import { ApolloClient } from '@apollo/client';
import { ApolloLink } from '@apollo/client/core';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import get from 'lodash/get';
import {
  BASE_URI,
  CLIENT_AUTH_TOKEN,
  CLIENT_GHOST_AUTH_TOKEN,
} from '@noloco/core/src/constants/auth';
import SafeStorage from '@noloco/core/src/utils/SafeStorage';
import {
  USE_CORE_LAMBDA_ENDPOINT,
  USE_PROJECT_LAMBDA_ENDPOINT,
  getOperationUri,
} from '@noloco/core/src/utils/graphQlEndpoints';
import { USE_BATCHING_CLIENT_KEY } from '../constants/configKeys';
import { SSELink } from './sseLink';

export const getAuthToken = (skipGhostToken: boolean) => {
  const ghostToken = new SafeStorage().get(CLIENT_GHOST_AUTH_TOKEN);
  const token =
    !skipGhostToken && ghostToken
      ? ghostToken
      : new SafeStorage().get(CLIENT_AUTH_TOKEN);
  return token ? token : '';
};

const USE_BATCHING_CLIENT = new SafeStorage().getBoolean(
  USE_BATCHING_CLIENT_KEY,
  false,
);

console.log(
  'USE_BATCHING_CLIENT: ',
  USE_BATCHING_CLIENT,
  ', USE_PROJECT_LAMBDA_ENDPOINT: ',
  USE_PROJECT_LAMBDA_ENDPOINT,
  ' USE_CORE_LAMBDA_ENDPOINT: ',
  USE_CORE_LAMBDA_ENDPOINT,
);

export const getAuthLink = (projectName: string) =>
  setContext((_, { headers, projectQuery, authQuery, withCoreAuth }) => {
    const token = getAuthToken(authQuery || !projectQuery || withCoreAuth);
    const ghostToken = new SafeStorage().get(CLIENT_GHOST_AUTH_TOKEN);
    return {
      headers: {
        ...headers,
        'x-noloco-project': projectName,
        'x-noloco-ghost': !!ghostToken,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

const getSSELink = (projectName: string) =>
  new SSELink({
    url: `${BASE_URI}/stream`,
    headers: () => ({
      authorization: getAuthToken(false),
      'x-noloco-project': projectName,
    }),
  });

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    if (
      graphQLErrors.some(
        (error) => error.extensions?.code === 'UNAUTHENTICATED',
      ) &&
      !window.location.pathname.startsWith('/login')
    ) {
      window.location.assign(
        `/login?redirectPath=${encodeURIComponent(
          `${window.location.pathname}${window.location.search}`,
        )}`,
      );
    }
});

const getBatchLink = (projectName: string) =>
  new BatchHttpLink({
    uri: getOperationUri(projectName),
    /*
     * We debounce the batch for 20ms for a max of 3 requests in a batch
     * This means a maximum of 60ms delay for a request to be sent
     * For single requests, the delay is 20ms
     */
    batchMax: 3,
    batchInterval: 20,
    batchDebounce: true,
  });

const OPERATION_PATH = ['definitions', 0, 'operation'];

const getApolloLink = (projectName: string) => {
  const uploadLink = createUploadLink({
    uri: getOperationUri(projectName),
  });

  if (!USE_BATCHING_CLIENT) {
    return uploadLink;
  }

  const coreBatchLink = getBatchLink(projectName);
  const projectBatchLink = getBatchLink(projectName);

  // Separate batching per endpoint (core or data)
  const baseLink = ApolloLink.split(
    ({ getContext }) => {
      const context = getContext();
      return context.projectQuery && context.projectName;
    },
    projectBatchLink,
    coreBatchLink,
  );

  // Use an uploadLink for mutations instead of the batchLink
  return ApolloLink.split(
    ({ query }) => {
      const operation = get(query, OPERATION_PATH);
      return operation === 'mutation';
    },
    uploadLink,
    baseLink,
  );
};

const getApolloClient = (
  projectName: string,
  cache: any,
  authLink: any,
  ssrMode: boolean,
) => {
  const apolloLink = getApolloLink(projectName);

  return new ApolloClient({
    ssrMode,
    link: ApolloLink.from([errorLink, authLink]).split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      !ssrMode ? getSSELink(projectName) : apolloLink,
      apolloLink,
    ),
    cache,
  });
};

export default getApolloClient;
