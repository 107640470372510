import React, { memo } from 'react';
import { Surface } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { DataFieldType } from '@noloco/core/src/constants/dataTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import {
  ElementPath,
  StringPropSegment,
} from '@noloco/core/src/models/Element';
import { DataItemValueOption } from '@noloco/core/src/utils/state';
import DynamicValueInput from './dynamicValueInput/DynamicValueInput';

type ConditionValueInputProps = {
  additionalScopeItems?: DataItemValueOption[];
  acceptableDataTypes?: DataFieldType[];
  className?: string;
  checkFieldPermissions?: boolean;
  contained: boolean;
  dataType: DataType;
  dataOptions?: DataItemValueOption[];
  disabled?: boolean;
  elementPath: ElementPath;
  field?: DataField;
  fieldFilter?: (field: DataField, dataType?: DataType) => boolean;
  includeUniqueColumnar?: boolean;
  includeRelationships?: boolean;
  includeSelf?: boolean;
  multiple: boolean;
  onChange: (value: StringPropSegment[] | null) => void;
  placeholder: any;
  surface?: Surface;
  value: StringPropSegment[] | undefined;
};

const ConditionValueInput = memo(
  ({
    additionalScopeItems = [],
    acceptableDataTypes,
    checkFieldPermissions = true,
    dataOptions,
    dataType,
    disabled,
    elementPath,
    field,
    fieldFilter,
    includeRelationships,
    includeUniqueColumnar,
    includeSelf,
    multiple,
    onChange,
    placeholder,
    surface = DARK,
    value,
  }: ConditionValueInputProps) => (
    <DynamicValueInput
      additionalScopeItems={additionalScopeItems}
      acceptableDataTypes={acceptableDataTypes}
      checkFieldPermissions={checkFieldPermissions}
      dataOptions={dataOptions}
      dataType={dataType}
      disabled={disabled}
      elementPath={elementPath}
      field={field}
      fieldFilter={fieldFilter}
      includeSelf={includeSelf}
      includeRelationships={includeRelationships}
      includeUniqueColumnar={includeUniqueColumnar}
      multiple={multiple}
      onChange={onChange}
      placeholder={placeholder}
      surface={surface}
      value={value}
    />
  ),
);

export default ConditionValueInput;
