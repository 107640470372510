import { IconInfoCircle } from '@tabler/icons-react';
import classNames from 'classnames';
import { Tooltip } from '@noloco/components/src';
import { DARK } from '@noloco/components/src/constants/surface';
import { darkModeColors } from '../../constants/darkModeColors';
import BuildModeLabel from './BuildModeLabel';

export type BuildModeBaseSwitchSectionProps = {
  button?: JSX.Element | boolean;
  children?: JSX.Element;
  className?: string;
  guide?: string | JSX.Element;
  label: string | JSX.Element;
  onClick?: () => void;
  showOnlyButton?: boolean;
  switchEl: JSX.Element;
  value: boolean;
};

const BuildModeBaseSwitchSection = ({
  button,
  children,
  className,
  guide,
  label,
  onClick,
  showOnlyButton = false,
  switchEl,
  value,
}: BuildModeBaseSwitchSectionProps) => (
  <div
    className={classNames(
      'flex flex-col rounded-lg',
      { 'bg-slate-900': children || button || showOnlyButton },
      className,
    )}
    onClick={onClick}
  >
    <div
      className={classNames(
        'flex items-center justify-between border-dashed border-slate-600',
        {
          'border-b': (children || button) && value && !showOnlyButton,
          'h-10 p-2': children || button || showOnlyButton,
        },
      )}
    >
      <BuildModeLabel>
        <div className="flex items-center space-x-2">
          {label}
          {guide && (
            <Tooltip
              content={
                <div
                  className={classNames('text-sm', darkModeColors.text.primary)}
                >
                  {guide}
                </div>
              }
              placement="left"
              offset={[0, 8]}
              surface={DARK}
            >
              <div className="flex cursor-pointer items-center justify-center rounded-md p-1 opacity-75 hover:bg-slate-700 hover:opacity-100">
                <IconInfoCircle size={16} />
              </div>
            </Tooltip>
          )}
        </div>
      </BuildModeLabel>
      <div className="flex items-center space-x-2">
        {button}
        {switchEl}
      </div>
    </div>
    {value && children}
  </div>
);

export default BuildModeBaseSwitchSection;
