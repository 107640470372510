import React, { forwardRef, useCallback, useState } from 'react';
import Loader from '../loading/Loader';
import Modal from '../modal/Modal';
import BaseButton from './Button';

const ConfirmationButton = forwardRef(
  (
    {
      // @ts-expect-error TS(2339): Property 'Button' does not exist on type '{}'.
      Button = BaseButton,
      children,
      // @ts-expect-error TS(2339): Property 'confirmText' does not exist on type '{}'... Remove this comment to see the full error message
      confirmText,
      // @ts-expect-error TS(2339): Property 'description' does not exist on type '{}'... Remove this comment to see the full error message
      description,
      // @ts-expect-error TS(2339): Property 'icon' does not exist on type '{}'.
      icon,
      // @ts-expect-error TS(2339): Property 'onConfirm' does not exist on type '{}'.
      onConfirm,
      // @ts-expect-error TS(2339): Property 'onOpenChange' does not exist on type '{}... Remove this comment to see the full error message
      onOpenChange,
      // @ts-expect-error TS(2339): Property 'title' does not exist on type '{}'.
      title,
      // @ts-expect-error TS(2339): Property 'variant' does not exist on type '{}'.
      variant,
      ...rest
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const onOpen = useCallback(() => {
      setIsOpen(true);
      if (onOpenChange) {
        onOpenChange(true);
      }
    }, [onOpenChange]);

    const onClose = useCallback(() => {
      setIsOpen(false);
      if (onOpenChange) {
        onOpenChange(false);
      }
    }, [onOpenChange]);

    const handleOnConfirm = useCallback(() => {
      setIsLoading(true);
      onConfirm().finally(() => {
        setIsLoading(false);
        onClose();
      });
    }, [onClose, onConfirm]);

    return (
      <>
        {isOpen && onConfirm && (
          <Modal
            confirmText={isLoading ? <Loader size="sm" /> : confirmText}
            icon={icon}
            onCancel={onClose}
            onClose={onClose}
            onConfirm={handleOnConfirm}
            onOpen
            title={title}
            variant={variant}
          >
            <p>{description}</p>
          </Modal>
        )}
        <Button {...rest} onClick={onOpen} variant={variant} ref={ref}>
          {children}
        </Button>
      </>
    );
  },
);

export default ConfirmationButton;
