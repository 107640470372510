import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import { IconAlertTriangle } from '@tabler/icons-react';
import classNames from 'classnames';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import {
  Button,
  Loader,
  Popover,
  Tooltip,
  getColorShade,
} from '@noloco/components';
import { ROADMAP } from '@noloco/components/src/constants/mapTypes';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import { darkModeColors } from '../../../constants/darkModeColors';
import MapPin from '../../../img/svg/MapPin';
import { DataField } from '../../../models/DataTypeFields';
import { DepValue } from '../../../models/Element';
import { BaseRecord } from '../../../models/Record';
import { GroupByWithField } from '../../../models/View';
import { getColorByIndex } from '../../../utils/colors';
import { formatDisplayField } from '../../../utils/dataTypes';
import { getPrimaryField } from '../../../utils/fields';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import usePrevious from '../../../utils/hooks/usePrevious';
import { getText } from '../../../utils/lang';
import SidebarCollapseButton from './SidebarCollapseButton';
import mapStyles from './map/mapStyles';

const containerStyle: CSSProperties = {
  position: 'absolute',
  width: '100%',
  height: '100%',
};

const getIconPixelOffset = () => ({ x: -20, y: -40 });

const getLocationGroupColor = (
  node: any,
  groupByDep: any,
  groupColorMap: any,
) => {
  const groupByPath = groupByDep.path.replace(/^edges\.node\./g, '');
  const groupValue = get(node, groupByPath);
  return groupColorMap[groupValue];
};

const getLocationValue = (
  node: BaseRecord,
  locationField: DataField | undefined,
  locationFieldDep: DepValue | undefined,
) => {
  if (!locationField || !locationFieldDep) {
    return null;
  }

  const locationFieldDepPath = locationFieldDep.path.replace(
    /^edges\.node\./g,
    '',
  );
  const rawValue = get(node, locationFieldDepPath);

  const number = Number(rawValue);
  return isNaN(number) ? null : number;
};

const INITIAL_CENTER = {
  lat: 0,
  lng: 0,
};

const CollectionMap = ({
  className,
  dataType,
  editorMode,
  edges,
  EmptyState,
  groupByFields,
  map,
  latField,
  lngField,
  pagination,
  recordTitleField,
  Row,
  project,
  theme,
}: any) => {
  const { sm: isSmScreen } = useBreakpoints();
  const [listIsOpen, setListIsOpen] = useState(!isSmScreen);
  const apiKey = useMemo(
    () => get(project, 'settings.apiKeys.googleMaps'),
    [project],
  );

  const previousSmScreen = usePrevious(isSmScreen);

  useEffect(() => {
    if (previousSmScreen !== undefined && previousSmScreen !== isSmScreen) {
      setListIsOpen(!isSmScreen);
    }
  }, [isSmScreen, previousSmScreen]);

  const primaryColor = theme.brandColors.primary;

  const [isDarkModeEnabled] = useDarkMode();

  const [hoveredLocation, setHoveredLocation] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const handleSetHoveredLocation = useCallback(
    (location: any) => {
      if (hoveredLocation !== location) {
        setHoveredLocation(location);
      }
    },
    [hoveredLocation],
  );

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: apiKey,
  });

  const titleField = useMemo(() => {
    if (!dataType) {
      return null;
    }

    if (recordTitleField) {
      const field = dataType.fields.getByName(recordTitleField);

      if (field && !field.relationship) {
        return field;
      }
    }

    return getPrimaryField(dataType);
  }, [dataType, recordTitleField]);

  const groupByField: GroupByWithField | undefined = useMemo(
    () => (groupByFields.length > 0 ? groupByFields[0] : undefined),
    [groupByFields],
  );

  const groupByFieldColorMap = useMemo(() => {
    if (
      !groupByField ||
      !groupByField.dataField ||
      !groupByField.dataField.field.options
    ) {
      return {};
    }

    return groupByField.dataField!.field.options.reduce(
      (acc, option, index) => ({
        ...acc,
        [option.name]: option.color || getColorByIndex(index),
      }),
      {},
    );
  }, [groupByField]);

  const locations = useMemo(
    () =>
      edges
        .map((edge: any) => ({
          latitude: getLocationValue(edge.node, latField, map?.latitude),
          longitude: getLocationValue(edge.node, lngField, map?.longitude),
          record: edge.node,

          title:
            (titleField &&
              formatDisplayField(
                titleField,
                get(edge, ['node', titleField.apiName]),
              )) ||
            get(edge, 'node.uuid'),

          color:
            groupByField &&
            groupByField.field &&
            getLocationGroupColor(
              edge.node,
              groupByField.field,
              groupByFieldColorMap,
            ),
        }))
        .filter(
          (location: any) =>
            location.latitude &&
            location.longitude &&
            Math.abs(location.latitude) <= 90 &&
            Math.abs(location.longitude) <= 180,
        ),
    [
      edges,
      groupByField,
      groupByFieldColorMap,
      latField,
      lngField,
      map,
      titleField,
    ],
  );

  const loadHandler = useCallback(
    (map: any) => {
      if (locations.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        locations.forEach((location: any) => {
          bounds.extend({ lat: location.latitude, lng: location.longitude });
        });
        map.fitBounds(bounds);
      }
    },
    [locations],
  );

  if (!isLoaded) {
    return (
      <div className="flex h-screen max-h-full flex-col items-center justify-center overflow-hidden">
        <Loader size="md" />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        className,
        `flex h-full max-h-full overflow-hidden ${
          isDarkModeEnabled ? darkModeColors.surfaces.elevation0 : 'bg-gray-50'
        }`,
      )}
    >
      <div
        className={classNames(
          'relative flex h-full max-w-xs flex-col space-y-4 sm:max-w-full',
          {
            'w-0': !listIsOpen,
            'w-screen overflow-y-auto p-2 shadow-md': listIsOpen,
          },
        )}
      >
        {listIsOpen && locations.length === 0 && (
          <div className="flex h-full flex-col items-center justify-center">
            <EmptyState />
          </div>
        )}
        {listIsOpen &&
          locations.map((location: any, index: any) => (
            <div
              className="flex w-full"
              key={location.record.id}
              onMouseOver={() => handleSetHoveredLocation(location)}
              onMouseOut={() => setHoveredLocation(null)}
            >
              <Row
                className="w-full max-w-sm flex-col rounded-lg font-sans font-normal shadow"
                edge={{ node: location.record }}
                isLast={index === locations.length - 1}
                index={index}
                record={location.record}
              />
            </div>
          ))}
        {listIsOpen && pagination}
        <SidebarCollapseButton isOpen={listIsOpen} onClick={setListIsOpen} />
      </div>
      <div
        className={classNames(
          'relative flex w-full max-w-full overflow-hidden',
          { 'sm:w-0': listIsOpen },
        )}
      >
        {/* @ts-expect-error TS2786: 'GoogleMap' cannot be used as a JSX component. */}
        <GoogleMap
          mapContainerStyle={containerStyle}
          onLoad={loadHandler}
          center={INITIAL_CENTER}
          onClick={undefined}
          options={{
            gestureHandling: undefined,
            zoomControl: true,
            mapTypeControl: false,
            maxZoom: 18,
            minZoom: 2,
            fullscreenControl: false,
            streetViewControl: false,
            styles: mapStyles,
            mapTypeId: map?.mapType ?? ROADMAP,
          }}
          zoom={3}
        >
          {locations.map((location: any) => (
            <>
              {/* @ts-expect-error TS2786: 'OverlayView' cannot be used as a JSX component. */}
              <OverlayView
                key={location.record.id}
                getPixelPositionOffset={getIconPixelOffset}
                position={{
                  lat: location.latitude,
                  lng: location.longitude,
                }}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <Popover
                  isOpen={selectedLocation === location}
                  trigger="none"
                  content={
                    <div
                      className="flex w-full flex-col"
                      data-testid="map-popover-view"
                    >
                      <Row
                        className="w-screen max-w-xs flex-col font-sans font-normal"
                        edge={{ node: location.record }}
                        isLast={false}
                        index={0}
                        record={location.record}
                      >
                        <div className="my-4 w-full">
                          <h2 className="text-base tracking-wider">
                            {location.title}
                          </h2>
                        </div>
                      </Row>
                    </div>
                  }
                  onOpenChange={(nextOpen: any) => {
                    if (!nextOpen) {
                      setSelectedLocation(null);
                    }
                  }}
                  closeOnOutsideClick={true}
                  p={0}
                  showArrow={false}
                >
                  <div className="flex">
                    <Tooltip
                      bg="white"
                      disabled={selectedLocation === location}
                      content={location.title}
                      showArrow={false}
                    >
                      <div
                        className="font-sans"
                        data-testid="collection-map-marker"
                        onClick={() => setSelectedLocation(location)}
                      >
                        <MapPin
                          className={classNames(
                            'flex h-10 w-10 items-center justify-center',
                            {
                              [`text-${getColorShade(
                                location.color || primaryColor,
                                600,
                              )} hover:text-${getColorShade(
                                location.color || primaryColor,
                                400,
                              )}`]:
                                hoveredLocation !== location &&
                                selectedLocation !== location,
                              [`z-10 text-${getColorShade(
                                location.color || primaryColor,
                                400,
                              )}`]:
                                hoveredLocation === location ||
                                selectedLocation === location,
                            },
                          )}
                        />
                      </div>
                    </Tooltip>
                  </div>
                </Popover>
              </OverlayView>
            </>
          ))}
        </GoogleMap>
        {!apiKey && editorMode && (
          <div className="absolute bottom-4 left-4 right-4 flex items-center rounded-lg border border-gray-300 bg-gray-200 p-4 text-gray-900">
            <IconAlertTriangle
              size={24}
              className="mr-4 flex-shrink-0 opacity-75"
            />
            <div className="mr-4 flex flex-grow flex-col">
              <h3 className="text-sm font-medium">
                {getText('elements.VIEW.display.map.apiKey.invalid.title')}
              </h3>
              <p className="text-xs">
                {getText(
                  'elements.VIEW.display.map.apiKey.invalid.description',
                )}
              </p>
            </div>
            <Link to="/_/settings/integrations/google-maps">
              <Button variant="secondary">
                {getText('elements.VIEW.display.map.apiKey.invalid.cta')}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default withTheme(CollectionMap);
