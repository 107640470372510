import React, { useMemo } from 'react';
import classNames from 'classnames';
import { Modal, Popover, getColorShade } from '@noloco/components';
import useBreakpoints from '@noloco/components/src/utils/hooks/useBreakpoints';
import useDarkModeSurface from '../../../utils/hooks/useDarkModeSurface';
import useRouter from '../../../utils/hooks/useRouter';
import { getText } from '../../../utils/lang';
import { Event } from './CollectionEvents';
import CollectionEventPopoverContent from './calendar/CollectionEventPopoverContent';

type ItemContext = {
  canMove: boolean;
  canResizeLeft: boolean;
  canResizeRight: boolean;
  dimensions: any;
  dragging: null;
  dragGroupDelta: null;
  dragStart: null;
  dragTime: null;
  resizeEdge: null;
  resizeStart: null;
  resizeTime: null;
  resizing: null;
  selected: boolean;
  title: string;
  useResizeHandle: boolean;
  width: number;
};

type CollectionTimelineItemProps = {
  deselectItem: () => void;
  getItemProps: any;
  getResizeProps: any;
  isDarkModeEnabled: boolean;
  isGanttViewEnabled: boolean;
  item: Event;
  itemContext: ItemContext;
  primaryColor: string;
  recordRowLink: string;
  Row: React.ReactNode;
};

const CollectionTimelineItem = ({
  deselectItem,
  getItemProps,
  getResizeProps,
  isDarkModeEnabled,
  isGanttViewEnabled,
  item,
  itemContext,
  primaryColor,
  recordRowLink,
  Row,
}: CollectionTimelineItemProps) => {
  const { push } = useRouter();
  const surface = useDarkModeSurface();
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
  const { sm: isSmScreen } = useBreakpoints();

  const popoverDisabled = useMemo(
    () => isSmScreen || itemContext.dragging || itemContext.resizing,
    [isSmScreen, itemContext],
  );

  return (
    <div
      {...getItemProps(item.itemProps)}
      style={{
        ...getItemProps(item.itemProps).style,
        background: '',
        border: '',
        borderRightWidth: '',
        borderLeftWidth: '',
        cursor: 'pointer',
      }}
      className={classNames(`flex items-center truncate rounded-lg border-2`, {
        [`bg-${getColorShade(item.color, 600)} border-${getColorShade(
          item.color,
          800,
        )}`]: item.color,
        [`bg-${getColorShade(item.color, 700)}`]:
          item.color && itemContext.selected,
        [`bg-${getColorShade(primaryColor, 600)} border-${getColorShade(
          primaryColor,
          700,
        )}`]: !item.color && !itemContext.selected,
        [`bg-${getColorShade(primaryColor, 700)} border-${getColorShade(
          primaryColor,
          600,
        )}`]: !item.color && itemContext.selected,
      })}
      {...(isGanttViewEnabled ? { id: `gantt-event-${item.id}-end` } : {})}
    >
      <div
        className={classNames('w-full overflow-hidden', {
          'cursor-move': itemContext.selected,
        })}
      >
        {itemContext.selected && itemContext.useResizeHandle && (
          <span
            {...leftResizeProps}
            className="flex items-center justify-center"
            style={{ ...leftResizeProps.style, cursor: 'col-resize' }}
          />
        )}
        <Popover
          className="overflow-hidden"
          closeOnOutsideClick={true}
          content={
            <CollectionEventPopoverContent
              event={item}
              isDarkModeEnabled={isDarkModeEnabled}
              recordRowLink={recordRowLink}
              Row={Row}
            />
          }
          disabled={popoverDisabled}
          isOpen={itemContext.selected}
          p={{ x: 0, y: 0 }}
          placement="right"
          surface={surface}
          trigger="none"
        >
          <span
            className="flex w-full items-center px-2"
            style={{ maxHeight: `${itemContext.dimensions.height}` }}
          >
            {itemContext.title}
          </span>
        </Popover>
        {itemContext.selected && itemContext.useResizeHandle && (
          <span
            {...rightResizeProps}
            className="flex items-center justify-center"
            style={{ ...rightResizeProps.style, cursor: 'col-resize' }}
          />
        )}
      </div>
      {isGanttViewEnabled && (
        <div
          id={`gantt-event-${item.id}-start`}
          className="absolute right-0 top-0 h-full w-6"
        />
      )}
      {isSmScreen && itemContext.selected && (
        <Modal
          confirmText={getText('elements.COLLECTION.calendar.open')}
          onCancel={deselectItem}
          onClose={deselectItem}
          onConfirm={() => {
            if (recordRowLink && item.rootPathname) {
              push(recordRowLink);
            }
          }}
          title={itemContext.title}
        >
          <CollectionEventPopoverContent
            event={item}
            hideOpenButton={true}
            isDarkModeEnabled={isDarkModeEnabled}
            recordRowLink={recordRowLink}
            Row={Row}
          />
        </Modal>
      )}
    </div>
  );
};

export default CollectionTimelineItem;
