import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import { CONDITIONAL_FIELD_VISIBILITY } from '@noloco/core/src/constants/features';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { getText } from '@noloco/core/src/utils/lang';
import useIsFeatureEnabled from '../../../utils/hooks/useIsFeatureEnabled';
import FeatureLockedSwitch from '../../FeatureLockedSwitch';
import Guide from '../../Guide';
import FieldColorsConditionsEditor, {
  getNewColorCondition,
} from './FieldColorsConditionsEditor';

const LANG_KEY = 'elements.VIEW';

type FieldColordEditorProps = {
  conditionFieldOptions: any;
  dataType: DataType;
  elementPath: ElementPath;
  onChange: (path: any, value: any) => void;
  project: Project;
  value: any;
};

const FieldColorEditor = ({
  conditionFieldOptions,
  dataType,
  elementPath,
  onChange,
  project,
  value,
}: FieldColordEditorProps) => {
  const isEnabled = useIsFeatureEnabled(CONDITIONAL_FIELD_VISIBILITY);

  if (!isEnabled) {
    return null;
  }

  return (
    <BuildModeSwitchSection
      guide={
        <Guide href="https://guides.noloco.io/field-formatting/conditionally-highligh-fields">
          {getText(LANG_KEY, 'fields.colorConditions.guide')}
        </Guide>
      }
      label={getText(LANG_KEY, 'fields.colorConditions.label')}
      value={!!value && isEnabled}
      onChange={(nextValue: any) => {
        onChange([], nextValue ? [getNewColorCondition()] : null);
      }}
      switchEl={
        <FeatureLockedSwitch
          feature={CONDITIONAL_FIELD_VISIBILITY}
          value={!!value && isEnabled}
          onChange={(nextValue: any) => {
            onChange([], nextValue ? [getNewColorCondition()] : null);
          }}
          size="sm"
        />
      }
    >
      <div className="flex max-w-full flex-col items-center justify-between px-2 py-4">
        <FieldColorsConditionsEditor
          values={value}
          onChange={onChange}
          dataType={dataType}
          fieldOptions={conditionFieldOptions}
          project={project}
          elementPath={elementPath}
        />
      </div>
    </BuildModeSwitchSection>
  );
};

export default FieldColorEditor;
