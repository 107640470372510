import { createSlice } from '@reduxjs/toolkit';
import { setAutoFreeze } from 'immer';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import initial from 'lodash/initial';
import last from 'lodash/last';
import mutableSet from 'lodash/set';
import { FILE } from '../constants/builtInDataTypes';
import { VIEW } from '../constants/elements';
import DataTypeFields from '../models/DataTypeFields';
import DataTypePermissions from '../models/DataTypePermissions';
import DataTypes, {
  DataSource,
  DataType,
  DataTypeArray,
} from '../models/DataTypes';
import { Element, ElementPath } from '../models/Element';
import { Permission } from '../models/Permission';
import { Project } from '../models/Project';
import { Workflow } from '../models/Workflow';
import {
  buildFormattedReverseRelateField,
  getDataTypesWithRelations,
} from '../utils/data';

// This is necessary because auto-freezing causes BaseArrayTypeMap can't be built
// if the object is frozen
setAutoFreeze(false);

export type ProjectState = Project;

export type ProjectUpdate = {
  path: ElementPath;
  value: any;
};

const projectSlice = createSlice({
  name: 'project',
  initialState: {
    data: null as null | ProjectState,
    undoStack: [] as ProjectUpdate[],
    redoStack: [] as ProjectUpdate[],
    draftDataTypes: undefined as undefined | DataTypes,
  },
  reducers: {
    addMedia: (state, { payload }) => {
      state.data = {
        ...state.data,
        media: [...(state.data?.media ?? []), payload],
      } as ProjectState;
    },
    redo: (state) => {
      const redoUpdate = last(state.redoStack);
      if (redoUpdate) {
        const existingValue = get(state.data, redoUpdate.path);
        state.undoStack.push({ ...redoUpdate, value: existingValue });
        state.redoStack = initial(state.redoStack);
      }
    },
    undo: (state) => {
      const undoUpdate = last(state.undoStack);
      if (undoUpdate) {
        const existingValue = get(state.data, undoUpdate.path);
        state.redoStack.push({ ...undoUpdate, value: existingValue });
        state.undoStack = initial(state.undoStack);
      }
    },
    updateProject: (state, { payload: { value, path, skipHistory } }) => {
      if (first(path) === 'elements' && !skipHistory) {
        const existingValue = get(state.data, path);
        state.undoStack.push({
          path,
          value: existingValue,
        });
        state.redoStack = [];
      }
      mutableSet(state.data as Record<any, any>, path, value);
    },
    updateProjectStatus: (state, { payload }) => {
      if (state.data) {
        state.data.live = payload;
      }
    },
    updateProjectIntegration: (state, { payload: { value, integration } }) => {
      mutableSet(
        state.data as Record<any, any>,
        ['integrations', integration],
        value,
      );
    },
    setProject: (state, { payload }) => {
      state.data = {
        ...payload,
        dataTypes: getDataTypesWithRelations(payload.dataTypes),
      };
    },
    updateSource: (state, { payload: dataSource }: { payload: DataSource }) => {
      const dataTypesToUpdate = state.data?.dataTypes?.filter(
        ({ source }) => source.id === dataSource.id,
      );

      dataTypesToUpdate?.forEach((dataType) => {
        const idx = state.data?.dataTypes.findIndex(
          ({ id }) => id === dataType.id,
        );
        if (idx !== undefined && idx >= 0) {
          const updateDataSource = set(
            'source.display',
            dataSource.display,
            dataType,
          );
          state.data = set(
            ['dataTypes', idx],
            updateDataSource,
            state.data as ProjectState,
          );
        }
      });
    },
    setHasUnpublishedChanges: (state, { payload }) => {
      if (state.data) {
        state.data.hasUnpublishedChanges = payload;
      }
    },
    incrementPublishedVersion: (state) => {
      if (state.data) {
        state.data.publishedVersion = (state.data.publishedVersion ?? 0) + 1;
      }
    },
    setPublishedVersion: (state, { payload }) => {
      if (state.data) {
        state.data.publishedVersion = payload;
      }
    },
    addDataField: (state, { payload: { dataTypeName, dataField } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ name }) => name === dataTypeName,
      );
      if (state.data && typeIndex !== undefined) {
        state.data.dataTypes[typeIndex].fields.push(dataField);
        if (dataField.relationship && dataField.type !== FILE) {
          const relatedType = state.data?.dataTypes.find(
            ({ name }) => name === dataField.type,
          );
          if (relatedType) {
            const reverseField = buildFormattedReverseRelateField(
              dataField,
              state.data.dataTypes[typeIndex] as DataType,
              relatedType,
            );
            const relatedTypeIndex = state.data?.dataTypes.findIndex(
              ({ name }) => name === relatedType.name,
            );
            state.data.dataTypes[relatedTypeIndex].fields.push(reverseField);
          }
        }
      }
    },
    removeDataField: (state, { payload: { dataTypeId, dataField } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );
      if (state.data && typeIndex !== undefined) {
        state.data.dataTypes[typeIndex].fields = new DataTypeFields(
          state.data.dataTypes[typeIndex].fields.filter(
            ({ id }) => id !== dataField.id,
          ),
        );
        if (!dataField.relationship || dataField.type === FILE) {
          return;
        }
        //delete reverse field
        const relatedTypeIndex = state.data.dataTypes.findIndex(
          (types) => types.apiName === dataField.type,
        );
        if (relatedTypeIndex) {
          state.data.dataTypes[relatedTypeIndex].fields = new DataTypeFields(
            state.data.dataTypes[relatedTypeIndex].fields.filter(
              ({ relatedField }) => relatedField?.id !== dataField.id,
            ),
          );
        }
      }
    },
    updateDataField: (state, { payload: { dataTypeId, dataField } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );
      const fieldIndex =
        typeIndex !== undefined
          ? state.data?.dataTypes[typeIndex].fields.findIndex(
              ({ id }) => id === dataField.id,
            )
          : undefined;
      if (state.data && typeIndex !== undefined && fieldIndex !== undefined) {
        state.data.dataTypes[typeIndex].fields[fieldIndex] = dataField;
      }
    },
    updateDataFieldOptions: (
      state,
      { payload: { dataTypeId, dataFieldId, options } },
    ) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );
      const fieldIndex =
        typeIndex !== undefined
          ? state.data?.dataTypes[typeIndex].fields.findIndex(
              ({ id }) => id === dataFieldId,
            )
          : undefined;
      if (state.data && typeIndex !== undefined && fieldIndex !== undefined) {
        state.data.dataTypes[typeIndex].fields[fieldIndex].options = options;
      }
    },
    setPermissionsEnabled: (
      state,
      { payload: { dataTypeId, permissionsEnabled } },
    ) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );

      if (state.data && typeIndex !== undefined) {
        state.data = set(
          ['dataTypes', typeIndex, 'permissionsEnabled'],
          permissionsEnabled,
          state.data,
        );
      }
    },
    setPermissionRules: (state, { payload: { dataTypeId, permissions } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );

      if (state.data && typeIndex !== undefined) {
        state.data.dataTypes[typeIndex].permissions = new DataTypePermissions(
          permissions,
        );
      }
    },
    setPermissionRule: (state, { payload: { dataTypeId, permission } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );

      const permissionIndex =
        typeIndex &&
        state.data?.dataTypes[typeIndex].permissions.findIndex(
          ({ id }) => id === permission.id,
        );

      if (
        state.data &&
        typeIndex !== undefined &&
        permissionIndex !== undefined
      ) {
        state.data.dataTypes[typeIndex].permissions = new DataTypePermissions(
          set(permissionIndex, permission, [
            ...state.data.dataTypes[typeIndex].permissions,
          ]) as Permission[],
        );
      }
    },
    removeDataSource: (state, { payload: { dataSource } }) => {
      const dataTypesToRemove = state.data?.dataTypes.filter(
        ({ source }) =>
          source.id === dataSource.id && source.type === dataSource.type,
      );

      dataTypesToRemove?.forEach((dataType) =>
        projectSlice.caseReducers.removeDataType(state, {
          payload: { dataType },
        } as { payload: { dataType: DataType } }),
      );
    },
    removeDataType: (
      state,
      { payload: { dataType } }: { payload: { dataType: DataType } },
    ) => {
      if (!state.data) {
        return state;
      }

      state.data.dataTypes = new DataTypes(
        state.data?.dataTypes
          .filter(({ id }) => id !== dataType.id)
          .map((dt) =>
            set(
              'fields',
              new DataTypeFields(
                dt.fields.filter((field) => field.type !== dataType.name),
              ),
              dt,
            ),
          ),
      );

      state.data.elements = state.data.elements.filter(
        (element: Element) =>
          !(
            element.type === VIEW &&
            get(element, 'props.dataList.dataType') === dataType.name
          ),
      );
    },
    removePermissionRule: (
      state,
      { payload: { dataTypeId, permissionId } },
    ) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataTypeId,
      );

      if (state.data && typeIndex !== undefined) {
        const withoutRemovedPermission = new DataTypePermissions(
          [...state.data.dataTypes[typeIndex].permissions].filter(
            (it) => it.id !== permissionId,
          ) as Permission[],
        );
        state.data.dataTypes[typeIndex].permissions = withoutRemovedPermission;
      }
    },
    addDataType: (state, { payload }) => {
      if (
        state.data &&
        state.data.dataTypes.every(({ id }) => payload.id !== id)
      ) {
        state.data.dataTypes.push(DataTypeArray.formatDataType(payload));
      }
    },
    addDataTypes: (state, { payload }) => {
      if (state.data) {
        const existingDataTypeIds = state.data.dataTypes.map(({ id }) => id);

        state.data.dataTypes.push(
          ...payload
            .filter(({ id }: DataType) => !existingDataTypeIds.includes(id))
            .map(DataTypes.formatDataType),
        );
      }
    },
    updateDataType: (state, { payload: dataType }) => {
      const idx = state.data?.dataTypes.findIndex(
        ({ id }) => id === dataType.id,
      );
      if (idx !== undefined && idx >= 0 && state.data) {
        state.data.dataTypes[idx] = DataTypes.formatDataType(dataType);
      }
    },
    addWorkflow: (state, { payload: { dataTypeName, workflow } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ name }) => name === dataTypeName,
      );

      if (state.data && typeIndex !== undefined) {
        const workflowPath = ['dataTypes', typeIndex, 'workflows'];
        const existingWorkflows = get(state.data, workflowPath, []);
        state.data = set(
          workflowPath,
          [...existingWorkflows, workflow],
          state.data,
        );

        state.data = set(
          ['workflows', workflow.workflow.id, 'actions'],
          [],
          state.data,
        );
      }
    },
    changeWorkflow: (state, { payload: { dataTypeName, workflow } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ name }) => name === dataTypeName,
      );
      if (state.data && typeIndex !== undefined) {
        const workflowPath = ['dataTypes', typeIndex, 'workflows'];
        const existingWorkflows = get(state.data, workflowPath, []);
        const workflowIndex = existingWorkflows.findIndex(
          ({ id }: Workflow) => id === workflow.id,
        );

        if (workflowIndex !== undefined) {
          state.data = set(
            [...workflowPath, String(workflowIndex)],
            workflow,
            state.data,
          ) as ProjectState;
        }
      }
    },
    cloneWorkflow: (
      state,
      { payload: { dataTypeName, workflow, actions } },
    ) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ name }) => name === dataTypeName,
      );

      if (state.data && typeIndex !== undefined) {
        const workflowPath = ['dataTypes', typeIndex, 'workflows'];
        const existingWorkflows = get(state.data, workflowPath, []);
        state.data = set(
          workflowPath,
          [...existingWorkflows, workflow],
          state.data,
        );
        state.data = set(
          ['workflows', String(workflow.workflow.id), 'actions'],
          actions,
          state.data,
        ) as ProjectState;
      }
    },
    removeWorkflow: (state, { payload: { dataTypeName, id } }) => {
      const typeIndex = state.data?.dataTypes.findIndex(
        ({ name }) => name === dataTypeName,
      );
      if (state.data && typeIndex !== undefined) {
        const workflowPath = ['dataTypes', typeIndex, 'workflows'];
        const existingWorkflows = get(state.data, workflowPath, []);
        const workflowsWithout = existingWorkflows.filter(
          (workflow: Workflow) => workflow.id !== id,
        );
        state.data = set(workflowPath, workflowsWithout, state.data);
      }
    },

    addGoogleSignInClient: (state, { payload }) => {
      if (state.data && state.data.integrations) {
        state.data.integrations.google = payload;
      }
    },
    addDomain: (state, { payload }) => {
      if (state.data) {
        state.data.domains = [...(state.data.domains ?? []), payload];
      }
    },
    removeDomain: (state, { payload }) => {
      if (state.data && state.data.domains) {
        state.data.domains = state.data.domains.filter(
          (domain: { id: number }) => domain.id !== payload,
        );
      }
    },
    updateDomain: (state, { payload }) => {
      const domainIndex = (state.data?.domains ?? []).findIndex(
        ({ id }: { id: number }) => id === payload.id,
      );

      if (state.data && domainIndex !== undefined && state.data.domains) {
        state.data.domains = set([domainIndex], payload, state.data.domains);
      }
    },
    updateSmtp: (state, { payload }) => {
      if (state.data && state.data.integrations) {
        state.data.integrations.smtp = payload;
      }
    },
    setDataTypes: (state, { payload }) => {
      if (state.data) {
        state.data.dataTypes = getDataTypesWithRelations(payload);
      }
    },
    setDraftDataTypes: (state, { payload }) => {
      state.draftDataTypes = getDataTypesWithRelations(payload);
    },
    swapDraftDataTypes: (state) => {
      if (state.data && state.draftDataTypes) {
        state.data.dataTypes = state.draftDataTypes;
        state.draftDataTypes = undefined;
      }
    },
    setIntegrations: (state, { payload }) => {
      if (state.data) {
        state.data.integrations = payload;
      }
    },
  },
});

export const {
  addDataField,
  addDomain,
  addGoogleSignInClient,
  removeDataField,
  removeDataSource,
  removeDataType,
  removePermissionRule,
  addDataType,
  addDataTypes,
  addMedia,
  addWorkflow,
  setPermissionRules,
  setPermissionRule,
  setPermissionsEnabled,
  updateDataField,
  updateDataFieldOptions,
  removeDomain,
  removeWorkflow,
  changeWorkflow,
  cloneWorkflow,
  incrementPublishedVersion,
  setDataTypes,
  setDraftDataTypes,
  setIntegrations,
  setPublishedVersion,
  setHasUnpublishedChanges,
  setProject,
  undo,
  redo,
  updateSource,
  updateDataType,
  updateDomain,
  updateProject,
  updateProjectIntegration,
  updateProjectStatus,
  updateSmtp,
  swapDraftDataTypes,
} = projectSlice.actions;

export default projectSlice.reducer;
