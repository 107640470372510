import React, { useCallback, useMemo } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { Label, Loader, TextInput } from '@noloco/components';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { getText } from '@noloco/core/src/utils/lang';
import { UPDATE_DATA_FIELD } from '../../../queries/project';
import { useUpdateDataField } from '../../../utils/hooks/useUpdateDataTypes';

const LANG_KEY = 'data.changeDisplayInput';

const ChangeFieldDisplayInput = ({
  className,
  field,
  dataType,
  projectName,
  surface,
}: any) => {
  const updateDataField = useUpdateDataField();
  const errorAlert = useGraphQlErrorAlert();
  const [updateField, { loading }] = useMutation(UPDATE_DATA_FIELD);

  const existingFieldDisplays = useMemo(
    () => dataType.fields.map(({ display }: DataField) => display),
    [dataType.fields],
  );

  const onChange = useCallback(
    ({ target: { value: display } }) => {
      if (display && !existingFieldDisplays.includes(display)) {
        updateField({
          variables: {
            projectName,
            id: field.id,
            display,
            typeOptions: field.typeOptions,
          },
        })
          .then(({ data }) =>
            updateDataField({
              dataTypeId: dataType.id,
              dataField: data.updateDataField,
            }),
          )
          .catch((e) => {
            errorAlert(getText(LANG_KEY, 'error'), e);
          });
      }
    },
    [
      existingFieldDisplays,
      updateField,
      projectName,
      field.id,
      field.typeOptions,
      updateDataField,
      dataType.id,
      errorAlert,
    ],
  );

  const debouncedOnChange = debounce(onChange, 800);

  return (
    <div className={classNames(className, 'flex w-full flex-col')}>
      <Label surface={surface}>{getText(LANG_KEY, 'label')}</Label>
      <div className="mt-1 flex w-full items-center">
        <TextInput
          className="w-full"
          disabled={loading}
          onChange={debouncedOnChange}
          surface={surface}
          value={field.display}
        />
        {loading && <Loader size="sm" className="ml-4" />}
      </div>
    </div>
  );
};

export default ChangeFieldDisplayInput;
