import { Element, ViewTab } from '../models/Element';

export const filterSections = (
  tabs: ViewTab[],
  visibleTabs: ViewTab[],
  sections: Element[],
  selectedTab: ViewTab | null | undefined,
) => {
  const useTabs = tabs && tabs.length > 1;
  const tabIds = tabs.map((t: any) => t.id);
  const visibleTabIds = visibleTabs.map((t: any) => t.id);

  if (visibleTabIds.length === 0 && useTabs) {
    return [];
  }

  return sections
    .map((section, index) => ({
      section,
      index,
    }))
    .filter(({ section }: any) => {
      if (!useTabs) {
        // If not using tabs then render anything that isn't hidden.
        return (
          !section ||
          !(
            tabIds.includes(section.tab) && !visibleTabIds.includes(section.tab)
          )
        );
      }

      return (
        section &&
        ((!selectedTab && (!section.tab || !tabIds.includes(section.tab))) ||
          (selectedTab && section.tab === selectedTab.id))
      );
    });
};
