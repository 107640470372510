import React, { forwardRef, useMemo, useState } from 'react';
import { getColorShade, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import get from 'lodash/get';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormField, Theme } from '@noloco/components';
import SimpleLayout from '@noloco/components/src/components/auth/SimpleLayout';
import SubmitButton from '@noloco/components/src/components/auth/SubmitButton';
import { LIGHT } from '@noloco/components/src/constants/surface';
import MarkdownText from '../components/MarkdownText';
import PoweredByNoloco from '../components/PoweredByNoloco';
import SamlLogin from '../components/SamlLogin';
import SocialLogin from '../components/SocialLogin';
import MailSent from '../img/undraw/MailSent';
import { ProjectSettings } from '../models/Project';
import { User } from '../models/User';
import {
  projectIntegrationsSelector,
  projectMediaSelector,
  projectNameSelector,
} from '../selectors/projectSelectors';
import {
  isMagicLinkSignUpEnabled,
  isPasswordSignUpEnabled,
  isSsoEnabled,
} from '../utils/auth';
import { extractErrorMessages } from '../utils/errors';
import { useAuth } from '../utils/hooks/useAuth';
import { useUpdateUserCache } from '../utils/hooks/useAuthWrapper';
import useRouter from '../utils/hooks/useRouter';
import { getProjectAuthLogo } from '../utils/image';
import { getText } from '../utils/lang';

type Props = {
  logo: any;
  preview?: boolean;
  settings: ProjectSettings;
  theme: Theme;
};

const Login = forwardRef<any, Props>(
  ({ logo, preview = false, settings, theme }, ref) => {
    const {
      query: { redirectPath },
      push,
    } = useRouter();

    const projectName = useSelector(projectNameSelector);
    const integrations = useSelector(projectIntegrationsSelector);
    const media = useSelector(projectMediaSelector);

    const primaryColor = theme.brandColors.primary;
    const [errors, setErrors] = useState([]);
    const [hasSubmittedEmail, setHasSubmittedEmail] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login, magicLinkLogin } = useAuth();
    const updateUserCache = useUpdateUserCache();
    const googleClientId = get(integrations, 'google.signIn.clientId', null);
    const loginSettings = get(settings, 'login', null);

    const ssoEnabled = useMemo(() => isSsoEnabled(settings), [settings]);

    const openSignUpEnabled = useMemo(
      () => get(settings, 'openSignUp.enabled', false),
      [settings],
    );

    const passwordSignUpEnabled = useMemo(
      () => isPasswordSignUpEnabled(settings),
      [settings],
    );

    const magicLinkSignUpEnabled = useMemo(
      () => isMagicLinkSignUpEnabled(settings),
      [settings],
    );

    const showEmailInput = useMemo(
      () => passwordSignUpEnabled || magicLinkSignUpEnabled || !googleClientId,
      [googleClientId, magicLinkSignUpEnabled, passwordSignUpEnabled],
    );

    const title = useMemo(
      () =>
        loginSettings && loginSettings.title
          ? loginSettings.title
          : getText('auth.login.title'),
      [loginSettings],
    );

    const subTitle = useMemo(() => {
      if (openSignUpEnabled) {
        return (
          <span>
            <span>{getText('auth.login.or')}</span>
            <Link
              to="/register"
              className={classNames(
                'ml-1 font-medium transition duration-150 ease-in-out focus:underline focus:outline-none',
                `text-${getColorShade(
                  primaryColor,
                  600,
                )} hover:text-${getColorShade(primaryColor, 600)}`,
              )}
            >
              {getText('auth.login.appRegisterLink')}
            </Link>
          </span>
        );
      }

      if (loginSettings && loginSettings.subTitle) {
        return (
          <MarkdownText disabledHeadings={true}>
            {loginSettings.subTitle}
          </MarkdownText>
        );
      }

      return null;
    }, [openSignUpEnabled, primaryColor, loginSettings]);

    const handleOnSubmit = (event: any) => {
      event.preventDefault();
      setErrors([]);
      if (!hasSubmittedEmail) {
        magicLinkLogin(email).catch((error: any) => {
          setErrors(error.graphQLErrors.map((er: any) => er.message));
          console.warn('ERROR', error);
        });
        setHasSubmittedEmail(true);
      } else {
        login(email, password)
          .then((user: any) => {
            updateUserCache(user);
            if (redirectPath) {
              push(decodeURIComponent(redirectPath));
            } else {
              push('/');
            }
          })
          .catch((error: any) => {
            const errors = extractErrorMessages(error);
            if (errors.length > 0) {
              // @ts-expect-error TS(2345): Argument of type 'String[]' is not assignable to p... Remove this comment to see the full error message
              setErrors(errors);
            }

            console.warn('ERROR', JSON.stringify(error, undefined, 2));
          });
      }
    };

    const onSocialLogin = (user: User) => {
      updateUserCache(user);
    };

    const { src: logoUrl = getProjectAuthLogo(settings, media) } = logo || {};

    if (ssoEnabled) {
      return (
        <SamlLogin
          logo={logo}
          media={media}
          projectName={projectName}
          settings={settings}
          redirectPath={redirectPath}
          ref={ref}
        />
      );
    }

    return (
      <div
        className={classNames(
          'flex w-full flex-col items-center justify-center overflow-hidden bg-gray-100',
          { 'min-h-screen': !preview },
        )}
        ref={ref}
      >
        <SimpleLayout
          errors={errors}
          logoUrl={logoUrl}
          onSubmit={handleOnSubmit}
          titleText={title}
          subTitleText={subTitle}
        >
          <div className="mx-2 mt-6 overflow-hidden rounded-lg bg-white p-7 shadow sm:px-4">
            {!hasSubmittedEmail && (
              <>
                {showEmailInput && (
                  <>
                    <FormField
                      aria-label="email"
                      autoComplete="email"
                      name="email"
                      disabled={preview}
                      type="text"
                      onChange={({ target: { value } }: any) => setEmail(value)}
                      required
                      errorType="below-solid"
                      label={getText('auth.fields.email')}
                      placeholder=""
                      value={email}
                      surface={LIGHT}
                    />
                    <SubmitButton disabled={!email || preview}>
                      {getText('auth.login.next')}
                    </SubmitButton>
                  </>
                )}
                {googleClientId && (
                  <SocialLogin
                    clientId={googleClientId}
                    loginPath={redirectPath || '/'}
                    setErrors={setErrors}
                    onLogin={onSocialLogin}
                    hideOr={!showEmailInput}
                  />
                )}
              </>
            )}
            {hasSubmittedEmail && (
              <>
                {magicLinkSignUpEnabled && (
                  <>
                    <div
                      className={`mx-auto mb-4 w-24 overflow-hidden text-${getColorShade(
                        primaryColor,
                        500,
                      )}`}
                    >
                      <MailSent />
                    </div>
                    <h3 className="my-3 text-center text-base font-medium tracking-wider">
                      {getText('auth.login.magicLink.title')}
                    </h3>
                    <p className="mb-3 text-center text-sm">
                      {getText({ email }, 'auth.login.magicLink.subtitle')}
                    </p>
                  </>
                )}
                {magicLinkSignUpEnabled && passwordSignUpEnabled && (
                  <div className="my-6 flex items-center">
                    <span className="h-px w-full bg-gray-300" />
                    <span className="mx-3 text-center text-sm uppercase text-gray-500">
                      {getText('auth.login.or')}
                    </span>
                    <span className="h-px w-full bg-gray-300" />
                  </div>
                )}
                {passwordSignUpEnabled && (
                  <>
                    <h3 className="my-3 text-center font-medium tracking-wider">
                      {getText('auth.login.magicLink.password')}
                    </h3>
                    <FormField
                      aria-label="email"
                      autoComplete="email"
                      name="email"
                      className="hidden"
                      type="text"
                      readOnly={true}
                      onChange={({ target: { value } }: any) => setEmail(value)}
                      required
                      errorType="below-solid"
                      label={getText('auth.fields.email')}
                      placeholder=""
                      value={email}
                      surface={LIGHT}
                    />
                    <FormField
                      aria-label="password"
                      autoComplete="password"
                      className="mt-3"
                      name="password"
                      type="password"
                      onChange={({ target: { value } }: any) =>
                        setPassword(value)
                      }
                      required
                      errorType="below-solid"
                      label=""
                      placeholder={getText('auth.fields.password')}
                      value={password}
                      surface={LIGHT}
                    />
                    <SubmitButton disabled={!email || !password}>
                      {getText('auth.login.button')}
                    </SubmitButton>
                    <div className="mt-3 w-full text-center text-xs leading-5">
                      <Link
                        to="/forgot"
                        className={classNames(
                          'font-medium transition duration-150 ease-in-out focus:underline focus:outline-none',
                          `text-${getColorShade(primaryColor, 500)}`,
                          `hover:text-${getColorShade(primaryColor, 600)}`,
                        )}
                      >
                        {getText('auth.login.forgot')}
                      </Link>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </SimpleLayout>
        <PoweredByNoloco
          className="mx-auto flex-wrap justify-center text-gray-800"
          projectName={projectName}
          utmSource="noloco_login"
        />
      </div>
    );
  },
);

Login.defaultProps = {
  // @ts-expect-error TS(2322): Type '{ children: undefined; className: string; }'... Remove this comment to see the full error message
  children: undefined,
  className: '',
};

export default withTheme(Login);
