import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { getDestinationPage, getTabsForPage } from '@noloco/ui/src/utils/pages';
import { DIVIDER, LINK } from '../constants/elements';
import { EMAIL, PAGE, PHONE, URL as UrlType } from '../constants/linkTypes';
import { Element } from '../models/Element';
import { Project } from '../models/Project';
import { User } from '../models/User';
import { getHref } from '../models/elementPropTypes/comboProps/LinkPropType';
import { resolveDataValue } from './data';
import { isElementVisible } from './elementVisibility';
import { getPagePath, getPages } from './pages';
import { getPageTo } from './urls';

export const resolveNavigationObject = (
  navigate: any,
  actionsScope: any,
  project: any,
) => {
  const {
    email,
    page,
    pageData = {},
    phone,
    subject,
    url,
    tab,
    type: navigationType,
  } = navigate;

  if (navigationType === UrlType && url) {
    const href = resolveDataValue(url, actionsScope, project, true);

    if (href && String(href).startsWith('/')) {
      return {
        to: href,
      };
    }

    return {
      href,
    };
  } else if (navigationType === PAGE && page) {
    const pages = getPages(project.elements);
    let to = getPageTo(page, pageData, pages, actionsScope);
    if (tab) {
      const lastPageInPath = getDestinationPage(page, pages);

      if (lastPageInPath) {
        const tabsOnPage = getTabsForPage(lastPageInPath, page);
        const targetTab = tabsOnPage.find((t: any) => t.id === tab);

        if (targetTab) {
          const tabPath = kebabCase(targetTab.title);
          to = `${to}/${tabPath}`;
        }
      }
    }
    return { to };
  } else if (navigationType === EMAIL && email) {
    return {
      // @ts-expect-error TS(2554): Expected 6 arguments, but got 5.
      href: getHref(
        undefined,
        EMAIL,
        undefined,
        resolveDataValue(email, actionsScope, project, true),
        subject
          ? resolveDataValue(subject, actionsScope, project, true)
          : undefined,
      ),
    };
  } else if (navigationType === PHONE && phone) {
    return {
      href: getHref(
        undefined,
        PHONE,
        undefined,
        undefined,
        undefined,
        resolveDataValue(phone, actionsScope, project, true),
      ),
    };
  }

  return {};
};

type ElementReactNode = { props: { element: Element } };

export type PageRoute = {
  path: string;
  routePath: string;
  child: ElementReactNode;
  id: string;
  element: Element;
  parentPageId: string | null;
};

export const getVisibleRoutes = (
  pages: ElementReactNode[],
  elementMap: Record<string, Element>,
  fetchedUser: boolean,
  project: Project,
  user: User | undefined,
  scope: Record<string, any>,
  editorMode: boolean,
  customRulesEnabled: boolean,
): PageRoute[] =>
  pages
    .map((child: ElementReactNode) => {
      const childElement = get(child, 'props.element');

      if (childElement.type === DIVIDER) {
        return {
          child,
          id: childElement.id,
          element: childElement,
        };
      }

      const pageElementProps = get(childElement, 'props');
      const parentPageId = get(childElement, 'props.parentPage');
      const parentPage = parentPageId && elementMap && elementMap[parentPageId];

      const { dataType, dataProperty, routePath } = pageElementProps;
      const pageRoutePath = parentPage
        ? `${parentPage.props.routePath}/${routePath}`
        : routePath;
      const { path } = getPagePath('/', pageRoutePath, dataType, dataProperty);

      return {
        path,
        routePath,
        child,
        id: childElement.id,
        element: childElement,
        parentPageId: parentPage ? parentPage.id : null,
      };
    })
    .filter(
      ({ element }) =>
        !!element.type &&
        !!element.id &&
        element.type !== LINK &&
        element.type !== DIVIDER &&
        (!fetchedUser ||
          isElementVisible(
            element,
            project,
            user,
            scope,
            editorMode,
            customRulesEnabled,
          )),
    ) as PageRoute[];
