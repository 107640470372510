import { DECIMAL, INTEGER, TEXT } from '../../../constants/dataTypes';
import { EMAIL, PAGE, URL } from '../../../constants/linkTypes';
import { getPages } from '../../../utils/pages';
import { formatUrl, getPageTo } from '../../../utils/urls';
import BoolType from '../BoolPropType';
import ComboType from '../ComboPropType';
import RawDataPropType from '../RawDataPropPropType';
import StringType from '../StringPropType';

export const getHref = (
  __: any,
  type: any,
  url: any,
  email: any,
  subject: any,
  phone: any,
) => {
  if (type === URL) {
    return formatUrl(url);
  }

  if (type === EMAIL) {
    return `mailto:${email}${
      subject ? `?subject=${encodeURIComponent(subject)}` : ''
    }`;
  }

  if (phone) {
    return `tel:${phone}`;
  }

  return '#';
};

export const linkResolver = (
  comboProps: any,
  project: any,
  scope: any,
  editorMode: any,
) => {
  const { newTab, type, url, email, subject, page, pageData, phone } =
    comboProps;

  const href = getHref(editorMode, type, url, email, subject, phone);

  if (
    (type === PAGE || (type === URL && url && url.startsWith('/'))) &&
    !editorMode
  ) {
    let to = {};
    let active = false;

    if (type === URL) {
      return { to: href, active };
    }

    const pages = getPages(project.elements);

    if (!editorMode && page) {
      to = getPageTo(page, pageData, pages, scope);
      active =
        typeof document !== 'undefined' && document.location.pathname === to;
    }

    return { to, active };
  }

  return {
    href,
    target:
      (newTab && href && !href.startsWith('#')) ||
      (href && href.startsWith('mailto:'))
        ? '_blank'
        : undefined,
  };
};

export const extractPageDataDependencies = (
  prop: any,
  dependencies: any,
  elementProps: any,
) => {
  const { pageData = {} } = elementProps;
  const deps = Object.entries(pageData).reduce(
    (dependenciesAcc, [key, dataItem]) => {
      if (key !== 'endpointParams') {
        return [...dependenciesAcc, dataItem];
      }

      return [
        ...dependenciesAcc,
        // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
        ...Object.values(dataItem).reduce(
          (subAcc, dataItems) => [
            // @ts-expect-error TS(2488): Type 'unknown' must have a '[Symbol.iterator]()' m... Remove this comment to see the full error message
            ...subAcc,
            ...(dataItems
              ? (dataItems as any)
                  .filter((item: any) => item.data)
                  .map((item: any) => item.data)
              : []),
          ],
          [],
        ),
      ];
    },
    dependencies,
  );

  return deps;
};

export const pageDataPropTransformation = (
  value: any,
  idMap: any,
  { cloneDataItem }: any,
) =>
  Object.entries(value).reduce(
    (dataPropAcc, [pageKey, pageValue]) => ({
      ...dataPropAcc,
      [pageKey]: pageValue ? cloneDataItem(pageValue, idMap) : pageValue,
    }),
    value,
  );

class LinkPropType extends ComboType {
  constructor(propMapper?: any, includeSelf = false) {
    super(
      {
        newTab: new BoolType(),
        email: new StringType(includeSelf),
        subject: new StringType(includeSelf),
        phone: new StringType(includeSelf),
        url: new StringType(includeSelf),
        pageData: new RawDataPropType(() => [TEXT, INTEGER, DECIMAL])
          .setExtractPropTypesDependencies(extractPageDataDependencies)
          .setPropTransformation(pageDataPropTransformation),
      },
      linkResolver,
      propMapper,
    );
    this.includeSelf = true;
  }
}

export default LinkPropType;
