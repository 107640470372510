import {
  MANY_TO_MANY,
  MANY_TO_ONE,
  ONE_TO_MANY,
  ONE_TO_ONE,
  Relationship,
} from '../constants/relationships';
import { DataField } from '../models/DataTypeFields';

export const getRelationshipFromMultiOpts = (
  isMulti: any,
  isReverseMulti: any,
) => {
  if (!isMulti && !isReverseMulti) {
    return ONE_TO_ONE;
  } else if (!isMulti && isReverseMulti) {
    return MANY_TO_ONE;
  } else if (isMulti && !isReverseMulti) {
    return ONE_TO_MANY;
  }

  return MANY_TO_MANY;
};

/**
 * This side of the relationship is a single value rather than an array of ids/records
 * @param field
 */
export const isSingleSideOfRelationship = (field: DataField): boolean =>
  (!!field.relationship &&
    (field.relationship === ONE_TO_ONE ||
      field.relationship === MANY_TO_ONE)) ||
  (!!field.relatedField &&
    (field.relatedField.relationship === ONE_TO_MANY ||
      field.relatedField.relationship === ONE_TO_ONE));

export const isMultiRelationship = (
  relationship: Relationship | undefined | null,
): boolean => relationship === ONE_TO_MANY || relationship === MANY_TO_MANY;

export const isReverseMultiRelationship = (
  relationship: Relationship | undefined | null,
): boolean => relationship === MANY_TO_ONE || relationship === MANY_TO_MANY;

export const isMultiField = (field: DataField): boolean =>
  (!!field.relationship && isMultiRelationship(field.relationship)) ||
  (!!field.relatedField &&
    isReverseMultiRelationship(field.relatedField.relationship));

export const isReverseMultiField = (field: DataField): boolean =>
  (!!field.relationship && isReverseMultiRelationship(field.relationship)) ||
  (!!field.relatedField &&
    isMultiRelationship(field.relatedField.relationship));

export const reverseRelationship = (
  relationship: Relationship,
): Relationship => {
  if (relationship === ONE_TO_MANY) {
    return MANY_TO_ONE;
  }

  if (relationship === MANY_TO_ONE) {
    return ONE_TO_MANY;
  }

  return relationship;
};
