import React, { useMemo } from 'react';
import get from 'lodash/get';
import { SelectInput, TextInput } from '@noloco/components';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import {
  AVERAGE,
  COUNT,
  MAX,
  MIN,
  SUM,
} from '@noloco/core/src/constants/aggregationTypes';
import {
  CollectionLayout,
  ROWS,
  SPLIT,
  TABLE,
  TABLE_FULL,
} from '@noloco/core/src/constants/collectionLayouts';
import { PROGRESS_RING } from '@noloco/core/src/constants/elements';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import { CollectionField, FormFieldConfig } from '@noloco/core/src/models/View';
import {
  canBeCardHeroImage,
  canBeCopiedToClipboard,
  canBeStickyColumn,
  canHaveGroupSummary,
} from '@noloco/core/src/utils/fields';
import useRecordScopeOptions from '@noloco/core/src/utils/hooks/useRecordScopeOptions';
import { getText } from '@noloco/core/src/utils/lang';
import { withNullOption } from '@noloco/core/src/utils/settings';
import { UPDATE_DEBOUNCE_MS } from '../../../utils/hooks/projectHooks';
import BasicFormFieldEditor from './BasicFormFieldEditor';
import ColumnWidthEditor from './ColumnWidthEditor';
import FieldConditionsEditor, {
  getCachedConditionFieldOptions,
} from './FieldConditionsEditor';
import FieldElementTypeEditor from './FieldElementTypeEditor';
import FieldViewLinkEditor from './FieldViewLinkEditor';
import { UpdateFieldsCallback } from './FieldsListEditor';
import RelationalFieldFormFieldsEditor from './RelationalFieldFormFieldsEditor';

const NUMERIC_SUMMARY_OPTIONS = [COUNT, SUM, MAX, MIN, AVERAGE];

const LANG_KEY = 'elements.VIEW';

type Props = {
  config: CollectionField;
  dataType: DataType;
  elementId: string;
  elementPath: ElementPath;
  field: DataField;
  index: number;
  layout: CollectionLayout;
  parent: DataField | undefined;
  project: Project;
  updateFields: UpdateFieldsCallback;
};

const CollectionFieldEditor = ({
  config,
  dataType,
  elementId,
  elementPath,
  field,
  index,
  layout,
  parent,
  project,
  updateFields,
}: Props) => {
  const groupSummaryOptions = useMemo(
    () =>
      withNullOption(
        NUMERIC_SUMMARY_OPTIONS.map((aggregation) => ({
          value: aggregation,
          label: getText('elements.CHART.aggregation', aggregation),
        })),
      ),
    [],
  );

  const conditionFieldOptions = useMemo(
    () => (dataType ? getCachedConditionFieldOptions(dataType, project) : {}),
    [dataType, project],
  );

  const recordScopeOptions = useRecordScopeOptions(
    elementId,
    dataType,
    project,
  );

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-2">
        <BuildModeSwitchSection
          label={getText(LANG_KEY, 'fields.label')}
          onChange={(value: any) =>
            updateFields([index, 'label', 'hidden'], !value)
          }
          value={!get(config, 'label.hidden')}
        />
        <TextInput
          debounceMs={UPDATE_DEBOUNCE_MS}
          onChange={({ target: { value } }: any) => {
            updateFields([index, 'label', 'value'], value);
          }}
          disabled={config.hidden}
          value={get(config, 'label.value')}
          placeholder={field.display}
        />
      </div>
      {!get(config, 'editInline', false) && canBeCopiedToClipboard(field) && (
        <BuildModeSwitchSection
          label={getText(LANG_KEY, 'fields.copyToClipboard')}
          onChange={(value: boolean) =>
            updateFields([index, 'copyToClipboard'], value)
          }
          value={get(config, 'copyToClipboard', false)}
        />
      )}
      {canBeCardHeroImage(index, field, layout) && (
        <BuildModeSwitchSection
          label={getText(LANG_KEY, 'fields.heroImage')}
          onChange={(value: any) => updateFields([index, 'heroImage'], value)}
          value={get(config, 'heroImage', false)}
        />
      )}
      {(layout === ROWS || layout === SPLIT) && (
        <BuildModeInput label={getText(LANG_KEY, 'fields.columnSpan.label')}>
          <ColumnWidthEditor
            onChange={(colWidthValue) =>
              updateFields([index, 'columnWidth'], colWidthValue)
            }
            value={config.columnWidth!}
          />
        </BuildModeInput>
      )}
      {canHaveGroupSummary(index, field, layout) && (
        <BuildModeInput label={getText(LANG_KEY, 'fields.groupSummary.label')}>
          <SelectInput
            className="w-full"
            contained={true}
            options={groupSummaryOptions}
            onChange={(value: any) =>
              updateFields([index, 'groupSummary'], value)
            }
            value={get(config, 'groupSummary') || null}
          />
        </BuildModeInput>
      )}
      {canBeStickyColumn(index, layout) && (
        <BuildModeSwitchSection
          label={getText(LANG_KEY, 'fields.stickyColumn')}
          onChange={(value: any) => updateFields([index, 'sticky'], value)}
          value={get(config, 'sticky', false)}
        />
      )}
      <FieldElementTypeEditor
        config={config}
        field={field}
        index={index}
        elementPath={elementPath}
        project={project}
        updateFields={updateFields}
      />
      <FieldViewLinkEditor
        config={config as FormFieldConfig & { elementType: string }}
        label={getText('elements.VIEW.fields.rowLink.label')}
        field={field}
        onChange={(nextRowLink) =>
          updateFields([index, 'rowLink'], nextRowLink)
        }
        project={project}
        value={config.rowLink}
      />
      {!field.readOnly &&
        !parent &&
        (!get(config, 'elementType') ||
          (get(config, 'elementType') &&
            get(config, 'elementType') !== PROGRESS_RING)) &&
        (!canBeCardHeroImage(index, field, layout) ||
          !get(config, 'heroImage')) && (
          <>
            <BuildModeSwitchSection
              label={getText(LANG_KEY, 'fields.inlineEditing.label')}
              onChange={(value: any) =>
                updateFields([index, 'editInline'], value)
              }
              value={get(config, 'editInline', false)}
            />
            {get(config, 'editInline') && (
              <div className="flex flex-col space-y-4">
                <BasicFormFieldEditor
                  config={config}
                  dataType={dataType}
                  elementPath={elementPath}
                  field={field}
                  index={index}
                  showFieldTypeOptions={false}
                  updateFields={updateFields}
                  recordScopeOptions={
                    recordScopeOptions ? Object.values(recordScopeOptions) : []
                  }
                  project={project}
                />
                {(field.relationship || field.relatedField) && (
                  <RelationalFieldFormFieldsEditor
                    config={config}
                    dataType={dataType}
                    elementPath={elementPath}
                    field={field}
                    index={index}
                    project={project}
                    recordScopeOptions={get(
                      recordScopeOptions,
                      ['fieldOptions', 'options'],
                      [],
                    )}
                    updateFields={updateFields}
                  />
                )}
              </div>
            )}
          </>
        )}
      {![TABLE, TABLE_FULL].includes(layout) && dataType && (
        <div className="max-w-lg">
          <FieldConditionsEditor
            conditionFieldOptions={Object.values(conditionFieldOptions)}
            dataType={dataType}
            elementPath={elementPath}
            onChange={(path: any, value: any) =>
              updateFields([index, 'conditions', ...path], value)
            }
            project={project}
            value={get(config, 'conditions')}
          />
        </div>
      )}
    </div>
  );
};

export default CollectionFieldEditor;
