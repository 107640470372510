export const API_FORM = 'API_FORM';
export const APP_LAYOUT = 'APP_LAYOUT';
export const BADGE = 'BADGE';
export const BUTTON = 'BUTTON';
export const CHART = 'CHART';
export const CONTENT = 'CONTENT';
export const COMMENTS = 'COMMENTS';
export const COPY = 'COPY';
export const DATA_WRAPPER = 'DATA_WRAPPER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORM_V2 = 'FORM:V2';
export const FOLDER = 'FOLDER';
export const GROUP = 'GROUP';
export const ICON = 'ICON';
export const IFRAME = 'IFRAME';
export const IMAGE = 'IMAGE';
export const LABEL = 'LABEL';
export const LIST = 'LIST';
export const LINK = 'LINK';
export const PROGRESS_BAR = 'PROGRESS_BAR';
export const PROGRESS_RING = 'PROGRESS_RING';
export const DIVIDER = 'DIVIDER';
export const PAGE = 'PAGE';
export const PAGE_SWITCH = 'PAGE_SWITCH';
export const RELATIVE_DATE = 'RELATIVE_DATE';
export const TEXT = 'TEXT';
export const VIDEO = 'VIDEO';
export const YOUTUBE_VIDEO = 'YOUTUBE_VIDEO';

export const ACTION_BUTTONS = 'ACTION_BUTTONS';
export const NEW_RECORD_BUTTON = 'NEW_RECORD_BUTTON';
export const IMPORT_BUTTON = 'IMPORT_BUTTON';
export const EXPORT_BUTTON = 'EXPORT_BUTTON';
export const RECORD_VIEW = 'RECORD_VIEW';

// SECTIONS
export const BILLING = 'BILLING';
export const BREADCRUMBS = 'BREADCRUMBS';
export const COLLECTION = 'COLLECTION';
export const DETAILS = 'DETAILS';
export const HIGHLIGHTS = 'HIGHLIGHTS';
export const FILE_SHARING = 'FILE_SHARING';
export const EMBED = 'EMBED';
export const FORMS = 'FORMS';
export const FORM = 'FORM';
export const MESSAGING = 'MESSAGING';
export const NOTICE = 'NOTICE';
export const ONBOARDING_TASKS = 'ONBOARDING_TASKS';
export const STAGES = 'STAGES';
export const TABS = 'TABS';
export const TITLE = 'TITLE';
export const QUICK_LINKS = 'QUICK_LINKS';
export const VIEW = 'VIEW';
export const FILE_GALLERY = 'FILE_GALLERY';
export const CONTAINER = 'CONTAINER';

// COLLECTION ELEMENTS
export const RECORD = 'RECORD';
export const MARKDOWN = 'MARKDOWN';
export const SECTION = 'SECTION';
export const FORM_SECTION = 'FORM_SECTION';
export const FIELD_CELL = 'FIELD_CELL';

export const elements = [
  TITLE,
  COLLECTION,
  DETAILS,
  HIGHLIGHTS,
  VIDEO,
  IFRAME,
  STAGES,
  CHART,
  ACTION_BUTTONS,
  QUICK_LINKS,
  MARKDOWN,
  FILE_GALLERY,
  NOTICE,
  DIVIDER,
  COMMENTS,
  IMAGE,
  EMBED,
  CONTAINER,
];
