import React, { memo } from 'react';
import { Surface } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { StringPropSegment } from '@noloco/core/src/models/Element';
import { DataItemValueOption } from '@noloco/core/src/utils/state';
import ConditionValueInput from './ConditionValueInput';

type BackendConditionValueEditorProps = {
  contained: boolean;
  dataOptions?: DataItemValueOption[];
  disabled?: boolean;
  field?: DataField;
  dataType: DataType;
  includeRelationships?: boolean;
  multiple: boolean;
  onChange: (value: StringPropSegment[] | null) => void;
  placeholder?: string;
  surface?: Surface;
  value: StringPropSegment[] | undefined;
};

const BackendConditionValueEditor = memo(
  ({
    contained,
    dataOptions,
    disabled,
    field,
    dataType,
    includeRelationships,
    multiple,
    onChange,
    placeholder,
    surface,
    value,
  }: BackendConditionValueEditorProps) => (
    <ConditionValueInput
      checkFieldPermissions={false}
      contained={contained}
      dataOptions={dataOptions}
      dataType={dataType}
      disabled={disabled}
      elementPath={['condition']}
      field={field}
      includeRelationships={includeRelationships}
      includeSelf={false}
      multiple={multiple}
      onChange={onChange}
      placeholder={placeholder}
      surface={surface}
      value={value}
    />
  ),
);

(BackendConditionValueEditor as any).defaultProps = {
  acceptableDataTypes: undefined,
  includeRelationships: false,
  includeUniqueColumnar: false,
  surface: LIGHT,
};

export default BackendConditionValueEditor;
