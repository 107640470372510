import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import { CONDITIONAL_FIELD_VISIBILITY } from '@noloco/core/src/constants/features';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import cappedMemoize from '@noloco/core/src/utils/cappedMemoize';
import { getDataTypesKey } from '@noloco/core/src/utils/data';
import { getDataTypeFieldOptions } from '@noloco/core/src/utils/getDataTypeFieldOptions';
import { getText } from '@noloco/core/src/utils/lang';
import { getAuthUserTypeOptionsOfTypes } from '@noloco/core/src/utils/renderedOptions';
import useIsFeatureEnabled from '../../../utils/hooks/useIsFeatureEnabled';
import FeatureLockedSwitch from '../../FeatureLockedSwitch';
import Guide from '../../Guide';
import ConditionsEditor from '../ConditionsEditor';

const LANG_KEY = 'elements.VIEW';

const getNewConditionRule = () => [
  [
    {
      field: null,
      operator: null,
      value: null,
    },
  ],
];

export const getConditionFieldOptions = (
  dataType: DataType,
  project: Project,
  options = {},
) => {
  const fieldOptions = getDataTypeFieldOptions(dataType, project, options);

  const currentUserConditionFieldOptions =
    getAuthUserTypeOptionsOfTypes(project);

  return {
    fieldOptions: {
      label: dataType.display,
      options: fieldOptions,
    },
    userOptions: {
      label: getText('elements.PAGE.state.user'),
      help: getText('elements.PAGE.state.help'),
      options: currentUserConditionFieldOptions,
    },
  };
};

export const getCachedConditionFieldOptions = cappedMemoize(
  (dataType: any, project: any, options = {}) =>
    getConditionFieldOptions(dataType, project, options),
  {
    getKey: ([dataType, project, options]) =>
      `${getDataTypesKey(project.dataTypes)}:${dataType.name}:${JSON.stringify(
        options,
      )}`,
    maxKeys: 20,
  },
);

const FieldConditionsEditor = ({
  conditionFieldOptions,
  dataType,
  elementPath,
  onChange,
  project,
  value,
}: any) => {
  const isEnabled = useIsFeatureEnabled(CONDITIONAL_FIELD_VISIBILITY);

  return (
    <BuildModeSwitchSection
      guide={
        <Guide href="https://guides.noloco.io/field-formatting/field-visibility-conditions">
          {getText(LANG_KEY, 'fields.conditions.guide')}
        </Guide>
      }
      label={getText(LANG_KEY, 'fields.conditions.label')}
      value={!!value && isEnabled}
      onChange={(nextValue: any) => {
        onChange([], nextValue ? getNewConditionRule() : null);
      }}
      switchEl={
        <FeatureLockedSwitch
          feature={CONDITIONAL_FIELD_VISIBILITY}
          value={!!value && isEnabled}
          onChange={(nextValue: any) => {
            onChange([], nextValue ? getNewConditionRule() : null);
          }}
          size="sm"
        />
      }
    >
      <div className="flex max-w-full flex-col items-center justify-between px-2 py-4">
        <ConditionsEditor
          inline={true}
          dataType={dataType}
          fieldOptions={conditionFieldOptions}
          updateConditions={onChange}
          rules={value}
          project={project}
          elementPath={elementPath}
        />
      </div>
    </BuildModeSwitchSection>
  );
};

export default FieldConditionsEditor;
