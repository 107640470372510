import first from 'lodash/first';
import get from 'lodash/get';
import shortId from 'shortid';
import { DepValue } from '../models/Element';
import { RecordValue } from '../models/Record';
import { GroupBy } from '../models/View';
import { ChartAggregation } from './chartAggregations';

export const ROW = '__row';
export const SUMMARY = '__summary';

export type PivotTableHeader = {
  alignRight?: boolean;
  id: string;
  label?: RecordValue;
  labels?: RecordValue[];
  subGroupId?: string;
  valueKey?: string;
  valueLabel?: string;
};

export type PivotTableValue = {
  aggregation?: ChartAggregation;
  field?: DepValue;
  id: string;
  label?: string;
};

export type PivotTable = {
  aggregation?: ChartAggregation;
  columnGrouping?: GroupBy[];
  rowGrouping: [GroupBy];
  showTableAggregation?: boolean;
  values?: PivotTableValue[];
};

export const getRowGrouping = (pivotTable: PivotTable) => {
  const grouping = get(pivotTable, 'rowGrouping');

  if (Array.isArray(grouping)) {
    return first(grouping) as GroupBy;
  }

  return { field: grouping, id: shortId.generate() };
};
