import { useRegisteredDragSource } from './useRegisteredDragSource';
import { useOptionalFactory } from '../useOptionalFactory';
import { useDragSourceMonitor } from './useDragSourceMonitor';
import { useDragSourceConnector } from './useDragSourceConnector';
import { useCollectedProps } from '../useCollectedProps';
import { useConnectDragPreview, useConnectDragSource } from './connectors';
import { invariant } from '@react-dnd/invariant';
/**
 * useDragSource hook
 * @param sourceSpec The drag source specification (object or function, function preferred)
 * @param deps The memoization deps array to use when evaluating spec changes
 */

export function useDrag(specArg, deps) {
  var spec = useOptionalFactory(specArg, deps);
  invariant(!spec.begin, "useDrag::spec.begin was deprecated in v14. Replace spec.begin() with spec.item(). (see more here - https://react-dnd.github.io/react-dnd/docs/api/use-drag)");
  var monitor = useDragSourceMonitor();
  var connector = useDragSourceConnector(spec.options, spec.previewOptions);
  useRegisteredDragSource(spec, monitor, connector);
  return [useCollectedProps(spec.collect, monitor, connector), useConnectDragSource(connector), useConnectDragPreview(connector)];
}