import React, { Suspense, lazy, useCallback, useMemo } from 'react';
import { Box } from '@darraghmckay/tailwind-react-ui';
import {
  IconAdjustmentsHorizontal,
  IconAlignLeft,
  IconAt,
  IconCalendar,
  IconCalendarTime,
  IconChevronRight,
  IconClearFormatting,
  IconClock,
  IconCurrencyDollar,
  IconPercentage,
  IconSeparator,
  IconSquareNumber1,
  IconSquareNumber2,
  IconSquareNumber3,
  IconSquareNumber4,
  IconSquareNumber5,
  IconSquareNumber6,
  IconSquareNumber7,
  IconSquareNumber8,
  IconStar,
  IconWorldWww,
} from '@tabler/icons-react';
import { IconNetwork } from '@tabler/icons-react';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import set from 'lodash/fp/set';
import unset from 'lodash/fp/unset';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import range from 'lodash/range';
import { DEFAULT_MAX_RATING, Loader } from '@noloco/components';
import { Label, SelectInput, Switch, TextInput } from '@noloco/components';
import { AIRTABLE } from '@noloco/core/src/constants/dataSources';
import {
  DATE,
  DECIMAL,
  DURATION,
  DataFieldType,
  FORMULA,
  INTEGER,
  NUMERIC_DATATYPES,
  OBJECT,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import {
  COORDINATES,
  CURRENCY,
  DATE as DATE_FORMAT,
  DATE_RANGE,
  DATE_TIME,
  EMAIL,
  FULL_NAME,
  FieldFormat,
  IP_ADDRESS,
  MULTILINE_TEXT,
  OBJECT_FORMATS,
  PERCENTAGE,
  RATING,
  SINGLE_LINE_TEXT,
  SLIDER,
  TIME,
  UNFORMATTED_NUMBER,
  URL,
} from '@noloco/core/src/constants/fieldFormats';
import { SUB_FIELD_CONFIG } from '@noloco/core/src/constants/objects';
import { BaseDataFieldOption } from '@noloco/core/src/models/DataTypeFields';
import {
  getAllowedOptionsForFieldSource,
  getValidTypeOptionsForFieldSource,
} from '@noloco/core/src/utils/fieldTypeOptions';
import { getText } from '@noloco/core/src/utils/lang';
import Guide from '../../Guide';
import OptionEditor from './OptionEditor';

type OnChangeEvent = React.ChangeEvent<HTMLInputElement>;

const LazyFormulaStringPropEditor = lazy(
  () => import('../../canvas/FormulaStringPropEditor'),
);

export const FIELDS_WITH_TYPE_OPTIONS = [
  TEXT,
  DECIMAL,
  DURATION,
  INTEGER,
  DATE,
  OBJECT,
];

const formatIcons = {
  [CURRENCY]: IconCurrencyDollar,
  [PERCENTAGE]: IconPercentage,
  [SLIDER]: IconAdjustmentsHorizontal,
  [MULTILINE_TEXT]: IconAlignLeft,
  [SINGLE_LINE_TEXT]: IconSeparator,
  [DATE_FORMAT]: IconCalendar,
  [DATE_TIME]: IconCalendarTime,
  [EMAIL]: IconAt,
  [IP_ADDRESS]: IconNetwork,
  [RATING]: IconStar,
  [TIME]: IconClock,
  [UNFORMATTED_NUMBER]: IconClearFormatting,
  [URL]: IconWorldWww,
};

const formatOptionsWithDefault = (
  options: FieldFormat[],
  type: DataFieldType,
  allowedFormats: FieldFormat[] | undefined,
) => [
  {
    label: getText('data.typeOptions.format.default'),
    icon: <IconChevronRight size={16} />,
    value: undefined,
  },
  ...options.map((option: FieldFormat) => ({
    value: option,
    icon: <Box is={formatIcons[option]} size={16} />,
    label: getText('data.typeOptions.format', type, option),
    disabled: allowedFormats && !allowedFormats.includes(option),
  })),
];

const precisionIcons = {
  1: IconSquareNumber1,
  2: IconSquareNumber2,
  3: IconSquareNumber3,
  4: IconSquareNumber4,
  5: IconSquareNumber5,
  6: IconSquareNumber6,
  7: IconSquareNumber7,
  8: IconSquareNumber8,
};

const maxRatingOptions = [...Array(9)].map((_, i) => ({
  label: i + 2,
  value: i + 2,
}));

const getFieldFormatOptions = (
  fieldType: DataFieldType,
  allowedFormats: FieldFormat[] | undefined,
  formatValue: FieldFormat | undefined,
) => {
  if (allowedFormats && formatValue && !allowedFormats.includes(formatValue)) {
    return formatOptionsWithDefault([formatValue], fieldType, allowedFormats);
  } else if (
    allowedFormats &&
    (!formatValue || allowedFormats.includes(formatValue))
  ) {
    return formatOptionsWithDefault(allowedFormats, fieldType, allowedFormats);
  }

  switch (fieldType) {
    case TEXT:
      return formatOptionsWithDefault(
        [SINGLE_LINE_TEXT, MULTILINE_TEXT, EMAIL, IP_ADDRESS, URL],
        TEXT,
        allowedFormats,
      );
    case INTEGER:
      return formatOptionsWithDefault(
        [CURRENCY, RATING, UNFORMATTED_NUMBER, SLIDER],
        INTEGER,
        allowedFormats,
      );
    case DECIMAL:
      return formatOptionsWithDefault(
        [CURRENCY, PERCENTAGE, UNFORMATTED_NUMBER],
        DECIMAL,
        allowedFormats,
      );
    case DATE:
      return formatOptionsWithDefault(
        [DATE_FORMAT, DATE_TIME],
        DATE,
        allowedFormats,
      );
    case DURATION:
      return formatOptionsWithDefault([TIME], DURATION, allowedFormats);
    default:
      return [];
  }
};

const getFieldPrecisionOptions = (fieldType: any) => {
  switch (fieldType) {
    case COORDINATES:
    case DECIMAL:
      return [
        {
          label: getText('data.typeOptions.precision.default'),
          icon: <IconChevronRight size={16} />,
          value: undefined,
        },
        ...range(1, 9).map((option) => ({
          value: option,
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          icon: <Box is={precisionIcons[option]} size={16} />,
          label: (1).toFixed(option),
        })),
      ];
    default:
      return [];
  }
};

const FieldTypeOptionsEditor = ({
  dataType,
  className,
  fieldSource,
  fieldType,
  onChange,
  readOnly,
  surface,
  value,
  formulaChangeLoading,
}: any) => {
  const onChangeHandler = useCallback(
    (key, newValue) => onChange(set(key, newValue, value)),
    [onChange, value],
  );

  const debouncedOnChangeHandler = useMemo(
    () => debounce(onChangeHandler, 500, { leading: false }),
    [onChangeHandler],
  );

  const validTypeOptionsForFieldSource = useMemo(
    () => getValidTypeOptionsForFieldSource(fieldType, fieldSource),
    [fieldSource, fieldType],
  );

  const format = get(value, 'format');

  const onSwitchSubFields = useCallback(
    (subField, newValue) => {
      if (newValue) {
        onChange(
          set(
            ['subFields', subField],
            get(SUB_FIELD_CONFIG, [format, subField]),
            value,
          ),
        );
      } else {
        onChange(unset(['subFields', subField], value));
      }
    },
    [format, onChange, value],
  );

  const onSetSubFieldOptions = useCallback(
    (subField) => (options: BaseDataFieldOption[]) =>
      debouncedOnChangeHandler(['subFields', subField, 'options'], options),
    [debouncedOnChangeHandler],
  );

  const allowedFormatOptions = useMemo(
    () => getAllowedOptionsForFieldSource(fieldType, fieldSource),
    [fieldSource, fieldType],
  );

  const formatOptions = useMemo(
    () =>
      getFieldFormatOptions(fieldType, allowedFormatOptions, format).filter(
        ({ value }) => !OBJECT_FORMATS.includes(value as FieldFormat),
      ),
    [allowedFormatOptions, fieldType, format],
  );

  const formatIsDisabled = useMemo(
    () =>
      !!allowedFormatOptions &&
      !allowedFormatOptions.includes(format) &&
      format !== undefined,
    [allowedFormatOptions, format],
  );

  const isDisabled = useMemo(
    () => formatIsDisabled || fieldSource === AIRTABLE,
    [fieldSource, formatIsDisabled],
  );

  const precisionOptions = useMemo(
    () => getFieldPrecisionOptions(fieldType),
    [fieldType],
  );

  const precision = get(value, 'precision');
  const maxRating = get(value, 'max', DEFAULT_MAX_RATING);

  return (
    <div
      className={classNames('flex flex-col', className)}
      data-testid="field-type-options-editor"
    >
      {((value && value.formula !== undefined) || fieldType === FORMULA) && (
        <>
          <div className="flex justify-between">
            <div>
              <Label surface={surface}>
                {getText('data.typeOptions.formula.label')}
              </Label>
            </div>
            <div>
              <Guide href="https://guides.noloco.io/data/collections/formulas">
                {getText('data.typeOptions.formula.guideText')}
              </Guide>
            </div>
          </div>
          <Suspense fallback={<Loader />}>
            <LazyFormulaStringPropEditor
              onChange={(formula: any) =>
                value ? onChangeHandler('formula', formula) : null
              }
              dataType={dataType}
              placeholder={getText('data.typeOptions.formula.placeholder')}
              value={get(value, 'formula')}
              disabled={formulaChangeLoading}
              isNewFormula={fieldType === FORMULA}
              formulaChangeLoading={formulaChangeLoading}
              surface={surface}
            />
          </Suspense>
        </>
      )}
      {formatOptions.length > 1 && (
        <>
          <Label surface={surface}>
            {getText('data.typeOptions.format.label')}
          </Label>
          <SelectInput
            className="mt-1"
            data-testid="field-type-options-format-input"
            contained={true}
            disabled={
              readOnly ||
              formatIsDisabled ||
              !validTypeOptionsForFieldSource.format
            }
            onChange={(newValue: string) => onChangeHandler('format', newValue)}
            placeholder={getText('data.typeOptions.format.default')}
            options={formatOptions}
            surface={surface}
            value={format}
          />
        </>
      )}
      {NUMERIC_DATATYPES.includes(fieldType) &&
        (!format || (format && format === UNFORMATTED_NUMBER)) && (
          <div className="grid grid-cols-2 gap-2">
            <div>
              <Label className="mt-4" surface={surface}>
                {getText('data.typeOptions.prefix.label')}
              </Label>
              <TextInput
                className="mt-1"
                onChange={(event: OnChangeEvent) =>
                  debouncedOnChangeHandler('prefix', event.target.value)
                }
                readOnly={
                  readOnly ||
                  isDisabled ||
                  !validTypeOptionsForFieldSource.prefix
                }
                onBlur={(event: OnChangeEvent) =>
                  onChangeHandler('prefix', event.target.value)
                }
                placeholder={getText('data.typeOptions.prefix.placeholder')}
                surface={surface}
                value={get(value, 'prefix')}
              />
            </div>
            <div>
              <Label className="mt-4" surface={surface}>
                {getText('data.typeOptions.suffix.label')}
              </Label>
              <TextInput
                className="mt-1"
                onChange={(event: OnChangeEvent) =>
                  debouncedOnChangeHandler('suffix', event.target.value)
                }
                readOnly={
                  readOnly ||
                  isDisabled ||
                  !validTypeOptionsForFieldSource.suffix
                }
                onBlur={(event: OnChangeEvent) =>
                  onChangeHandler('suffix', event.target.value)
                }
                placeholder={getText('data.typeOptions.suffix.placeholder')}
                surface={surface}
                value={get(value, 'suffix')}
              />
            </div>
          </div>
        )}
      {fieldType === TEXT && !isNil(value.max) && (
        <div className="mt-8 flex w-full flex-col space-y-3">
          <div className="flex w-full items-center justify-between">
            <Label surface={surface}>
              {getText('data.typeOptions.maxCharacters.label')}
            </Label>
            <Switch
              data-testid="field-type-options-max-character-switch"
              disabled={true}
              size="sm"
              value={!isNil(value.max)}
            />
          </div>
          <TextInput
            className="mt-1"
            data-testid="field-type-options-max-character-input"
            disabled={true}
            surface={surface}
            value={value.max}
          />
        </div>
      )}
      {format === CURRENCY && (
        <>
          <Label className="mt-4" surface={surface}>
            {getText('data.typeOptions.symbol.label')}
          </Label>
          <TextInput
            className="mt-1"
            data-testid="field-type-options-currency-input"
            onChange={(event: OnChangeEvent) =>
              debouncedOnChangeHandler('symbol', event.target.value)
            }
            readOnly={
              readOnly || isDisabled || !validTypeOptionsForFieldSource.symbol
            }
            placeholder={getText('data.typeOptions.symbol.placeholder')}
            onBlur={(event: OnChangeEvent) =>
              onChangeHandler('symbol', event.target.value)
            }
            surface={surface}
            value={get(value, 'symbol')}
          />
        </>
      )}
      {format === RATING && (
        <div className="mt-4 flex items-center justify-between">
          <Label surface={surface}>
            {getText('data.typeOptions.maxRating.label')}
          </Label>
          <SelectInput
            data-testid="field-type-options-rating-input"
            disabled={
              readOnly || isDisabled || !validTypeOptionsForFieldSource.max
            }
            options={maxRatingOptions}
            surface={surface}
            value={maxRating}
            onChange={(newValue: number) => onChangeHandler('max', newValue)}
          />
        </div>
      )}
      {format === SLIDER && (
        <div className="grid grid-cols-3 gap-2">
          <div>
            <Label className="mt-4" surface={surface}>
              {getText('data.typeOptions.slider.min')}
            </Label>
            <TextInput
              className="mt-1"
              type="number"
              onChange={(event: OnChangeEvent) =>
                debouncedOnChangeHandler('min', Number(event.target.value))
              }
              readOnly={
                readOnly || isDisabled || !validTypeOptionsForFieldSource.min
              }
              onBlur={(event: OnChangeEvent) =>
                onChangeHandler('min', Number(event.target.value))
              }
              placeholder={getText('data.typeOptions.slider.min')}
              surface={surface}
              value={get(value, 'min')}
            />
          </div>
          <div>
            <Label className="mt-4" surface={surface}>
              {getText('data.typeOptions.slider.max')}
            </Label>
            <TextInput
              className="mt-1"
              type="number"
              onChange={(event: OnChangeEvent) =>
                debouncedOnChangeHandler('max', Number(event.target.value))
              }
              readOnly={
                readOnly || isDisabled || !validTypeOptionsForFieldSource.max
              }
              onBlur={(event: OnChangeEvent) =>
                onChangeHandler('max', Number(event.target.value))
              }
              placeholder={getText('data.typeOptions.slider.max')}
              surface={surface}
              value={get(value, 'max')}
            />
          </div>
          <div>
            <Label className="mt-4" surface={surface}>
              {getText('data.typeOptions.slider.step')}
            </Label>
            <TextInput
              className="mt-1"
              type="number"
              onChange={(event: OnChangeEvent) =>
                debouncedOnChangeHandler('step', Number(event.target.value))
              }
              readOnly={
                readOnly || isDisabled || !validTypeOptionsForFieldSource.step
              }
              onBlur={(event: OnChangeEvent) =>
                onChangeHandler('step', Number(event.target.value))
              }
              placeholder={getText('data.typeOptions.slider.step')}
              surface={surface}
              value={get(value, 'step')}
            />
          </div>
        </div>
      )}
      {precisionOptions.length > 0 && (
        <>
          <Label className="mt-4" surface={surface}>
            {getText('data.typeOptions.precision.label')}
          </Label>
          <SelectInput
            className="mt-1"
            data-testid="field-type-options-precision-input"
            contained={true}
            disabled={
              readOnly ||
              isDisabled ||
              !validTypeOptionsForFieldSource.precision
            }
            onChange={(newValue: string) =>
              onChangeHandler('precision', newValue)
            }
            options={precisionOptions}
            placeholder={getText('data.typeOptions.precision.placeholder')}
            surface={surface}
            value={precision}
          />
        </>
      )}
      {format === DATE_RANGE && (
        <div className="mt-4 flex w-full items-center justify-between">
          <Label surface={surface}>
            {getText('data.typeOptions.time.label')}
          </Label>
          <Switch
            data-testid="field-type-options-time-switch"
            disabled={
              readOnly || isDisabled || !validTypeOptionsForFieldSource.time
            }
            onChange={(newValue: boolean) => onChangeHandler('time', newValue)}
            size="sm"
            value={value.time}
          />
        </div>
      )}
      {(format === DATE_TIME || format === DATE_RANGE) && (
        <div className="mt-4 flex w-full items-center justify-between">
          <Label surface={surface}>
            {getText('data.typeOptions.timeZone.label')}
          </Label>
          <Switch
            data-testid="field-type-options-utc-timezone-switch"
            disabled={
              readOnly || isDisabled || !validTypeOptionsForFieldSource.timeZone
            }
            onChange={(newValue: boolean) =>
              onChangeHandler('timeZone', newValue ? 'UTC' : undefined)
            }
            size="sm"
            value={value.timeZone === 'UTC'}
          />
        </div>
      )}
      {format === FULL_NAME && (
        <>
          <div className="mt-4 flex w-full items-center justify-between">
            <Label surface={surface}>
              {getText('data.typeOptions', FULL_NAME, 'title')}
            </Label>
            <Switch
              data-testid="field-type-options-title-switch"
              disabled={readOnly || isDisabled}
              onChange={(newValue: boolean) =>
                onSwitchSubFields('title', newValue)
              }
              size="sm"
              value={!!value.subFields.title}
            />
          </div>
          {value.subFields.title && (
            <OptionEditor
              className="ml-4"
              options={value.subFields.title.options}
              setOptions={onSetSubFieldOptions('title')}
              surface={surface}
            />
          )}
          <div className="mt-4 flex w-full items-center justify-between">
            <Label surface={surface}>
              {getText('data.typeOptions', FULL_NAME, 'middle')}
            </Label>
            <Switch
              data-testid="field-type-options-middle-switch"
              disabled={readOnly || isDisabled}
              onChange={(newValue: boolean) =>
                onSwitchSubFields('middle', newValue)
              }
              size="sm"
              value={!!value.subFields.middle}
            />
          </div>
        </>
      )}
    </div>
  );
};

FieldTypeOptionsEditor.defaultProps = {
  value: {},
  isNewFormula: false,
  formulaChangeLoading: false,
};

export default FieldTypeOptionsEditor;
