import { useCallback, useMemo } from 'react';
import { IconAlertTriangle } from '@tabler/icons-react';
import get from 'lodash/get';
import { SwitchButton, TextInput } from '@noloco/components/src';
import CollectionSortInput from '@noloco/ui/src/components/editor/customerEditors/CollectionSortInput';
import {
  UPDATE_DEBOUNCE_MS,
  UpdatePropertyCallback,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { VIEW_SORT } from '../../../constants/buildMode';
import { INTERNAL } from '../../../constants/dataSources';
import { DataType } from '../../../models/DataTypes';
import { Element } from '../../../models/Element';
import useRouter from '../../../utils/hooks/useRouter';
import { getText } from '../../../utils/lang';
import { Page } from '../../../utils/pages';
import BuildModeInput from '../BuildModeInput';
import BuildModeSection from '../BuildModeSection';

type BuildModeViewSortAndLimitSettingsProps = {
  dataType: DataType;
  element: Page | Element;
  updateProperty: UpdatePropertyCallback;
};

const isEmpty = (value: any) =>
  !value ||
  (Array.isArray(value) &&
    value.every((dataItem) => !dataItem.text && !dataItem.data));

const BuildModeViewSortAndLimitSettings = ({
  dataType,
  element,
  updateProperty,
}: BuildModeViewSortAndLimitSettingsProps) => {
  const { pushQueryParams } = useRouter();
  const { dataList = {} } = element.props || {};

  const {
    dataSource = INTERNAL,
    limit,
    orderBy,
    showPagination,
  } = useMemo(() => dataList, [dataList]);

  const updateDataListProps = useCallback(
    (path, value) => updateProperty(['dataList', ...path], value),
    [updateProperty],
  );

  const onUpdateSort = useCallback(
    (option) => updateDataListProps(['orderBy'], option),
    [updateDataListProps],
  );

  const onUpdateShowPagination = useCallback(
    (value) => updateDataListProps(['showPagination'], value),
    [updateDataListProps],
  );

  const onUpdateLimit = useCallback(
    ({ target: { value } }) => {
      updateProperty(['dataList', 'limit'], [{ text: value }]);
      const qsSuffix = element.id;

      pushQueryParams({
        _after: undefined,
        _before: undefined,
        [`_after${qsSuffix}`]: undefined,
        [`_before${qsSuffix}`]: undefined,
      });
    },
    [element, updateProperty, pushQueryParams],
  );

  return (
    <BuildModeSection
      className="border-t"
      id={VIEW_SORT}
      sticky={true}
      title={getText('rightSidebar.editor.sortAndLimit')}
    >
      <div className="mb-2 w-full space-y-4 p-2 text-xs text-gray-400">
        <>
          {dataSource === INTERNAL && (
            <BuildModeInput label={getText('elements.LIST.sort')}>
              <CollectionSortInput
                className="w-full"
                shiftRight={true}
                dataType={dataType}
                onChange={onUpdateSort}
                value={orderBy}
              />
            </BuildModeInput>
          )}
          <BuildModeInput label={getText('elements.LIST.pageSize')}>
            <div className="flex items-center space-x-2">
              <div className="w-20">
                <TextInput
                  debounceMs={UPDATE_DEBOUNCE_MS}
                  onChange={onUpdateLimit}
                  value={get(limit, [0, 'text'], '')}
                  type="number"
                  placeholder={getText('elements.LIST.limit.placeholder')}
                />
              </div>
              {dataSource === INTERNAL && !isEmpty(limit) && (
                <SwitchButton
                  className="h-8 w-full rounded-lg"
                  inverseColors={true}
                  onChange={(value) => onUpdateShowPagination(value)}
                  options={[
                    {
                      label: getText('elements.LIST.paginate'),
                      value: true,
                    },
                    {
                      label: getText('elements.LIST.none'),
                      value: false,
                    },
                  ]}
                  value={showPagination}
                />
              )}
            </div>
          </BuildModeInput>
          {dataSource === INTERNAL && isEmpty(limit) && (
            <div className="my-3 flex items-center space-x-3 rounded-lg bg-yellow-100 p-3 py-2">
              <IconAlertTriangle
                size={22}
                className="mt-1 flex-shrink-0 text-yellow-500"
              />

              <p className="text-xs text-yellow-700">
                {getText('elements.LIST.limit.warning')}
              </p>
            </div>
          )}
        </>
      </div>
    </BuildModeSection>
  );
};

export default BuildModeViewSortAndLimitSettings;
