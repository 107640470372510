export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const BULK_UPDATE = 'BULK_UPDATE';
export const DELETE = 'DELETE';
export const NAVIGATE: ActionType = 'NAVIGATE';
export const INVITE_USER = 'INVITE';
export const OPEN_STRIPE_PORTAL = 'OPEN_STRIPE_PORTAL';
export const API_REQUEST = 'API_REQUEST';
export const ON_DEMAND = 'ON_DEMAND';
export const IFRAME = 'IFRAME';
export const COPY = 'COPY';
export const VIEW = 'VIEW';
export const SCAN_BARCODE = 'SCAN_BARCODE';

const actionTypes = [
  API_REQUEST,
  CREATE,
  UPDATE,
  DELETE,
  OPEN_STRIPE_PORTAL,
  INVITE_USER,
  VIEW,
];

export type ActionType =
  | 'CREATE'
  | 'UPDATE'
  | 'DELETE'
  | 'ON_DEMAND'
  | 'NAVIGATE'
  | 'IFRAME'
  | 'COPY'
  | 'VIEW'
  | 'SCAN_BARCODE';

export const ACTION_OPTIONS: ActionType[] = [
  UPDATE,
  CREATE,
  DELETE,
  NAVIGATE,
  ON_DEMAND,
  IFRAME,
  VIEW,
  COPY,
  SCAN_BARCODE,
];

export default actionTypes;
