import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import upperFirst from 'lodash/upperFirst';
import CollectionFieldEditor from '@noloco/ui/src/components/editor/customerEditors/CollectionFieldEditor';
import FieldsListEditor from '@noloco/ui/src/components/editor/customerEditors/FieldsListEditor';
import { CHARTS, SINGLE_RECORD } from '../../../constants/collectionLayouts';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import { Element, ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { Page } from '../../../utils/pages';

type BuildModeViewFieldsTabProps = {
  dataType: DataType;
  element: Page;
  elementPath: ElementPath;
  project: Project;
  section?: Element;
  sectionPropPath?: ElementPath;
  updateProperty: (elementPath: ElementPath, value: any) => void;
};

const BuildModeViewFieldsTab = ({
  dataType: collectionDataType,
  element,
  elementPath,
  project,
  section,
  sectionPropPath = [],
  updateProperty,
}: BuildModeViewFieldsTabProps) => {
  const collectionElement = useMemo(
    () => (section ?? element) as Page | Element,
    [section, element],
  );

  const collectionElementPath = useMemo(
    () => (section ? sectionPropPath : elementPath),
    [section, sectionPropPath, elementPath],
  );

  const { dataList, layout, fields = [] } = collectionElement.props || {};

  const dataType = useMemo(() => {
    const typeName = get(dataList, 'dataType');

    return (
      (typeName && project.dataTypes.getByName(typeName)) ?? collectionDataType
    );
  }, [dataList, collectionDataType, project.dataTypes]);

  const onFieldsChange = useCallback(
    (path, value) => updateProperty(['fields', ...path], value),
    [updateProperty],
  );

  return (
    <>
      <div className="text-xs">
        {dataType && layout !== SINGLE_RECORD && layout !== CHARTS && (
          <FieldsListEditor
            allowChildFieldsFilter={({
              relatedField,
              relationship,
            }: DataField) => !!relatedField || !!relationship}
            dataType={dataType}
            dataTypes={project.dataTypes}
            fields={dataType.fields}
            getNewFieldConfig={(field: any) => ({
              label: { value: upperFirst(field.display) },
            })}
            onFieldsChange={onFieldsChange}
            permissionType="read"
            sticky={true}
            value={fields}
          >
            {({ config, field, parent, index, updateFields }: any) => (
              <CollectionFieldEditor
                config={config}
                dataType={dataType}
                elementId={collectionElement.id}
                elementPath={collectionElementPath}
                field={field}
                index={index}
                layout={layout}
                parent={parent}
                project={project}
                updateFields={updateFields}
              />
            )}
          </FieldsListEditor>
        )}
      </div>
    </>
  );
};

export default BuildModeViewFieldsTab;
