import { useEffect, useState } from 'react';
import { DataType } from '../../../models/DataTypes';
import { Action } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { BaseRecord } from '../../../models/Record';
import useActions from '../../../utils/hooks/useActions';
import useOneClickActionMutations from '../../../utils/hooks/useOneClickActionMutations';

type OneClickRecordActionButtonProps = {
  action: Action;
  actionIndex: number;
  dataType: DataType;
  onCopy: (action: Action, setIsLoading: (isLoading: boolean) => void) => void;
  onDelete: (
    action: Action,
    setIsLoading: (isLoading: boolean) => void,
  ) => void;
  onNavigate: (action: Action) => void;
  onNext: (actionResult?: any) => void;
  onRunWorkflow: (
    action: Action,
    setIsLoading: (isLoading: boolean) => void,
  ) => void;
  project: Project;
  record?: BaseRecord;
};

const OneClickRecordActionButton = ({
  action,
  actionIndex,
  dataType,
  onCopy,
  onDelete,
  onNavigate,
  onNext,
  onRunWorkflow,
  project,
  record,
}: OneClickRecordActionButtonProps) => {
  const [lastExecuted, setLastExecuted] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { onCreate, onUpdate } = useOneClickActionMutations(
    action,
    dataType,
    project,
    record,
    onNext,
  );

  const { onExecuteAction } = useActions(
    onCreate,
    onDelete,
    onNavigate,
    onNext,
    onRunWorkflow,
    onUpdate,
    onCopy,
  );

  useEffect(() => {
    if (!isLoading && lastExecuted !== actionIndex) {
      setLastExecuted(actionIndex);
      onExecuteAction(action, setIsLoading);
    }
  }, [
    action,
    actionIndex,
    isLoading,
    lastExecuted,
    onExecuteAction,
    setIsLoading,
  ]);

  return null;
};

export default OneClickRecordActionButton;
