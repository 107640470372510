import React, { useCallback, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UPDATE_DATA_FIELD } from '../../../queries/project';
import { useUpdateDataField } from '../../../utils/hooks/useUpdateDataTypes';
import FieldTypeOptionsEditor from './FieldTypeOptionsInput';

const EditFieldTypeOptions = ({
  className,
  dataType,
  projectName,
  readOnly,
  field,
  refetchData,
  surface,
}: any) => {
  const updateDataField = useUpdateDataField();
  const [updateDataFieldMutation] = useMutation(UPDATE_DATA_FIELD);
  const [formulaChangeLoading, setLoading] = useState(false);

  const handleChange = useCallback(
    (nextTypeOptions) => {
      const hasFormula = nextTypeOptions.formula !== undefined;
      if (hasFormula) {
        setLoading(true);
      }
      updateDataFieldMutation({
        variables: { projectName, id: field.id, typeOptions: nextTypeOptions },
      }).then(({ data }) => {
        if (data.updateDataField) {
          updateDataField({
            dataTypeId: dataType.id,
            dataField: data.updateDataField,
          });

          if (hasFormula) {
            return refetchData().then(() => setLoading(false));
          }
        }
      });
    },
    [
      dataType.id,
      field.id,
      projectName,
      refetchData,
      updateDataField,
      updateDataFieldMutation,
    ],
  );

  return (
    <FieldTypeOptionsEditor
      projectName={projectName}
      dataType={dataType}
      className={className}
      formulaChangeLoading={formulaChangeLoading}
      fieldType={field.type}
      fieldSource={field.source}
      readOnly={readOnly}
      onChange={handleChange}
      surface={surface}
      value={field.typeOptions}
    />
  );
};

export default EditFieldTypeOptions;
