import { forwardRef } from 'react';
import { BaseRecord } from '../../../../models/Record';
import useIsTable from '../../../../utils/hooks/useIsTable';
import {
  DroppableGroupConfig,
  useDroppableGroup,
} from '../DroppableBoardGroup';
import GroupHeader, { GroupHeaderProps } from './GroupHeader';

type Props = Omit<GroupHeaderProps, 'isTable'> &
  Omit<DroppableGroupConfig, 'elementRef'> & {
    dropIndicator: JSX.Element;
  } & {
    index: number;
    selectedRows: BaseRecord[];
    setSelectedRows: (
      setter: (currentValue: BaseRecord[]) => BaseRecord[],
    ) => void;
  };

const DroppableGroupHeader = forwardRef<HTMLTableRowElement, Props>(
  (
    {
      bulkActionsEnabled,
      dropIndicator,
      fields,
      firstSummaryIndex,
      group,
      index,
      isCollapsed,
      layout,
      onDrop,
      selectedRows,
      setSelectedRows,
      toggleGroupCollapsedState,
    },
    ref,
  ) => {
    const { isOver, dropRef } = useDroppableGroup({
      elementRef: ref,
      group,
      onDrop,
    });

    const isTable = useIsTable(layout);

    return (
      <>
        <GroupHeader
          bulkActionsEnabled={bulkActionsEnabled}
          group={group}
          isTable={isTable}
          isCollapsed={isCollapsed}
          fields={fields}
          firstSummaryIndex={firstSummaryIndex}
          layout={layout}
          dataTestid="collection-group-header"
          data-group-key={group.key}
          toggleGroupCollapsedState={toggleGroupCollapsedState}
          data-index={index}
          ref={(element: HTMLTableRowElement) => dropRef(element)}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
        {isOver && dropIndicator}
      </>
    );
  },
);

export default DroppableGroupHeader;
