import {
  BUSINESS,
  BUSINESS_V2,
  ENTERPRISE,
  EXPERT,
  FREE,
  PRO,
  PRO_V2,
  SCALE,
  STARTER_V2,
  TEAM,
} from './accountPlans';
import { FILE, ROLE, USER } from './builtInDataTypes';
import { IS_PRODUCTION } from './env';
import {
  ACTIVE_USERS,
  ACTIVE_WORKFLOWS,
  BUILDERS,
  FILE_STORAGE,
  Feature,
  LIVE_APPS,
  TEAM_MEMBERS,
  WORKFLOW_RUNS,
} from './features';

export const USAGE_FEATURES_WITH_UNITS = [FILE_STORAGE];

export const DATATYPES_EXCLUDED_FROM_RECORD_USAGE: string[] = [
  FILE,
  ROLE,
  USER,
];

// Usage Category
export type UsageCategory = 'plan' | 'project';
export const PLAN: UsageCategory = 'plan';
export const PROJECT: UsageCategory = 'project';

export interface AdditionalUsageValue {
  increment: number;
  cost: number;
  stripePriceId: string;
}

export type PlanOverages = {
  [_feature in Feature]?: AdditionalUsageValue;
};

const PAID_PLAN_OVERAGES: PlanOverages = {
  // Data
  [FILE_STORAGE]: {
    increment: 1,
    cost: 15,
    stripePriceId: IS_PRODUCTION
      ? 'price_1Lc9txFuSJNgcRAS29S0F2g8'
      : 'price_1LcA5EFuSJNgcRASwxzUtKK9',
  },

  // Workflows
  [WORKFLOW_RUNS]: {
    increment: 1000,
    cost: 50,
    stripePriceId: IS_PRODUCTION
      ? 'price_1Lc9wUFuSJNgcRAStVWfC1tx'
      : 'price_1LcA6aFuSJNgcRASYPYQJ3Yy',
  },
};

const PAID_PLAN_OVERAGES_V2: PlanOverages = {
  ...PAID_PLAN_OVERAGES,
  [FILE_STORAGE]: {
    increment: 10,
    cost: 15,
    stripePriceId: IS_PRODUCTION
      ? 'price_1PgKprFuSJNgcRASltmECtOS'
      : 'price_1PgKrSFuSJNgcRASR6kitVi0',
  },
};

const USAGE_PLAN_OVERAGES: PlanOverages = {
  ...PAID_PLAN_OVERAGES,

  // Users
  [BUILDERS]: {
    increment: 1,
    cost: 50,
    stripePriceId: IS_PRODUCTION
      ? 'price_1Lc9dQFuSJNgcRASJH1hkmbV'
      : 'price_1LcA19FuSJNgcRASYdXF9kHM',
  },
  [TEAM_MEMBERS]: {
    increment: 1,
    cost: 20,
    stripePriceId: IS_PRODUCTION
      ? 'price_1Lc9gNFuSJNgcRASWTvE7rqM'
      : 'price_1LcA3yFuSJNgcRASTovbVZ6v',
  },
  [ACTIVE_USERS]: {
    increment: 50,
    cost: 75,
    stripePriceId: IS_PRODUCTION
      ? 'price_1Lc9qWFuSJNgcRASB6X5Pg63'
      : 'price_1LcA4aFuSJNgcRASs1riAsjc',
  },

  // Workflows
  [ACTIVE_WORKFLOWS]: {
    increment: 10,
    cost: 20,
    stripePriceId: IS_PRODUCTION
      ? 'price_1Lc9yKFuSJNgcRASaiS3G7E7'
      : 'price_1LcA7PFuSJNgcRAS44VgV6ri',
  },
};

const NO_OVERAGES: PlanOverages = {};

export const ADDITIONAL_USAGE_CONFIG = {
  // Free
  [FREE]: NO_OVERAGES,

  // Usage-limited plans
  [TEAM]: {
    ...USAGE_PLAN_OVERAGES,
    [LIVE_APPS]: {
      increment: 1,
      cost: 50,
      stripePriceId: IS_PRODUCTION
        ? 'price_1Lc9ZmFuSJNgcRASobG3FspE'
        : 'price_1LcA1oFuSJNgcRASKPmA1HKw',
    },
  },
  [SCALE]: {
    ...USAGE_PLAN_OVERAGES,
    [LIVE_APPS]: {
      increment: 1,
      cost: 75,
      stripePriceId: IS_PRODUCTION
        ? 'price_1Lc9ZmFuSJNgcRASxSkWWFn2'
        : 'price_1LcA1pFuSJNgcRASGRYC7zr3',
    },
  },

  // Per-seat plans
  [PRO]: PAID_PLAN_OVERAGES,
  [BUSINESS]: PAID_PLAN_OVERAGES,

  // Flat Plan January 2024
  [STARTER_V2]: PAID_PLAN_OVERAGES_V2,
  [PRO_V2]: PAID_PLAN_OVERAGES_V2,
  [BUSINESS_V2]: PAID_PLAN_OVERAGES_V2,

  // Custom plans
  [ENTERPRISE]: NO_OVERAGES,
  [EXPERT]: NO_OVERAGES,
};

export const EXTERNAL_USERS_PRICE = {
  [PRO]: {
    addon: IS_PRODUCTION
      ? 'price_1NexcXFuSJNgcRAS5npxav3F'
      : 'price_1NdumpFuSJNgcRAS3Zyr5Gt6',
    extra: IS_PRODUCTION
      ? 'price_1NexcoFuSJNgcRASWJZupw8q'
      : 'price_1NdunDFuSJNgcRAS0oDn6n9O',
    stripePriceId: IS_PRODUCTION
      ? 'price_1N5VpMFuSJNgcRASgpA2aJTG'
      : 'price_1N5UYUFuSJNgcRASlIqDO3rL',
  },
  [BUSINESS]: {
    addon: IS_PRODUCTION
      ? 'price_1NexbpFuSJNgcRASuCmjJ6j1'
      : 'price_1NdulQFuSJNgcRASdItD71ih',
    extra: IS_PRODUCTION
      ? 'price_1NexcAFuSJNgcRASZNhXubtd'
      : 'price_1NdumJFuSJNgcRAS3PXXtabE',
    stripePriceId: IS_PRODUCTION
      ? 'price_1N5VpMFuSJNgcRASMfub1gu2'
      : 'price_1N5UZzFuSJNgcRAS9fsmI54x',
  },
};
