import React, { Suspense, lazy, useCallback, useMemo } from 'react';
import {
  IconCreditCard,
  IconCreditCardOff,
  IconShieldLock,
  IconShieldOff,
  IconUserCheck,
  IconUserOff,
  IconUsers,
} from '@tabler/icons-react';
import gql from 'graphql-tag';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import {
  BlockRadioGroupOption,
  Loader,
  RadioGroup,
  SwitchButton,
  Tooltip,
} from '@noloco/components';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeSection from '@noloco/core/src/components/buildMode/BuildModeSection';
import { isPageFullScreenIframe } from '@noloco/core/src/components/buildMode/LeftBuildModePageEditor';
import {
  ALL,
  LOGGED_IN,
  NOT_LOGGED_IN,
} from '@noloco/core/src/constants/authVisibilityRules';
import { CUSTOM_RULES } from '@noloco/core/src/constants/buildMode';
import { DEFAULT_ROLES } from '@noloco/core/src/constants/defaultRoleReferences';
import {
  CUSTOM_VISIBILITY_RULES,
  USER_ROLES,
} from '@noloco/core/src/constants/features';
import internalUsersVisibilityRules, {
  CLIENT,
  INTERNAL,
} from '@noloco/core/src/constants/internalUsersVisibilityRules';
import membershipVisibilityRules, {
  WITHOUT_PLANS,
  WITH_PLANS,
} from '@noloco/core/src/constants/membershipVisibilityRules';
import roleVisibilityRules from '@noloco/core/src/constants/roleVisibilityRules';
import { getCollectionDataQueryString } from '@noloco/core/src/queries/project';
import { getValidPlanLangKey } from '@noloco/core/src/utils/features';
import useCacheQuery from '@noloco/core/src/utils/hooks/useCacheQuery';
import { getText } from '@noloco/core/src/utils/lang';
import { isConnected } from '@noloco/core/src/utils/payments';
import { useUpdateVisibilityRules } from '../../utils/hooks/projectHooks';
import { useFeatureLimit } from '../../utils/hooks/useFeatureLimit';
import useIsFeatureEnabled from '../../utils/hooks/useIsFeatureEnabled';
import useIsTrialing from '../../utils/hooks/useIsTrialing';
import useValidFeaturePlan from '../../utils/hooks/useValidFeaturePlan';
import { getPlanDescription } from '../../utils/memberships';
import Guide from '../Guide';
import ProFeatureBadge from '../ProFeatureBadge';

const icons = {
  authentication: {
    [ALL]: IconUsers,
    [LOGGED_IN]: IconUserCheck,
    [NOT_LOGGED_IN]: IconUserOff,
  },
  type: {
    [ALL]: IconUsers,
    [INTERNAL]: IconShieldLock,
    [CLIENT]: IconShieldOff,
  },
  memberships: {
    [ALL]: IconUsers,
    [WITH_PLANS]: IconCreditCard,
    [WITHOUT_PLANS]: IconCreditCardOff,
  },
};

const LazyCustomVisibilityRulesEditor = lazy(
  () => import('./CustomVisibilityRulesEditor'),
);

const BaseVisibilityOption = ({
  description,
  label,
  active,
  checked,
  Icon,
  onClick,
  showBadge = false,
  locked = false,
  disabled = locked && !checked,
}: any) => {
  const badgePlan = useValidFeaturePlan(USER_ROLES);

  return (
    <Tooltip
      disabled={!locked}
      content={
        <>
          <span className="flex">
            {getText('rightSidebar.visibility.roles.disabled')}
          </span>
          {checked && (
            <span>
              {getText('rightSidebar.visibility.roles.disabledWithChecked')}
            </span>
          )}
        </>
      }
      bg="white"
    >
      {/* @ts-expect-error TS(2322): Type '{ children: any; active: any; badgeText: str... Remove this comment to see the full error message */}
      <BlockRadioGroupOption
        active={active}
        badgeText={getText(
          'billing.plans',
          getValidPlanLangKey(badgePlan),
          'name',
        )}
        checked={checked}
        description={description}
        disabled={disabled}
        Icon={Icon}
        onClick={onClick}
        showBadge={showBadge}
      >
        {label}
      </BlockRadioGroupOption>
    </Tooltip>
  );
};

const VisibilityRuleRadioOption =
  (prefix: any, Icon: any) =>
  ({ value, checked, active, description }: any) => (
    <BaseVisibilityOption
      Icon={Icon}
      label={getText('rightSidebar.visibility', prefix, value)}
      active={active}
      checked={checked}
      description={description}
    />
  );

const VisibilityRulesEditor = ({
  dataType,
  element,
  elementPath,
  hideGuide = false,
  onChange,
  project,
  section,
  sectionPropPath = [],
}: any) => {
  const isElementIframe = useMemo(
    () => isPageFullScreenIframe(element),
    [element],
  );
  const collectionElementPath = useMemo(
    () => (section && !isElementIframe ? sectionPropPath : elementPath),
    [section, isElementIframe, sectionPropPath, elementPath],
  );
  const [updateVisibilityRules] = useUpdateVisibilityRules(
    collectionElementPath,
    project,
  );

  const onUpdateVisibilityRules = useCallback(
    (path, value) => {
      if (onChange) {
        onChange(path, value);
      } else {
        updateVisibilityRules(path, value);
      }
    },
    [onChange, updateVisibilityRules],
  );

  const {
    auth,
    type,
    membership,
    roleRule,
    plans = [],
    roles = [],
    customRules = [],
  } = get(section && !isElementIframe ? section : element, 'visibilityRules') ||
  {};

  const customRulesEnabled = useIsFeatureEnabled(CUSTOM_VISIBILITY_RULES);
  const isTrialing = useIsTrialing();

  const roleLimit = useFeatureLimit(USER_ROLES);
  const roleExceedsLimit = useCallback(
    (index: number) => {
      if (isNil(roleLimit.limit)) {
        return false;
      }

      return index >= roleLimit.limit;
    },
    [roleLimit.limit],
  );

  const { stripe } = project.integrations;
  const membershipsEnabled = isConnected(stripe);
  const rolesQueryString = getCollectionDataQueryString('role', {
    edges: {
      node: {
        id: true,
        name: true,
        referenceId: true,
      },
    },
  });
  const { data: rolesData } = useCacheQuery(
    gql`
      ${rolesQueryString}
    `,
    {
      context: {
        projectQuery: true,
        projectName: project.name,
      },
    },
  );
  const userRoles = get(rolesData, 'roleCollection.edges', [])
    .map((edge: any) => ({
      ...edge.node,
      isDefault: DEFAULT_ROLES.includes(edge.node.referenceId),
    }))
    .sort((a: any, b: any) => a.id - b.id);

  const togglePlan = useCallback(
    (planId) => {
      if (plans.includes(planId)) {
        onUpdateVisibilityRules(
          ['plans'],
          plans.filter((id: any) => id !== planId),
        );
      } else {
        onUpdateVisibilityRules(['plans'], [...plans, planId]);
      }
    },
    [plans, onUpdateVisibilityRules],
  );

  const toggleRole = useCallback(
    (roleRefId) => {
      if (roles.includes(roleRefId)) {
        onUpdateVisibilityRules(
          ['roles'],
          roles.filter((id: any) => id !== roleRefId),
        );
      } else {
        onUpdateVisibilityRules(['roles'], [...roles, roleRefId]);
      }
    },
    [roles, onUpdateVisibilityRules],
  );

  return (
    <>
      <div className="space-y-2 p-2">
        {!hideGuide && (
          <Guide
            className="mb-4 text-sm"
            href="https://guides.noloco.io/record-pages/visibility-settings"
          >
            {getText('rightSidebar.visibility.description')}
          </Guide>
        )}
        <BuildModeInput
          inline={true}
          label={getText('rightSidebar.visibility.type.label')}
        >
          <SwitchButton
            className="h-8 w-full rounded-lg"
            onChange={(newValue) => onUpdateVisibilityRules(['type'], newValue)}
            options={internalUsersVisibilityRules.map((authRule) => ({
              value: authRule,
              label: getText('rightSidebar.visibility.type.title', authRule),
            }))}
            value={type || ALL}
          />
        </BuildModeInput>
        <p className="text-xs text-gray-400">
          {getText('rightSidebar.visibility.type.visibleTo')}
          <span className="lowercase">
            {getText('rightSidebar.visibility.type.description', type || ALL)}
          </span>
        </p>
      </div>
      <hr className="my-2 border-slate-700" />
      <div className="p-2">
        <BuildModeInput
          inline={true}
          label={getText('rightSidebar.visibility.roles.label')}
        >
          <SwitchButton
            className="h-8 w-full rounded-lg"
            onChange={(newValue) =>
              onUpdateVisibilityRules(['roleRule'], newValue)
            }
            options={roleVisibilityRules.map((roleVisibilityRule) => ({
              value: roleVisibilityRule,
              label: getText(
                'rightSidebar.visibility.roles.compactTitle',
                roleVisibilityRule,
              ),
            }))}
            value={roleRule || ALL}
          />
        </BuildModeInput>
        <p className="my-2 text-xs text-gray-400">
          {getText('rightSidebar.visibility.type.visibleTo')}
          <span className="lowercase">
            {getText('rightSidebar.visibility.roles', roleRule || ALL)}
          </span>
        </p>
        {roleRule && roleRule !== ALL && (
          <div className="space-y-4">
            <div className="flex flex-col">
              {userRoles.map((role: any, index: number) => (
                <BaseVisibilityOption
                  active={false}
                  checked={roles.includes(role.referenceId)}
                  key={role.referenceId}
                  label={role.name}
                  locked={
                    !role.isDefault && roleExceedsLimit(index) && !isTrialing
                  }
                  onClick={() => toggleRole(role.referenceId)}
                  showBadge={
                    !role.isDefault && (roleExceedsLimit(index) || isTrialing)
                  }
                />
              ))}
            </div>
          </div>
        )}
        {auth === LOGGED_IN && membershipsEnabled && (
          <>
            <hr className="my-2 border-slate-700" />
            <RadioGroup
              label={
                <span className="mb-3 block text-xs font-medium uppercase tracking-wider text-gray-300">
                  {getText('rightSidebar.visibility.memberships.label')}
                </span>
              }
              onChange={(newValue: any) =>
                onUpdateVisibilityRules(['membership'], newValue)
              }
              options={membershipVisibilityRules.map((membershipRule) => ({
                value: membershipRule,
                label: VisibilityRuleRadioOption(
                  'memberships',
                  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  icons.memberships[membershipRule],
                ),
              }))}
              value={membership || ALL}
            />
            {membership && membership !== ALL && (
              <div className="flex flex-col pl-4">
                <label className="mb-1 mt-2 block text-xs font-medium uppercase tracking-wider text-gray-300">
                  {getText('rightSidebar.visibility.memberships', membership)}
                </label>
                {stripe.plans.map((plan: any) => (
                  <BaseVisibilityOption
                    key={plan.id}
                    active={false}
                    checked={plans.includes(plan.id)}
                    label={plan.name}
                    onClick={() => togglePlan(plan.id)}
                    description={getPlanDescription(plan)}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <BuildModeSection
        id={CUSTOM_RULES}
        className="border-b border-t"
        title={getText('rightSidebar.visibility.custom.label')}
        endComponent={
          <div>
            <ProFeatureBadge
              feature={CUSTOM_VISIBILITY_RULES}
              inline={true}
              showAfterTrial={!customRulesEnabled}
            />
          </div>
        }
      >
        <div className="p-2">
          {customRulesEnabled && (
            <Suspense fallback={<Loader />}>
              <LazyCustomVisibilityRulesEditor
                customRules={customRules}
                dataType={dataType}
                elementPath={collectionElementPath}
                onUpdateVisibilityRules={onUpdateVisibilityRules}
                project={project}
              />
            </Suspense>
          )}
        </div>
      </BuildModeSection>
    </>
  );
};

export default VisibilityRulesEditor;
