import { useCallback, useMemo } from 'react';
import { omit } from 'lodash/fp';
import { CREATE, UPDATE } from '../../constants/actionTypes';
import { DataType } from '../../models/DataTypes';
import { Action, ScanActionType } from '../../models/Element';
import { Project } from '../../models/Project';
import { getFieldFromDependency } from '../fields';
import getActionButtonFormFieldConfig from '../getActionButtonFormFieldConfig';
import useActionWithHiddenInputsMutation from './useActionWithHiddenInputsMutation';
import useActionsWithHiddenInputs from './useActionsWithHiddenInputs';
import useAutoFormVariables from './useAutoFormVariables';

const useOneClickActionMutations = (
  action: Action,
  dataType: DataType,
  project: Project,
  record: any,
  onNext: () => void,
  setIsLoading?: (isLoading: boolean) => void,
) => {
  const createMutation = useActionWithHiddenInputsMutation(
    action,
    dataType,
    CREATE,
    project,
    record,
  );
  const updateMutation = useActionWithHiddenInputsMutation(
    action,
    dataType,
    UPDATE,
    project,
    record,
  );

  const { onCreate, onUpdate } = useActionsWithHiddenInputs(
    createMutation,
    updateMutation,
    onNext,
  );

  const { actionDataType } = useMemo(
    () =>
      getActionButtonFormFieldConfig(
        action.field!,
        dataType,
        project.dataTypes,
      ),
    [dataType, action, project.dataTypes],
  );

  const dep = getFieldFromDependency(
    action.barcodeField?.path.split('.') as string[],
    actionDataType,
    project.dataTypes,
  )!;

  // @ts-expect-error TS(2554): Expected 8-11 arguments, but got 4.
  const { getQueryVariables } = useAutoFormVariables(
    project,
    actionDataType,
    [{ config: { name: dep?.field.name }, field: dep?.field }],
    action.scanActionType === ScanActionType.CREATE ? CREATE : UPDATE,
  );

  const onScan = useCallback(
    async (barcodeValue) => {
      const { variables } = getQueryVariables(
        { [dep.field.name]: barcodeValue },
        null,
        dep.field,
      );

      const onAction =
        action.scanActionType === ScanActionType.CREATE ? onCreate : onUpdate;

      return await onAction(action, setIsLoading, omit(['id'], variables));
    },
    [action, dep, getQueryVariables, setIsLoading, onCreate, onUpdate],
  );

  return { onCreate, onUpdate, onScan };
};

export default useOneClickActionMutations;
