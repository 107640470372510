import React, { Suspense, lazy, useCallback } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import { IconPaperclip, IconSend, IconX } from '@tabler/icons-react';
import classNames from 'classnames';
import { Loader, getColorShade } from '@noloco/components';
import useRefCallback from '@noloco/components/src/utils/hooks/useRefCallback';
import { ACCEPTED_MIMETYPES } from '../constants/mimetypes';
import { ENTER } from '../constants/shortcuts';
import AttachmentIcon from '../elements/sections/messaging/AttachmentIcon';
import useIsMacOs from '../utils/hooks/useIsMacOs';
import useReadFileInputAttachments from '../utils/hooks/useReadFileInputAttachments';

const LazyRichTextEditor = lazy(
  () => import('./richTextEditor/RichTextEditor'),
);

const MessageInput = ({
  allowAttachments = true,
  className,
  dataTypes,
  isLoading,
  files,
  mentions,
  onChange,
  onChangeFiles,
  onSubmit,
  placeholder,
  projectName,
  richTextControls,
  shouldFocus,
  theme,
  value,
}: any) => {
  const secondaryColor = theme.brandColorGroups.secondary;
  const isMacOs = useIsMacOs();
  const handleEnter = useRefCallback(
    (event: KeyboardEvent) => {
      if (
        event.code === ENTER &&
        ((!isMacOs && event.ctrlKey) || (isMacOs && event.metaKey))
      ) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        onSubmit(event);
        return false;
      }
    },
    [onSubmit],
  );

  const removeFile = useCallback(
    (file: any) => {
      // @ts-expect-error TS(7031): Binding element 'existingFile' implicitly has an '... Remove this comment to see the full error message
      onChangeFiles(files.filter(([existingFile]) => file !== existingFile));
    },
    [files, onChangeFiles],
  );

  const readFileInputAttachments = useReadFileInputAttachments();

  const onSelectFiles = useCallback(
    (newFiles: FileList) => {
      readFileInputAttachments(newFiles).then((resolvedFiles) => {
        onChangeFiles([...files, ...resolvedFiles]);
      });
    },
    [files, onChangeFiles, readFileInputAttachments],
  );

  const handlePaste = useRefCallback(
    (event: ClipboardEvent): void => {
      // Stop data actually being pasted into div
      event.stopPropagation();
      event.preventDefault();

      const files: FileList | undefined = event.clipboardData?.files;
      if (allowAttachments && files && files.length > 0) {
        onSelectFiles(files);
      }
    },
    [onSelectFiles, allowAttachments],
  );

  return (
    <div className={classNames('flex w-full flex-col items-end', className)}>
      <div className="relative flex w-full items-end text-sm">
        <Suspense fallback={<Loader />}>
          <LazyRichTextEditor
            className="max-h-screen-50 px-3 py-2"
            dataTypes={dataTypes}
            mentions={mentions}
            onChange={onChange}
            onEnter={handleEnter}
            onPaste={handlePaste}
            placeholder={placeholder}
            projectName={projectName}
            richTextControls={richTextControls}
            shouldFocus={shouldFocus}
            value={value}
          >
            <div className="flex items-end">
              {allowAttachments && (
                <label
                  htmlFor="file-attachment"
                  className={classNames(
                    'ml-2 rounded-lg p-2 hover:bg-gray-100',
                    `text-${getColorShade(secondaryColor, '600')}`,
                  )}
                >
                  <IconPaperclip size={16} />
                  <input
                    accept={ACCEPTED_MIMETYPES.join(',')}
                    onChange={({ target: { files: inputFiles } }) => {
                      if (inputFiles !== null) {
                        onSelectFiles(inputFiles);
                      }
                    }}
                    type="file"
                    multiple={true}
                    id="file-attachment"
                    className="hidden"
                  />
                </label>
              )}
              <button
                onClick={onSubmit}
                disabled={isLoading}
                className={classNames(
                  'ml-2 rounded-lg p-2 text-white',
                  `bg-${getColorShade(secondaryColor, '500')}`,
                  `hover:bg-${getColorShade(secondaryColor, '600')}`,
                )}
              >
                {!isLoading ? <IconSend size={16} /> : <Loader size="xs" />}
              </button>
            </div>
          </LazyRichTextEditor>
        </Suspense>
      </div>
      {files.length > 0 && (
        <div className="flex w-full flex-wrap items-center px-2 pb-2">
          {/* @ts-expect-error TS(7031): Binding element 'file' implicitly has an 'any' typ... Remove this comment to see the full error message */}
          {files.map(([file, __, preview]) => (
            <div
              key={preview}
              className="group relative m-1 w-36 rounded-lg bg-gray-100 p-2"
            >
              <div className="truncate">
                <AttachmentIcon
                  mimetype={file.type}
                  size={18}
                  className="mr-2 inline-block opacity-50"
                />
                <span className="text-xs text-gray-700">{file.name}</span>
              </div>
              <div
                onClick={() => removeFile(file)}
                className="absolute right-0 top-0 -mr-2 -mt-2 hidden h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-gray-400 text-sm text-white group-hover:flex"
              >
                <IconX size={8} />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

MessageInput.defaultProps = {
  shouldFocus: true,
};

export default withTheme(MessageInput);
