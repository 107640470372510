import classNames from 'classnames';
import { darkModeColors } from '../../../constants/darkModeColors';
import { RecordEdge } from '../../../models/Record';
import { CollectionField } from '../../../models/View';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { FieldConfig } from '../../../utils/permissions';
import CollectionColumnSummaryCell from './CollectionColumnSummaryCell';

type TableSummaryFooterProps = {
  bulkActionsEnabled?: boolean;
  className: string;
  edges?: RecordEdge[];
  fieldConfigs?: FieldConfig<CollectionField>[];
  summaryRow?: (string | number)[];
};

const TableSummaryFooter = ({
  bulkActionsEnabled = false,
  className,
  edges = [],
  fieldConfigs = [],
  summaryRow,
}: TableSummaryFooterProps) => {
  const [isDarkModeEnabled] = useDarkMode();

  return (
    <tr
      className={classNames(
        className,
        'shadow-xs sticky z-20 border-b text-left text-xs font-medium uppercase tracking-wider',
        `${
          isDarkModeEnabled
            ? `${darkModeColors.surfaces.elevation0} ${darkModeColors.text.primary} ${darkModeColors.borders.one} ${darkModeColors.divides.one}`
            : 'shadow-t border-gray-200 bg-gray-50 text-gray-800'
        }`,
      )}
    >
      {summaryRow &&
        summaryRow.map((row, index) => {
          const isFirst = index === 0;
          const isLast = index === summaryRow.length - 1;

          return (
            <td
              className={classNames({
                'pl-6 pr-3': isFirst,
                'pr-6': isLast,
                'px-3': !isLast && !isFirst,
                'text-right': !isFirst,
              })}
              key={row}
            >
              {row}
            </td>
          );
        })}
      {bulkActionsEnabled && <td className="w-9"></td>}
      {fieldConfigs.map(({ field, parent, config }: any) => (
        <CollectionColumnSummaryCell
          className="py-1"
          config={config}
          key={field.id}
          field={field}
          parent={parent}
          rows={edges}
        />
      ))}
    </tr>
  );
};

export default TableSummaryFooter;
