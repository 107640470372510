import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';
import { SelectInput, Switch, SwitchButton } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { LG, MD, SM, XL } from '@noloco/components/src/constants/tShirtSizes';
import { PRIMARY } from '@noloco/components/src/constants/variants';
import BuildModeEditorTabs from '@noloco/core/src/components/buildMode/BuildModeEditorTabs';
import BuildModeImageEditor from '@noloco/core/src/components/buildMode/BuildModeImageEditor';
import BuildModeInput from '@noloco/core/src/components/buildMode/BuildModeInput';
import BuildModeLabel from '@noloco/core/src/components/buildMode/BuildModeLabel';
import BuildModeSwitchSection from '@noloco/core/src/components/buildMode/BuildModeSwitchSection';
import BuildModeActionButtonsEditor from '@noloco/core/src/components/buildMode/actionButton/BuildModeActionButtonsEditor';
import BuildModeViewChartsTab from '@noloco/core/src/components/buildMode/view/BuildModeViewChartsTab';
import BuildModeViewFieldsTab from '@noloco/core/src/components/buildMode/view/BuildModeViewFieldsTab';
import BuildModeViewGeneralTab from '@noloco/core/src/components/buildMode/view/BuildModeViewGeneralTab';
import BuildModeViewOptionsTab from '@noloco/core/src/components/buildMode/view/BuildModeViewOptionsTab';
import BuildModeViewVisibilityTab from '@noloco/core/src/components/buildMode/view/BuildModeViewVisibilityTab';
import IconEditor from '@noloco/core/src/components/editor/IconEditor';
import { CENTER, END, START } from '@noloco/core/src/constants/align';
import {
  CHARTS_TAB,
  EMPTY_COLLECTION,
  FIELDS,
  GENERAL,
  OPTIONS,
  VISIBILITY,
} from '@noloco/core/src/constants/buildMode';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { CHARTS } from '@noloco/core/src/constants/collectionLayouts';
import { TEXT } from '@noloco/core/src/constants/dataTypes';
import {
  ACTION_BUTTONS,
  COLLECTION,
  COMMENTS,
  CONTAINER,
  DETAILS,
  DIVIDER,
  EMBED,
  FILE_GALLERY,
  HIGHLIGHTS,
  IFRAME,
  IMAGE,
  MARKDOWN,
  NOTICE,
  PAGE,
  QUICK_LINKS,
  SECTION,
  STAGES,
  TITLE,
  VIDEO,
  VIEW,
} from '@noloco/core/src/constants/elements';
import { DATABASE } from '@noloco/core/src/constants/scopeTypes';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Element, ElementPath } from '@noloco/core/src/models/Element';
import { Project } from '@noloco/core/src/models/Project';
import cappedMemoize from '@noloco/core/src/utils/cappedMemoize';
import { canBeStages } from '@noloco/core/src/utils/fields';
import useEditorTabs from '@noloco/core/src/utils/hooks/useEditorTabs';
import { getText } from '@noloco/core/src/utils/lang';
import { getCollectionOptionsOfTypeFromParent } from '@noloco/core/src/utils/renderedOptions';
import StringPropEditor from '../../../components/canvas/StringPropEditor';
import { UpdatePropertyCallback } from '../../../utils/hooks/projectHooks';
import DataFieldIcon from '../../DataFieldIcon';
import VisibilityRulesEditor from '../VisibilityRulesEditor';
import ActionButtonAppearanceEditor from './ActionButtonAppearanceEditor';
import ActionButtonsEditorBody from './ActionButtonsEditorBody';
import { getCachedConditionFieldOptions } from './FieldConditionsEditor';
import IframeSectionEditor from './IframeEditor';
import OptionFieldEditor from './OptionFieldEditor';
import QuickLinksEditor from './QuickLinksEditor';
import SectionFieldsListEditor from './SectionFieldsListEditor';
import SectionRootFieldEditor from './SectionRootFieldEditor';

const BUTTON_ALIGNMENT = [START, CENTER, END];
const EMPTY_PROP_PATH: string[] = [];

export const getSectionPropPath = cappedMemoize(
  (elementPath, propPath, selectedSectionPath) => [
    ...elementPath,
    'props',
    ...propPath,
    'sections',
    ...selectedSectionPath,
  ],
  { maxKeys: 100 },
);

type FileGallerySectionEditorProps = {
  dataType: DataType;
  section: Element;
  updateProperty: UpdatePropertyCallback;
};

const FileGallerySectionEditor = ({
  dataType,
  section,
  updateProperty,
}: FileGallerySectionEditorProps) => {
  const { field, size, showFileName } = section.props || {};
  const sizeOptions = [SM, MD, LG, XL];

  const fieldOptions = useMemo(
    () =>
      dataType.fields
        .filter((dtField: any) => dtField.type === FILE)
        .map((dtField: any) => ({
          value: dtField.name,

          label: (
            <div className="flex items-center">
              <DataFieldIcon
                field={dtField}
                className="mr-2 opacity-75"
                size={18}
              />
              {dtField.display}
            </div>
          ),
        })),
    [dataType.fields],
  );

  return (
    <div className="space-y-2 p-2">
      <BuildModeInput label={getText('elements', FILE_GALLERY, 'fields.label')}>
        <SelectInput
          value={field}
          onChange={(value: any) => updateProperty(['field'], value)}
          options={fieldOptions}
          placeholder={getText('elements', FILE_GALLERY, 'fields.placeholder')}
        />
      </BuildModeInput>
      <BuildModeInput label={getText('elements', FILE_GALLERY, 'size.label')}>
        <SwitchButton
          className="h-8 w-full rounded-lg"
          inverseColors={true}
          onChange={(value) => updateProperty(['size'], value)}
          options={sizeOptions.map((sizeOption) => ({
            label: getText('elements.SELECT_INPUT.smallSizes', sizeOption),
            value: sizeOption,
          }))}
          value={size || MD}
        />
      </BuildModeInput>
      <div className="flex items-center justify-between">
        <BuildModeLabel>
          {getText('elements', FILE_GALLERY, 'showFileName.label')}
        </BuildModeLabel>
        <Switch
          size={SM}
          onChange={(enabled: boolean) =>
            updateProperty(['showFileName'], enabled)
          }
          value={showFileName}
        />
      </div>
    </div>
  );
};

type VideoSectionEditorProps = {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
  updateProperty: UpdatePropertyCallback;
};

const VideoSectionEditor = ({
  dataType,
  debouncedUpdateProperty,
  project,
  section,
  sectionPropPath,
  updateProperty,
}: VideoSectionEditorProps) => {
  const { url, field, type } = section.props || {};

  const typeOptions = useMemo(
    () =>
      ['field', 'url'].map((typeOption) => ({
        value: typeOption,
        label: getText('elements', VIDEO, 'options', typeOption),
      })),
    [],
  );

  const fieldOptions = useMemo(
    () =>
      dataType.fields
        .filter((field: any) => field.type === FILE || field.type === TEXT)
        .map((field: any) => ({
          value: field.name,
          label: (
            <div className="flex items-center">
              <DataFieldIcon
                field={field}
                className="mr-2 opacity-75"
                size={18}
              />
              {field.display}
            </div>
          ),
        })),
    [dataType.fields],
  );

  return (
    <div className="flex w-full flex-col space-y-2 p-2">
      <p className="text-slate-400">{getText('elements', VIDEO, 'help')}</p>
      <BuildModeInput label={getText('elements', VIDEO, 'options.label')}>
        <SelectInput
          value={type}
          onChange={(value: any) => updateProperty(['type'], value)}
          options={typeOptions}
          placeholder={getText('elements', VIDEO, 'options.placeholder')}
        />
      </BuildModeInput>
      {type === 'field' && (
        <BuildModeInput label={getText('elements', VIDEO, 'fields.label')}>
          <SelectInput
            value={field}
            onChange={(value: any) => updateProperty(['field'], value)}
            options={fieldOptions}
            placeholder={getText('elements', VIDEO, 'fields.placeholder')}
          />
        </BuildModeInput>
      )}
      {type === 'url' && (
        <BuildModeInput label={getText('elements', VIDEO, 'url.label')}>
          <StringPropEditor
            // @ts-expect-error TS(2322): Type '{ elementPath: any; project: any; onChange: ... Remove this comment to see the full error message
            elementPath={sectionPropPath}
            project={project}
            onChange={(option: any) => debouncedUpdateProperty(['url'], option)}
            value={url}
          />
        </BuildModeInput>
      )}
    </div>
  );
};

type CommentSectionEditorProps = {
  dataType: DataType;
  project: Project;
  section: Element;
  updateProperty: UpdatePropertyCallback;
};

const CommentSectionEditor = ({
  dataType,
  project,
  section,
  updateProperty,
}: CommentSectionEditorProps) => {
  const { rootField } = section.props || {};

  const onRootFieldChange = useCallback(
    (rootFieldName: string | null) =>
      updateProperty(['rootField'], rootFieldName),
    [updateProperty],
  );

  return (
    <>
      <BuildModeInput
        className="p-2"
        label={getText('elements.VIEW.fields.parent.title')}
      >
        <SectionRootFieldEditor
          value={rootField}
          dataType={dataType}
          onChange={onRootFieldChange}
          project={project}
        />
      </BuildModeInput>
      <BuildModeSwitchSection
        className="p-2"
        label={getText('elements.VIEW.comments.allowAttachments')}
        onChange={(value: boolean) =>
          updateProperty(['allowAttachments'], value)
        }
        value={section.props.allowAttachments ?? true}
      />
    </>
  );
};

type NoticeSectionEditorProps = {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
  updateProperty: UpdatePropertyCallback;
};

const NoticeSectionEditor = ({
  dataType,
  debouncedUpdateProperty,
  project,
  section,
  sectionPropPath,
  updateProperty,
}: NoticeSectionEditorProps) => {
  const { appearance = PRIMARY, icon } = section.props || {};

  return (
    <>
      <div className="flex flex-col space-y-2 p-2">
        <BuildModeInput label={getText('elements', NOTICE, 'icon')}>
          <IconEditor
            clearable={true}
            elementProps={icon}
            inline={false}
            placement="right"
            surface={DARK}
            updateProperty={(_: ElementPath, iconName: string | null) =>
              updateProperty(['icon', 'name'], iconName)
            }
          />
        </BuildModeInput>
        <BuildModeInput label={getText('elements', NOTICE, 'appearance')}>
          <ActionButtonAppearanceEditor
            appearance={appearance}
            onChange={(value: any) => updateProperty(['appearance'], value)}
          />
        </BuildModeInput>
      </div>
      <TitleSectionEditor
        dataType={dataType}
        section={section}
        project={project}
        updateProperty={updateProperty}
        debouncedUpdateProperty={debouncedUpdateProperty}
        sectionPropPath={sectionPropPath}
      />
    </>
  );
};

type StagesSectionEditorProps = {
  dataType: DataType;
  element: Element;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
  updateProperty: UpdatePropertyCallback;
};

const StagesSectionEditor = ({
  dataType,
  element,
  project,
  section,
  sectionPropPath,
  updateProperty,
}: StagesSectionEditorProps) => {
  const { optionsConfig, stages, useColors, disableEditing } =
    section.props || {};

  const singleOptionFields = useMemo(
    () => dataType.fields.filter(canBeStages),
    [dataType.fields],
  );

  const selectedField = useMemo(
    () =>
      singleOptionFields.find(
        (field: any) => field.name === get(stages, 'path'),
      ),
    [singleOptionFields, stages],
  );

  const recordScopeOptions = useMemo(
    () => getCachedConditionFieldOptions(dataType, project),
    [dataType, project],
  );

  const elementId = `${element.id}:VIEW`;
  const fieldOptions = useMemo(
    () =>
      singleOptionFields.map((field: any) => ({
        value: {
          id: elementId,
          path: field.name,
          source: DATABASE,
        },

        label: (
          <div className="flex items-center">
            <DataFieldIcon
              field={field}
              className="mr-2 opacity-75"
              size={18}
            />
            {field.display}
          </div>
        ),
      })),
    [elementId, singleOptionFields],
  );

  const stagesValueOption = useMemo(
    () =>
      fieldOptions.find(
        (option: any) => option.value.path === get(stages, 'path'),
      ),
    [fieldOptions, stages],
  );

  return (
    <div className="space-y-2 p-2">
      <div className="flex w-full flex-col">
        <BuildModeInput label={getText('elements', STAGES, 'input.label')}>
          <SelectInput
            options={fieldOptions}
            onChange={(option: any) => updateProperty(['stages'], option)}
            placeholder={getText('elements', STAGES, 'input.placeholder')}
            value={stagesValueOption ? stagesValueOption.value : null}
          />
        </BuildModeInput>
      </div>
      <div className="flex w-full items-center justify-between">
        <BuildModeLabel>
          {getText('elements', STAGES, 'input.useColors')}
        </BuildModeLabel>
        <Switch
          size={SM}
          onChange={(enabled: boolean) =>
            updateProperty(['useColors'], enabled)
          }
          value={useColors}
        />
      </div>
      <div className="flex w-full items-center justify-between">
        <BuildModeLabel>
          {getText('elements', STAGES, 'input.disableEditing')}
        </BuildModeLabel>
        <Switch
          size={SM}
          onChange={(enabled: any) =>
            updateProperty(['disableEditing'], enabled)
          }
          value={disableEditing}
        />
      </div>
      {selectedField && (
        <OptionFieldEditor
          dataType={dataType}
          elementPath={sectionPropPath}
          field={selectedField}
          onChange={(path, value) =>
            updateProperty(['optionsConfig', ...path], value)
          }
          project={project}
          recordScopeOptions={Object.values(recordScopeOptions)}
          values={optionsConfig}
        />
      )}
    </div>
  );
};

type ActionButtonsSectionEditorProps = {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
  updateProperty: UpdatePropertyCallback;
};

const ActionButtonsSectionEditor = ({
  dataType,
  debouncedUpdateProperty,
  project,
  section,
  sectionPropPath,
  updateProperty,
}: ActionButtonsSectionEditorProps) => {
  const { align = START, actionButtons = [] } = section.props || {};

  return (
    <>
      <BuildModeInput
        className="p-2"
        label={getText('rightSidebar.editor.position')}
      >
        <SwitchButton
          className="h-8 w-full rounded-lg"
          inverseColors={true}
          onChange={(value) => updateProperty(['align'], value)}
          options={BUTTON_ALIGNMENT.map((option) => ({
            label: getText('elements.ACTION_BUTTONS.align', option),
            value: option,
          }))}
          value={align}
        />
      </BuildModeInput>
      <ActionButtonsEditorBody
        actionButtons={actionButtons}
        className="mb-8 mt-2"
        dataType={dataType}
        debouncedUpdateProperty={debouncedUpdateProperty}
        elementPath={sectionPropPath}
        label={getText('elements', VIEW, 'actionButtons.label.default')}
        project={project}
        sectionPropPath={sectionPropPath}
        updateProperty={updateProperty}
      />
    </>
  );
};

type EmbedSectionEditorProps = {
  debouncedUpdateProperty: UpdatePropertyCallback;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
};

const EmbedSectionEditor = ({
  debouncedUpdateProperty,
  project,
  section,
  sectionPropPath,
}: EmbedSectionEditorProps) => {
  const { code } = section.props || {};

  const debouncedUpdateCode = useCallback(
    (value) => debouncedUpdateProperty(['code'], value),
    [debouncedUpdateProperty],
  );

  return (
    <div className="flex w-full flex-col p-2">
      <BuildModeInput label={getText('elements', EMBED, 'code')}>
        <StringPropEditor
          // @ts-expect-error TS(2322): Type '{ elementPath: any; project: any; onChange: ... Remove this comment to see the full error message
          elementPath={sectionPropPath}
          project={project}
          onChange={debouncedUpdateCode}
          value={code}
        />
      </BuildModeInput>
    </div>
  );
};

type TitleSectionEditorProps = {
  children?: JSX.Element;
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  multiLineSubtitle?: boolean;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
  selectedSectionPath?: ElementPath;
  source?: string;
  updateProperty: UpdatePropertyCallback;
};

const TitleSectionEditor = ({
  children,
  dataType,
  debouncedUpdateProperty,
  project,
  section,
  sectionPropPath,
  source,
  updateProperty,
}: TitleSectionEditorProps) => {
  const {
    actionButtons = [],
    title,
    subtitle,
    collapsable,
  } = section.props || {};

  const updateCoverPhotoProps = useCallback(
    (path, value) => updateProperty(['coverPhoto', 'value', ...path], value),
    [updateProperty],
  );
  const debouncedUpdateCoverPhotoProps = useCallback(
    (path, value) =>
      debouncedUpdateProperty(['coverPhoto', 'value', ...path], value),
    [debouncedUpdateProperty],
  );

  const updateImageProps = useCallback(
    (path, value) => updateProperty(['image', 'value', ...path], value),
    [updateProperty],
  );
  const debouncedUpdateImageProps = useCallback(
    (path, value) =>
      debouncedUpdateProperty(['image', 'value', ...path], value),
    [debouncedUpdateProperty],
  );

  const hideImage = useCallback(
    (value) => updateProperty(['image', 'hidden'], value),
    [updateProperty],
  );

  const hideCoverPhoto = useCallback(
    (value) => updateProperty(['coverPhoto', 'hidden'], value),
    [updateProperty],
  );

  const coverPhoto = get(section, ['props', 'coverPhoto', 'value'], null);
  const image = get(section, ['props', 'image', 'value'], null);

  return (
    <div className="flex w-full flex-col">
      <div className="mb-2">
        <div className="space-y-4 p-2">
          <BuildModeInput label={getText('elements', TITLE, 'title')}>
            <StringPropEditor
              // @ts-expect-error TS(2322): Type '{ elementPath: any; project: any; onChange: ... Remove this comment to see the full error message
              elementPath={sectionPropPath}
              project={project}
              onChange={(value: any) =>
                debouncedUpdateProperty(['title', 'value'], value)
              }
              value={get(title, 'value')}
            />
          </BuildModeInput>
          <BuildModeInput
            label={getText('elements', TITLE, 'subtitle')}
            markdown={true}
          >
            <StringPropEditor
              // @ts-expect-error TS(2322): Type '{ elementPath: any; project: any; onChange: ... Remove this comment to see the full error message
              elementPath={sectionPropPath}
              project={project}
              onChange={(value: any) =>
                debouncedUpdateProperty(['subtitle', 'value'], value)
              }
              multiLine={true}
              value={get(subtitle, 'value')}
            />
          </BuildModeInput>
          <div className="flex items-center justify-between">
            <BuildModeLabel>
              {getText('elements', TITLE, 'hideSubtitle')}
            </BuildModeLabel>
            <Switch
              size={SM}
              value={get(subtitle, 'hidden')}
              onChange={(value: boolean) =>
                updateProperty(['subtitle', 'hidden'], value)
              }
            />
          </div>
          {title && title.value && title.value.length > 0 && (
            <div className="flex items-center justify-between">
              <BuildModeLabel>
                {getText('elements', TITLE, 'collapseSection.label')}
              </BuildModeLabel>
              <Switch
                size={SM}
                value={collapsable ?? false}
                onChange={(value: boolean) =>
                  updateProperty(['collapsable'], value)
                }
              />
            </div>
          )}
          {children}
        </div>
        {source === PAGE && (
          <div className="flex flex-col space-y-4 px-2">
            <BuildModeImageEditor
              contained={true}
              dataType={dataType}
              debouncedUpdateProperty={debouncedUpdateImageProps}
              elementPath={sectionPropPath}
              elementProps={image}
              hidden={get(section, 'props.image.hidden', false)}
              hideImage={hideImage}
              label={getText('elements.VIEW.header.image')}
              project={project}
              updateProperty={updateImageProps}
            />
            <BuildModeImageEditor
              contained={true}
              debouncedUpdateProperty={debouncedUpdateCoverPhotoProps}
              elementPath={sectionPropPath}
              elementProps={coverPhoto}
              hidden={get(section, 'props.coverPhoto.hidden', false)}
              hideImage={hideCoverPhoto}
              label={getText('elements.VIEW.header.coverPhoto')}
              project={project}
              updateProperty={updateCoverPhotoProps}
            />
          </div>
        )}
      </div>
      <BuildModeActionButtonsEditor
        actionButtons={actionButtons || []}
        className="mb-8 mt-2"
        dataType={dataType}
        debouncedUpdateProperty={debouncedUpdateProperty}
        elementPath={sectionPropPath}
        label={getText('elements', VIEW, 'actionButtons.label.default')}
        project={project}
        sectionPropPath={sectionPropPath}
        updateProperty={updateProperty}
      />
    </div>
  );
};

type DetailsSectionFieldsEditorProps = {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  elementPath: ElementPath;
  isRecordView: boolean;
  project: Project;
  propertyKey: string;
  section: Element;
  sectionPropPath: ElementPath;
  selectedSectionPath: ElementPath;
  updateProperty: UpdatePropertyCallback;
};

const DetailsSectionFieldsEditor = ({
  dataType,
  debouncedUpdateProperty,
  project,
  section,
  sectionPropPath,
  selectedSectionPath,
  updateProperty,
}: DetailsSectionFieldsEditorProps) => (
  <TitleSectionEditor
    dataType={dataType}
    debouncedUpdateProperty={debouncedUpdateProperty}
    multiLineSubtitle={true}
    project={project}
    section={section}
    sectionPropPath={sectionPropPath}
    selectedSectionPath={selectedSectionPath}
    updateProperty={updateProperty}
  />
);

type QuickLinksSectionEditorProps = {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  elementPath: ElementPath;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
  updateProperty: UpdatePropertyCallback;
};

const QuickLinksSectionEditor = ({
  dataType,
  debouncedUpdateProperty,
  elementPath,
  project,
  section,
  sectionPropPath,
  updateProperty,
}: QuickLinksSectionEditorProps) => {
  const { dense, links } = section.props || {};

  return (
    <div className="flex w-full flex-col">
      <div className="flex items-center justify-between p-2">
        <BuildModeLabel>
          {getText('elements', QUICK_LINKS, 'dense')}
        </BuildModeLabel>
        <Switch
          onChange={(value: boolean) => updateProperty(['dense'], value)}
          size={SM}
          value={dense}
        />
      </div>
      <QuickLinksEditor
        dataType={dataType}
        sectionPropPath={sectionPropPath}
        links={links}
        project={project}
        elementPath={elementPath}
        className="mt-8"
        debouncedUpdateProperty={debouncedUpdateProperty}
        updateProperty={updateProperty}
      />
    </div>
  );
};

type MarkdownSectionEditorProps = {
  section: Element;
  sectionPropPath: ElementPath;
  project: Project;
  debouncedUpdateProperty: UpdatePropertyCallback;
};

const MarkdownSectionEditor = ({
  section,
  sectionPropPath,
  project,
  debouncedUpdateProperty,
}: MarkdownSectionEditorProps) => {
  const { content } = section.props || {};

  return (
    <div className="space-y-2 p-2">
      <BuildModeInput
        label={getText('elements', MARKDOWN, 'label')}
        markdown={true}
      >
        <StringPropEditor
          // @ts-expect-error TS(2322): Type '{ elementPath: any; project: any; onChange: ... Remove this comment to see the full error message
          elementPath={sectionPropPath}
          project={project}
          onChange={(value: any) => debouncedUpdateProperty(['content'], value)}
          multiLine={true}
          value={content}
        />
      </BuildModeInput>
    </div>
  );
};

type DividerEditorProps = {
  dataType: DataType;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
};

const DividerEditor = ({
  dataType,
  project,
  section,
  sectionPropPath,
}: DividerEditorProps) => (
  <VisibilityRulesEditor
    dataType={dataType}
    element={section}
    elementPath={sectionPropPath}
    project={project}
  />
);

type ImageSectionEditorProps = {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
  updateProperty: UpdatePropertyCallback;
};

const ImageSectionEditor = ({
  dataType,
  debouncedUpdateProperty,
  project,
  section,
  sectionPropPath,
  updateProperty,
}: ImageSectionEditorProps) => {
  const image = get(section, ['props', 'image', 'value'], null);

  const updateImageProps = useCallback(
    (path, value) => updateProperty(['image', 'value', ...path], value),
    [updateProperty],
  );
  const debouncedUpdateImageProps = useCallback(
    (path, value) =>
      debouncedUpdateProperty(['image', 'value', ...path], value),
    [debouncedUpdateProperty],
  );

  return (
    <div className="flex w-full flex-col space-y-2 p-2">
      <BuildModeLabel>{getText('elements', IMAGE, 'help')}</BuildModeLabel>
      <BuildModeLabel>{getText('elements.VIEW.header.image')}</BuildModeLabel>
      <BuildModeImageEditor
        contained={true}
        dataType={dataType}
        debouncedUpdateProperty={debouncedUpdateImageProps}
        elementPath={sectionPropPath}
        elementProps={image}
        hidden={get(section, 'props.image.hidden', false)}
        project={project}
        updateProperty={updateImageProps}
      />
    </div>
  );
};

type ContainerEditorProps = {
  dataType: DataType;
  project: Project;
  section: Element;
  sectionPropPath: ElementPath;
};

const ContainerEditor = ({
  dataType,
  project,
  section,
  sectionPropPath,
}: ContainerEditorProps) => (
  <VisibilityRulesEditor
    dataType={dataType}
    element={section}
    elementPath={sectionPropPath}
    project={project}
  />
);

const sectionEditor: { [key: string]: any } = {
  [ACTION_BUTTONS]: ActionButtonsSectionEditor,
  [COMMENTS]: CommentSectionEditor,
  [CONTAINER]: ContainerEditor,
  [DETAILS]: DetailsSectionFieldsEditor,
  [DIVIDER]: DividerEditor,
  [EMBED]: EmbedSectionEditor,
  [FILE_GALLERY]: FileGallerySectionEditor,
  [IFRAME]: IframeSectionEditor,
  [IMAGE]: ImageSectionEditor,
  [MARKDOWN]: MarkdownSectionEditor,
  [NOTICE]: NoticeSectionEditor,
  [QUICK_LINKS]: QuickLinksSectionEditor,
  [STAGES]: StagesSectionEditor,
  [TITLE]: TitleSectionEditor,
  [VIDEO]: VideoSectionEditor,
};

const EditorTabMap = (sectionType: string) => ({
  [COLLECTION]: {
    [GENERAL]: BuildModeViewGeneralTab,
    [OPTIONS]: BuildModeViewOptionsTab,
    ...(sectionType === CHARTS
      ? { [CHARTS_TAB]: BuildModeViewChartsTab }
      : { [FIELDS]: BuildModeViewFieldsTab }),
    [VISIBILITY]: BuildModeViewVisibilityTab,
  },
  [SECTION]: {
    ...(sectionType !== HIGHLIGHTS
      ? { [OPTIONS]: sectionEditor[sectionType] }
      : {}),
    ...(sectionType === DETAILS || sectionType === HIGHLIGHTS
      ? { [FIELDS]: SectionFieldsListEditor }
      : {}),
    [VISIBILITY]: VisibilityRulesEditor,
  },
});

type SectionEditorProps = {
  dataType: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  element: Element;
  elementPath: ElementPath;
  elementProps?: any;
  isRecordView: boolean;
  project: Project;
  propPath: ElementPath;
  section: Element;
  selectedSectionPath: ElementPath;
  source?: string;
  updateProperty: UpdatePropertyCallback;
};

const SectionEditor = ({
  dataType,
  debouncedUpdateProperty,
  element,
  elementPath,
  elementProps,
  isRecordView,
  project,
  propPath,
  section,
  selectedSectionPath,
  source,
  updateProperty,
}: SectionEditorProps) => {
  const [editorTab, setEditorTab] = useEditorTabs('sections', OPTIONS);

  const updateSectionProperty = useCallback(
    (path, value) =>
      updateProperty(
        ['sections', ...selectedSectionPath, 'props', ...path],
        value,
      ),
    [selectedSectionPath, updateProperty],
  );

  const debouncedUpdateSectionProperty = useCallback(
    (path, value) =>
      debouncedUpdateProperty(
        ['sections', ...selectedSectionPath, 'props', ...path],
        value,
      ),
    [debouncedUpdateProperty, selectedSectionPath],
  );

  const sectionPropPath = useMemo(
    () => getSectionPropPath(elementPath, propPath, selectedSectionPath),
    [elementPath, propPath, selectedSectionPath],
  );

  const sectionLayout = useMemo(() => get(section, 'props.layout'), [section]);

  const Editor = useMemo(() => {
    if (section.type === COLLECTION) {
      return EditorTabMap(sectionLayout === CHARTS ? CHARTS : section.type)[
        COLLECTION
      ][editorTab];
    }

    return EditorTabMap(section.type)[SECTION][editorTab];
  }, [section, sectionLayout, editorTab]);

  const sectionHasDataType = useMemo(
    () => get(section, 'props.dataList.dataType', null),
    [section],
  );

  const elementType = useMemo(() => {
    if (section.type === COLLECTION) {
      if (!sectionHasDataType) {
        return EMPTY_COLLECTION;
      }

      return sectionLayout === CHARTS ? CHARTS : section.type;
    }

    return [CONTAINER, DIVIDER, DETAILS, HIGHLIGHTS].includes(section.type)
      ? section.type
      : SECTION;
  }, [section, sectionLayout, sectionHasDataType]);

  const filterOptions = useMemo(() => {
    if (!isRecordView) {
      // When it's a blank page, we can just use the auth-user filters
      return undefined;
    }

    const listDataType = get(section, 'props.dataList.dataType');
    if (!listDataType) {
      return [];
    }

    const options = getCollectionOptionsOfTypeFromParent(
      project.dataTypes,
      {
        id: `${element.id}:VIEW`,
        dataType: dataType.name,
        source: DATABASE,
      },
      listDataType,
    );

    if (options.length > 0) {
      return [
        {
          label: getText('elements.VIEW.filters.all'),
          value: null,
        },
        {
          heading: true,
          label: dataType.display,
          options,
        },
      ];
    }

    return [];
  }, [dataType, element, isRecordView, project, section]);

  return (
    <>
      <div className="sticky top-12 z-10 w-full bg-slate-800">
        <BuildModeEditorTabs
          editorTab={editorTab}
          elementType={elementType}
          setEditorTab={setEditorTab}
        />
      </div>
      {![DETAILS, HIGHLIGHTS].includes(section.type) && sectionHasDataType && (
        <hr className="w-full border-slate-700" />
      )}
      {Editor && (
        <Editor
          dataType={dataType}
          debouncedUpdateProperty={debouncedUpdateSectionProperty}
          element={element}
          elementPath={elementPath}
          elementProps={elementProps}
          fields={get(section, 'props.fields', [])}
          filterOptions={filterOptions}
          hideDataTypeInput={false}
          hideSingleRecordOption={true}
          isRecordView={isRecordView}
          key={sectionPropPath}
          project={project}
          propPath={EMPTY_PROP_PATH}
          section={section}
          sectionPropPath={sectionPropPath}
          selectedSectionPath={selectedSectionPath}
          source={source}
          updateIframe={updateProperty}
          updateProperty={updateSectionProperty}
        />
      )}
    </>
  );
};

export default SectionEditor;
