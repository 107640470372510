import React, { useMemo } from 'react';
import { IconArrowLeft } from '@tabler/icons-react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { useUpdateProperty } from '@noloco/ui/src/utils/hooks/projectHooks';
import {
  CHARTS_TAB,
  FIELDS,
  GENERAL,
  LeftEditorSectionOptions,
  OPTIONS,
  VISIBILITY,
} from '../../../constants/buildMode';
import {
  CHARTS,
  CollectionLayout,
  PIVOT_TABLE,
  ROWS,
} from '../../../constants/collectionLayouts';
import { FOLDER, VIEW } from '../../../constants/elements';
import Icon from '../../../elements/Icon';
import { ElementPath } from '../../../models/Element';
import { setLeftEditorSection } from '../../../reducers/elements';
import { projectDataSelector } from '../../../selectors/projectSelectors';
import useEditorTabs from '../../../utils/hooks/useEditorTabs';
import { Page } from '../../../utils/pages';
import BuildModeEditorTabs from '../BuildModeEditorTabs';
import StandaloneRightBuildMode from '../StandaloneRightBuildMode';
import BuildModeViewChartsTab from './BuildModeViewChartsTab';
import BuildModeViewFieldsTab from './BuildModeViewFieldsTab';
import BuildModeViewGeneralTab from './BuildModeViewGeneralTab';
import BuildModeViewOptionsTab from './BuildModeViewOptionsTab';
import BuildModeViewVisibilityTab from './BuildModeViewVisibilityTab';

export type BuildModeViewEditorProps = {
  elementPath: ElementPath;
  isRecordView?: boolean;
  leftEditor: LeftEditorSectionOptions | null;
  page: Page;
};

const DEBOUNCE_TIME = 300;
const EditorTabMap = (layout: CollectionLayout) => ({
  [GENERAL]: BuildModeViewGeneralTab,
  [OPTIONS]: BuildModeViewOptionsTab,
  ...(layout === CHARTS
    ? { [CHARTS_TAB]: BuildModeViewChartsTab }
    : { [FIELDS]: BuildModeViewFieldsTab }),
  [VISIBILITY]: BuildModeViewVisibilityTab,
});

const BuildModeViewEditor = ({
  elementPath,
  isRecordView = false,
  leftEditor,
  page,
}: BuildModeViewEditorProps) => {
  const dispatch = useDispatch();
  const project = useSelector(projectDataSelector);
  const [updateProperty] = useUpdateProperty(elementPath, project);
  const debouncedUpdateProperty = useMemo(
    () => debounce(updateProperty, DEBOUNCE_TIME),
    [updateProperty],
  );
  const [editorTab, setEditorTab] = useEditorTabs('view', GENERAL);

  const dataType = useMemo(() => {
    const typeName = get(page.props.dataList, 'dataType');

    return typeName && project.dataTypes.getByName(typeName);
  }, [page, project.dataTypes]);

  const layout = useMemo(() => get(page, 'props.layout', ROWS), [page]);

  const Editor = useMemo(
    () => EditorTabMap(layout)[editorTab] ?? BuildModeViewGeneralTab,
    [editorTab, layout],
  );

  const elementType = useMemo(() => {
    if (page.type === FOLDER) {
      return FOLDER;
    }

    if (layout === CHARTS) {
      return CHARTS;
    }

    if (layout === PIVOT_TABLE) {
      return PIVOT_TABLE;
    }

    return VIEW;
  }, [page, layout]);

  return (
    <div className="w-full">
      <div className="sticky top-0 z-10 flex h-12 w-full items-center space-x-2 border-b border-gray-700 bg-slate-800 px-2 text-gray-300">
        {leftEditor ? (
          <IconArrowLeft
            className="h-5 w-5 cursor-pointer"
            onClick={() => dispatch(setLeftEditorSection(null))}
          />
        ) : (
          <Icon icon={page.props.icon} className="h-5 w-5" />
        )}
        <span>{page.props.name}</span>
      </div>
      {leftEditor ? (
        <StandaloneRightBuildMode
          dataType={dataType}
          debouncedUpdateProperty={debouncedUpdateProperty}
          element={page}
          elementPath={elementPath}
          elementType={VIEW}
          project={project}
          updateProperty={updateProperty}
        />
      ) : (
        <>
          <div className="sticky top-12 z-10 w-full bg-slate-800">
            <BuildModeEditorTabs
              editorTab={editorTab}
              elementType={elementType}
              setEditorTab={setEditorTab}
            />
          </div>
          <Editor
            dataType={dataType}
            debouncedUpdateProperty={debouncedUpdateProperty}
            element={page}
            elementPath={elementPath}
            hideSingleRecordOption={false}
            isRecordView={isRecordView}
            project={project}
            updateProperty={updateProperty}
          />
        </>
      )}
    </div>
  );
};

export default BuildModeViewEditor;
