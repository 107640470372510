import { useCallback, useMemo } from 'react';
import { IconPlus } from '@tabler/icons-react';
import get from 'lodash/get';
import Guide from '@noloco/ui/src/components/Guide';
import ProFeatureBadge from '@noloco/ui/src/components/ProFeatureBadge';
import ColorConditionsEditor from '@noloco/ui/src/components/editor/ColorConditionsEditor';
import { getNewColorCondition } from '@noloco/ui/src/components/editor/customerEditors/FieldColorsConditionsEditor';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import useIsFeatureEnabled from '@noloco/ui/src/utils/hooks/useIsFeatureEnabled';
import { VIEW_COLOR_CONDITIONS } from '../../../constants/buildMode';
import { CONDITIONAL_FIELD_VISIBILITY } from '../../../constants/features';
import { DataType } from '../../../models/DataTypes';
import { Element, ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import { getText } from '../../../utils/lang';
import { Page } from '../../../utils/pages';
import { getTypeOptionsOfTypeFromParent } from '../../../utils/renderedOptions';
import { getScopeForElement } from '../../../utils/scope';
import BuildModeSection from '../BuildModeSection';

type BuildModeColorConditionsEditorProps = {
  dataType: DataType;
  element: Page | Element;
  elementPath: ElementPath;
  project: Project;
  updateProperty: UpdatePropertyCallback;
};

const BuildModeViewColorConditionsEditor = ({
  dataType,
  element,
  elementPath,
  project,
  updateProperty,
}: BuildModeColorConditionsEditorProps) => {
  const values = useMemo(
    () => get(element, 'props.recordColoring', []),
    [element],
  );

  const isEnabled = useIsFeatureEnabled(CONDITIONAL_FIELD_VISIBILITY);

  const addColorCondition = useCallback(
    () =>
      updateProperty(['recordColoring'], [...values, getNewColorCondition()]),
    [values, updateProperty],
  );

  const removeColorCondition = useCallback(
    (id) =>
      updateProperty(
        ['recordColoring'],
        values.filter((item: any) => item.id !== id),
      ),
    [values, updateProperty],
  );

  const scopeContext = useMemo(
    () => ({
      dataTypes: project.dataTypes,
      getDataTypeOptions: (scopeItem: any) =>
        getTypeOptionsOfTypeFromParent(project.dataTypes, scopeItem),
    }),
    [project.dataTypes],
  );

  const elementScope = useMemo(
    () => [getScopeForElement(element, project, elementPath, scopeContext)],
    [element, project, elementPath, scopeContext],
  );

  return (
    <BuildModeSection
      className="border-t"
      id={VIEW_COLOR_CONDITIONS}
      guide={
        <Guide href="https://guides.noloco.io/collections/display/record-colors">
          {getText('elements.VIEW.recordColoring.guide')}
        </Guide>
      }
      title={getText('elements.VIEW.recordColoring.title')}
      emptyState={getText('elements.VIEW.recordColoring.noRecordColorings')}
      showEmptyState={values.length === 0}
      endComponent={
        <>
          <ProFeatureBadge
            feature={CONDITIONAL_FIELD_VISIBILITY}
            inline={true}
          />
          <button
            className="flex cursor-pointer items-center justify-center rounded-md p-1 opacity-75 hover:bg-gray-700 hover:opacity-100"
            disabled={!isEnabled}
            onClick={addColorCondition}
          >
            <IconPlus size={16} />
          </button>
        </>
      }
    >
      {values.map((value: any, index: number) => (
        <ColorConditionsEditor
          key={value.id}
          inline={true}
          dataType={dataType}
          fieldOptions={elementScope}
          updateConditions={(path: ElementPath, value: any) =>
            updateProperty(
              ['recordColoring', index, 'conditions', ...path],
              value,
            )
          }
          updateColor={(value: any) =>
            updateProperty(['recordColoring', index, 'color'], value)
          }
          removeColorCondition={() => removeColorCondition(value.id)}
          rules={value.conditions}
          project={project}
          elementPath={elementPath}
          colorValue={value.color}
        />
      ))}
    </BuildModeSection>
  );
};

export default BuildModeViewColorConditionsEditor;
