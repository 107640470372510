import get from 'lodash/get';
import { User } from '../models/User';

export const SAMPLE_USER_EMAIL = 'sample@noloco.io';

export const isSsoEnabled = (settings: any) =>
  get(settings, 'signInOptions.saml', false);

export const isPasswordSignUpEnabled = (settings: any) =>
  get(settings, 'signInOptions.password', true) !== false;

export const isNolocoAdmin = (user: User): boolean =>
  get(user, ['email']) === SAMPLE_USER_EMAIL;

export const isMagicLinkSignUpEnabled = (settings: any) =>
  get(settings, 'signInOptions.magicLink', true) !== false;
