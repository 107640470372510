import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { IconPlus } from '@tabler/icons-react';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import { useDrop } from 'react-dnd';
import { Label, Loader, TextInput } from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { INTERNAL } from '@noloco/core/src/constants/dataSources';
import DataFieldOptions from '@noloco/core/src/models/DataFieldOptions';
import {
  DataField,
  DataFieldOption,
} from '@noloco/core/src/models/DataTypeFields';
import { getColorByIndex } from '@noloco/core/src/utils/colors';
import { sortOptions } from '@noloco/core/src/utils/fields';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { getText } from '@noloco/core/src/utils/lang';
import { isOptionValid } from '@noloco/core/src/utils/options';
import {
  ADD_DATA_FIELD_OPTION,
  REMOVE_DATA_FIELD_OPTION,
  RENAME_DATA_FIELD_OPTION,
  UPDATE_DATA_FIELD,
} from '../../../queries/project';
import { useUpdateDataField } from '../../../utils/hooks/useUpdateDataTypes';
import BulkAddOptions from './BulkAddOptions';
import ColorOptionInput from './ColorOptionInput';
import FieldOptionsInputEntry from './FieldOptionsInputEntry';

type Props = {
  dataTypeId: number;
  readOnly: boolean;
  readOnlyColor: boolean;
  projectName: string;
  field: DataField;
  showBulkAddScreen?: boolean;
  setShowBulkAddScreen: (showBulkAddScreen: boolean) => void;
};

const FieldOptionsInput = ({
  dataTypeId,
  projectName,
  readOnly,
  readOnlyColor,
  field,
  showBulkAddScreen = false,
  setShowBulkAddScreen,
}: Props) => {
  const updateDataField = useUpdateDataField();
  const oldOrderRef = useRef<number | null>(null);
  const errorAlert = useGraphQlErrorAlert();

  const isNolocoField = field.source === INTERNAL;

  const [createDataFieldOption, { loading: createLoading }] = useMutation(
    ADD_DATA_FIELD_OPTION,
  );
  const [removeDataFieldOption] = useMutation(REMOVE_DATA_FIELD_OPTION);
  const [renameDataFieldOption, { loading: renameLoading }] = useMutation(
    RENAME_DATA_FIELD_OPTION,
  );
  const [updateDataFieldMutation, { loading: updateLoading }] =
    useMutation(UPDATE_DATA_FIELD);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<DataFieldOption[]>(
    field.options ?? [],
  );
  const [draftOption, setDraftOption] = useState('');
  const [draftOptionColor, setDraftOptionColor] = useState(null);

  const isLoading = createLoading || renameLoading || updateLoading;

  const canDelete = useMemo(() => options.length > 1, [options.length]);
  const findOption = useCallback(
    (optionId) => {
      const option = options.find(({ id }: any) => id === optionId);
      if (!option) {
        return { order: -1 };
      }

      return {
        option,
        order: option.order,
      };
    },
    [options],
  );

  const sortedOptions = useMemo(() => sortOptions(options), [options]);

  const updateOptionsOrder = useCallback(
    (newOptions) => {
      if (newOptions !== field.options) {
        setLoading(true);

        updateDataFieldMutation({
          variables: {
            projectName,
            id: field.id,
            typeOptions: field.typeOptions,
            options: options.map((it: any) => ({
              id: it.id,
              order: it.order,
            })),
          },
        })
          .then(({ data }) => {
            if (data.updateDataField) {
              updateDataField({
                dataTypeId: dataTypeId,
                dataField: data.updateDataField,
              });
            }
          })
          .catch((e) => {
            errorAlert(getText('data.options.edit.bulkError'), e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [
      dataTypeId,
      errorAlert,
      field.id,
      field.options,
      field.typeOptions,
      options,
      projectName,
      updateDataField,
      updateDataFieldMutation,
    ],
  );

  const onReorder = useCallback(
    (draggedId, newIndex) => {
      const { order: oldOrder } = findOption(draggedId);
      if (oldOrder !== oldOrderRef.current) {
        oldOrderRef.current = oldOrder;
        const newOptionOrder = [...sortedOptions];
        const field = first(newOptionOrder.splice(newIndex, 1));
        if (field) {
          newOptionOrder.splice(oldOrder, 0, field);
        }
        setOptions(
          () =>
            new DataFieldOptions(
              [...newOptionOrder].map((option, order) => ({
                ...option,
                order: order,
              })),
            ),
        );
      }
    },
    [findOption, sortedOptions],
  );

  const saveOrder = useCallback(() => {
    setTimeout(() => {
      updateOptionsOrder(options);
    }, 300);
  }, [options, updateOptionsOrder]);

  const updateOptionName = (option: DataFieldOption, newName: string) => {
    setOptions((currentOptions) => {
      const nextOptions = [...currentOptions];
      return new DataFieldOptions(
        set(
          [nextOptions.findIndex((o: any) => o.id === option.id), 'display'],
          newName,
          nextOptions,
        ),
      );
    });
  };

  const isDraftOptionValid =
    draftOption && isOptionValid({ display: draftOption });

  const deleteOption = (option: DataFieldOption) => {
    setLoading(true);
    removeDataFieldOption({
      variables: {
        projectName,
        dataTypeId,
        dataFieldId: field.id,
        optionId: option.id,
      },
    })
      .then(({ data }) => {
        if (data.removeDataFieldOption) {
          updateDataField({
            dataTypeId,
            dataField: data.removeDataFieldOption,
          });
          setOptions(data.removeDataFieldOption.options);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addOption = (event: any) => {
    event.preventDefault();
    if (isDraftOptionValid) {
      setLoading(true);
      createDataFieldOption({
        variables: {
          projectName,
          dataTypeId,
          dataFieldId: field.id,
          option: { display: draftOption, color: draftOptionColor },
        },
      })
        .then(({ data }) => {
          if (data.createDataFieldOption) {
            updateDataField({
              dataTypeId,
              dataField: data.createDataFieldOption,
            });
            setOptions(data.createDataFieldOption.options);
          }
        })
        .catch((e) => {
          errorAlert(getText('data.options.edit.error'), e);
        })
        .finally(() => {
          setLoading(false);
          setDraftOption('');
          // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
          setDraftOptionColor();
        });
    }
  };

  const updateOption = (option: DataFieldOption) => {
    const originalOption = field.options?.find(
      ({ id }: any) => id === option.id,
    );
    if (
      (originalOption && originalOption.display !== option.display) ||
      (originalOption && originalOption.color !== option.color)
    ) {
      setLoading(true);
      renameDataFieldOption({
        variables: {
          projectName,
          dataTypeId,
          dataFieldId: field.id,
          optionId: option.id,
          option: { display: option.display, color: option.color },
        },
      })
        .then(({ data }) => {
          if (data.renameDataFieldOption) {
            updateDataField({
              dataTypeId,
              dataField: data.renameDataFieldOption,
            });
            setOptions(data.renameDataFieldOption.options);
          }
        })
        .catch((e) => {
          errorAlert(getText('data.options.edit.error'), e);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const updateOptionColor = (option: DataFieldOption, nextColor: string) => {
    setOptions((currentOptions) => {
      const nextOptions = [...currentOptions];
      return new DataFieldOptions(
        set(
          [nextOptions.findIndex((o: any) => o.id === option.id), 'color'],
          nextColor,
          nextOptions,
        ),
      );
    });

    updateOption({ ...option, color: nextColor });
  };

  const [, drop] = useDrop({
    accept: 'OPTION',
  });

  return (
    <>
      <Label surface={LIGHT}>{getText('data.options.label')}</Label>
      {!readOnly && (
        <p className="mb-4 text-sm text-gray-600">
          {getText('data.options.edit.disclaimer')}
        </p>
      )}
      {showBulkAddScreen ? (
        <BulkAddOptions
          setShowBulkAddScreen={setShowBulkAddScreen}
          options={options}
          // @ts-expect-error TS2322: Type 'Dispatch<SetStateAction<DataFieldOption[]>>' is not assignable to type
          setOptions={setOptions}
          projectName={projectName}
          dataTypeId={dataTypeId}
          dataField={field}
        />
      ) : (
        <>
          <div
            className="flex max-h-96 w-full flex-col gap-y-1 overflow-y-auto py-1 first:pt-0 last:pb-0"
            ref={drop}
          >
            {sortedOptions.map((option) => {
              return (
                <FieldOptionsInputEntry
                  key={option.id}
                  deleteDisabled={!canDelete}
                  deleteOption={isNolocoField ? deleteOption : undefined}
                  loading={loading}
                  onDropItem={saveOrder}
                  onReorder={onReorder}
                  option={option}
                  readOnly={readOnly}
                  readOnlyColor={readOnlyColor}
                  updateOption={updateOption}
                  updateOptionName={updateOptionName}
                  updateOptionColor={updateOptionColor}
                />
              );
            })}
          </div>
          {isNolocoField && !readOnly && (
            <form className="mt-4 flex items-center" onSubmit={addOption}>
              <ColorOptionInput
                className="mr-2"
                onChange={(nextColor: any) => setDraftOptionColor(nextColor)}
                value={draftOptionColor || getColorByIndex(options.length + 1)}
              />
              <TextInput
                placeholder={getText('data.options.edit.placeholder')}
                surface={LIGHT}
                value={draftOption}
                onChange={({ target: { value } }: any) => setDraftOption(value)}
              />
              <button
                className="ml-2 whitespace-nowrap p-1 opacity-75 hover:opacity-100 disabled:opacity-50"
                disabled={!isDraftOptionValid || isLoading}
                type="submit"
              >
                {!isLoading ? <IconPlus size={16} /> : <Loader size="sm" />}
              </button>
            </form>
          )}
        </>
      )}
    </>
  );
};

export default FieldOptionsInput;
