import React from 'react';
import PropTypes from 'prop-types';
import SEOSettings from '@noloco/core/src/elements/SEOSettings';
import { getMediaImageSrc } from '@noloco/core/src/utils/image';

const Head = ({ settings, media }: any) => {
  const {
    title,
    description,
    socialBanner = {},
    socialMediaDesc,
  } = settings || {};

  const socialBannerSrc =
    socialBanner.mediaId && getMediaImageSrc(socialBanner.mediaId, media);

  return (
    <SEOSettings
      project={{ settings }}
      title={title}
      description={description}
      openGraphTitle={title}
      openGraphDescription={socialMediaDesc}
      openGraphImage={socialBannerSrc ? { src: socialBannerSrc } : undefined}
    />
  );
};

Head.propTypes = {
  settings: PropTypes.object.isRequired,
  media: PropTypes.array.isRequired,
};

export default Head;
