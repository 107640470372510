import { DARK, LIGHT } from '../constants/surface';
import { Variant } from '../constants/variants';

export type Surface = typeof DARK | typeof LIGHT;

export type Color = string;

export type BaseColor =
  | 'gray'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'indigo'
  | 'green'
  | 'blue'
  | 'teal'
  | 'purple'
  | 'pink';

export type Display = 'flex' | 'block' | 'inlineBlock' | 'hidden';

export type Position = 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky';

export type Theme = {
  spacing: {
    zero: number;
    sm: number;
    md: number;
    lg: number;
    xl: number;
  };
  brandColors: Record<Exclude<Variant, 'info' | 'custom'> | 'success', Color>;
  surfaceColors: {
    dark: Color;
    light: Color;
  };
  dropzone: {
    surface: string;
    textColor: string;
  };
  borderColors: Partial<{
    dark: Color;
    light: Color;
    default: Color;
  }>;
  brandColorGroups: Record<
    Exclude<Variant, 'info' | 'custom'> | 'success',
    Color
  >;
  [key: string]: any;
};
