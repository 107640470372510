import React from 'react';
import {
  IconLink,
  IconMathFunction,
  IconRotateClockwise2,
  IconZoomInArea,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { SelectInput } from '@noloco/components';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  FORMULA,
  INTEGER,
  LOOKUP,
  MULTIPLE_OPTION,
  OBJECT,
  ROLLUP,
  SINGLE_OPTION,
  TEXT,
} from '@noloco/core/src/constants/dataTypes';
import {
  DUE_DATE,
  OBJECT_FORMATS,
} from '@noloco/core/src/constants/fieldFormats';
import { getText } from '@noloco/core/src/utils/lang';
import {
  HIDDEN_DATA_TYPES,
  getGroupedDataTypeOptions,
} from '../../../utils/dataTypes';
import DataFieldIcon from '../../DataFieldIcon';

const AVAILABLE_DATA_TYPES = [
  TEXT,
  DATE,
  INTEGER,
  DECIMAL,
  BOOLEAN,
  SINGLE_OPTION,
  MULTIPLE_OPTION,
  DURATION,
];

const dataTypeFilter = ({ name, internal }: any) =>
  !HIDDEN_DATA_TYPES.includes(name) && !internal;

const FieldTypeInput = ({
  dataTypes,
  filter = dataTypeFilter,
  lookupFieldsEnabled,
  objectTypesEnabled,
  onChange,
  readOnly = false,
  surface,
  value,
}: any) => (
  <SelectInput
    className="mb-2 text-black"
    data-testid="field-type-input"
    contained={true}
    disabled={readOnly}
    options={[
      ...AVAILABLE_DATA_TYPES.map((dataTypeOption) => ({
        value: dataTypeOption,
        label: (
          <div className="flex items-center">
            <DataFieldIcon
              // @ts-expect-error TS(2741): Property 'name' is missing in type '{ type: string... Remove this comment to see the full error message
              field={{ type: dataTypeOption }}
              className={classNames('mr-2 w-5', {
                'text-gray-800': surface === LIGHT,
                'text-gray-200': surface === DARK,
              })}
            />
            {getText('data.types', dataTypeOption, 'label')}
          </div>
        ),
      })),
      ...(objectTypesEnabled
        ? OBJECT_FORMATS.filter((format) => format !== DUE_DATE).map(
            (objectFormat) => ({
              value: objectFormat,
              label: (
                <div className="flex items-center">
                  <DataFieldIcon
                    // @ts-expect-error TS(2741): Property 'name' is missing in type '{ type: string... Remove this comment to see the full error message
                    field={{
                      type: OBJECT,
                      typeOptions: { format: objectFormat },
                    }}
                    className={classNames('mr-2 w-5', {
                      'text-gray-800': surface === LIGHT,
                      'text-gray-200': surface === DARK,
                    })}
                  />
                  {getText('data.types', OBJECT, objectFormat, 'label')}
                </div>
              ),
            }),
          )
        : []),
      {
        value: FILE,
        label: (
          <div className="flex items-center">
            <DataFieldIcon
              // @ts-expect-error TS(2741): Property 'name' is missing in type '{ type: string... Remove this comment to see the full error message
              field={{ type: FILE }}
              className={classNames('mr-2 w-5', {
                'text-gray-800': surface === LIGHT,
                'text-gray-200': surface === DARK,
              })}
            />
            {getText('data.typeInput.fileLabel')}
          </div>
        ),
      },
      {
        icon: <IconMathFunction size={16} />,
        label: getText('data.typeInput.formula.label'),
        help: getText('data.typeInput.formula.help'),
        value: FORMULA,
      },
      ...(lookupFieldsEnabled
        ? [
            {
              icon: <IconZoomInArea size={16} />,
              label: getText('data.typeInput.lookup.label'),
              help: getText('data.typeInput.lookup.help'),
              value: LOOKUP,
            },
          ]
        : []),
      {
        icon: <IconRotateClockwise2 size={16} />,
        label: getText('data.typeInput.rollup.label'),
        help: getText('data.typeInput.rollup.help'),
        value: ROLLUP,
      },
      {
        icon: <IconLink size={16} />,
        label: getText('data.typeInput.relations'),
        options: getGroupedDataTypeOptions(dataTypes, { filter }),
      },
    ]}
    placeholder={getText('data.typeInput.placeholder')}
    readOnly={readOnly}
    onChange={onChange}
    surface={surface}
    value={value}
  />
);

export default FieldTypeInput;
