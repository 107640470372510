import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import classNames from 'classnames';
import { Label, Loader, SelectInput } from '@noloco/components';
import { FILE } from '@noloco/core/src/constants/builtInDataTypes';
import { OBJECT } from '@noloco/core/src/constants/dataTypes';
import { VALID_TYPES_TO_CONVERT } from '@noloco/core/src/constants/fieldConversion';
import { OBJECT_FORMATS } from '@noloco/core/src/constants/fieldFormats';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { getText } from '@noloco/core/src/utils/lang';
import { UPDATE_DATA_FIELD_TYPE } from '../../../queries/project';
import { useUpdateDataField } from '../../../utils/hooks/useUpdateDataTypes';
import DataFieldIcon from '../../DataFieldIcon';

const LANG_KEY = 'data.changeTypeInput';

const ChangeFieldTypeInput = ({
  className,
  field,
  dataType,
  projectName,
  refetchData,
  surface,
  typeOptions,
}: any) => {
  const updateDataField = useUpdateDataField();
  const errorAlert = useGraphQlErrorAlert();
  const [dataLoading, setDataLoading] = useState(false);
  const [updateFieldType, { loading }] = useMutation(UPDATE_DATA_FIELD_TYPE);

  const options = useMemo(
    () =>
      typeOptions.map((type: any) => ({
        value: type,

        label: (
          <div className="flex items-center">
            <DataFieldIcon
              // @ts-expect-error TS(2741): Property 'name' is missing in type '{ type: any; }... Remove this comment to see the full error message
              field={
                OBJECT_FORMATS.includes(type)
                  ? { type: OBJECT, typeOptions: { format: type } }
                  : { type }
              }
              className="mr-2 w-5 text-gray-800"
            />
            {getText(
              OBJECT_FORMATS.includes(type)
                ? 'data.types.OBJECT'
                : 'data.types',
              type,
              'label',
            )}
          </div>
        ),
      })),
    [typeOptions],
  );

  const onChange = useCallback(
    (newType) => {
      if (newType !== field.type) {
        updateFieldType({
          variables: {
            projectName,
            id: field.id,
            type: OBJECT_FORMATS.includes(newType) ? OBJECT : newType,
            typeOptions: OBJECT_FORMATS.includes(newType)
              ? { format: newType }
              : undefined,
          },
        })
          .then(({ data }) => {
            const updatedDataField = data.updateDataFieldType;
            updateDataField({
              dataTypeId: dataType.id,
              dataField: updatedDataField,
            });

            if (
              newType !== FILE &&
              field.type !== FILE &&
              newType !== OBJECT &&
              field.type !== OBJECT
            ) {
              return refetchData().then(() => {
                setDataLoading(false);
              });
            } else {
              return setDataLoading(false);
            }
          })
          .catch((e) => {
            errorAlert(getText(LANG_KEY, 'error'), e);
          })
          .finally(() => {
            setDataLoading(false);
          });
      }
    },
    [
      dataType.id,
      errorAlert,
      field.id,
      field.type,
      projectName,
      refetchData,
      updateDataField,
      updateFieldType,
    ],
  );

  return (
    <div className={classNames(className, 'flex w-full flex-col')}>
      <Label className="mb-1" surface={surface}>
        {getText(LANG_KEY, 'label')}
      </Label>
      <div className="mt-1 flex w-full items-center">
        <SelectInput
          className="w-full"
          contained={true}
          disabled={loading || dataLoading}
          options={options}
          onChange={onChange}
          value={field.type === OBJECT ? field.typeOptions?.format : field.type}
        />
        {(loading || dataLoading) && <Loader size="sm" className="ml-4" />}
      </div>
    </div>
  );
};

ChangeFieldTypeInput.defaultProps = {
  typeOptions: VALID_TYPES_TO_CONVERT,
};

export default ChangeFieldTypeInput;
