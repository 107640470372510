import { useCallback, useMemo, useState } from 'react';
import { IconPlus } from '@tabler/icons-react';
import first from 'lodash/first';
import get from 'lodash/get';
import shortId from 'shortid';
import { SelectInput, TextInput } from '@noloco/components/src';
import { DataTypeValue } from '@noloco/ui/src/components/canvas/DataTypeInput';
import ConditionsEditor from '@noloco/ui/src/components/editor/ConditionsEditor';
import DraggableListItem from '@noloco/ui/src/components/editor/customerEditors/DraggableListItem';
import {
  UPDATE_DEBOUNCE_MS,
  UpdatePropertyCallback,
} from '@noloco/ui/src/utils/hooks/projectHooks';
import { VIEW_CHARTS_SERIES } from '../../constants/buildMode';
import { STACKED_BAR } from '../../constants/chartTypes';
import { BOOLEAN, DATE, DECIMAL, INTEGER } from '../../constants/dataTypes';
import { DataType } from '../../models/DataTypes';
import { Element, ElementPath } from '../../models/Element';
import { Project } from '../../models/Project';
import StateItem from '../../models/StateItem';
import { getDataTypesWithRelations } from '../../utils/data';
import { getText } from '../../utils/lang';
import { getTypeOptionsOfTypeFromParent } from '../../utils/renderedOptions';
import { getScopeForElement } from '../../utils/scope';
import BuildModeInput from './BuildModeInput';
import BuildModeSection from './BuildModeSection';

const LANG_KEY = 'elements.VIEW.charts';

const expandFieldTypes = (dataType: any) => {
  switch (dataType) {
    case DECIMAL:
    case INTEGER:
      return [DECIMAL, INTEGER];
    default:
      return [dataType];
  }
};

const getAcceptableDataTypesForYAxis = (series: any) => {
  const firstSeries = first(series);

  if (series.length > 1 && firstSeries && (firstSeries as any).yAxisValue) {
    return expandFieldTypes((firstSeries as any).yAxisValue.dataType);
  }

  return [DATE, INTEGER, DECIMAL, BOOLEAN];
};

type BuildModeChartSeriesEditorProps = {
  chart: any;
  dataType: DataType;
  element: Element;
  elementPath: ElementPath;
  index: number;
  onUpdate: UpdatePropertyCallback;
  project: Project;
  stateItem: StateItem;
};

const BuildModeChartSeriesEditor = ({
  chart,
  dataType,
  element,
  elementPath,
  index,
  onUpdate,
  project,
  stateItem,
}: BuildModeChartSeriesEditorProps) => {
  const [activeListItem, setActiveListItem] = useState<number | null>(null);
  const [popoutOpen, setPopoutOpen] = useState(false);

  const chartSeries = useMemo(() => get(chart, 'series', []), [chart]);

  const addNewSeries = useCallback(
    () =>
      onUpdate(
        [index, 'series'],
        [...(chart.series || []), { id: shortId.generate() }],
      ),
    [chart.series, index, onUpdate],
  );

  const onDeleteSeries = useCallback(
    (seriesId) =>
      onUpdate(
        [index, 'series'],
        chart.series.filter(({ id }: any) => id !== seriesId),
      ),
    [chart.series, index, onUpdate],
  );

  const yAxisOptions = useMemo(
    () =>
      getTypeOptionsOfTypeFromParent(
        project.dataTypes,
        stateItem,
        getAcceptableDataTypesForYAxis(chart.series),
      ),
    [chart.series, project, stateItem],
  );

  const listOptions = useMemo(
    () =>
      chartSeries.map((series: any, index: number) => ({
        label:
          series.label ||
          getText({ n: index + 1 }, LANG_KEY, 'series.placeholder'),
        value: index,
      })),
    [chartSeries],
  );

  const dataTypesWithRelations = useMemo(
    () => getDataTypesWithRelations(project.dataTypes),
    [project.dataTypes],
  );

  const scopeContext = useMemo(() => {
    return {
      dataTypes: dataTypesWithRelations,
      getDataTypeOptions: (scopeItem: any) =>
        getTypeOptionsOfTypeFromParent(dataTypesWithRelations, scopeItem),
    };
  }, [dataTypesWithRelations]);

  const elementScope = useMemo(() => {
    return getScopeForElement(element, project, elementPath, scopeContext);
  }, [element, project, elementPath, scopeContext]);

  return (
    <BuildModeSection
      id={VIEW_CHARTS_SERIES}
      className="border-t"
      endComponent={
        chart.chartType !== STACKED_BAR ? (
          <div
            className="flex cursor-pointer items-center justify-center rounded-md p-1 text-gray-300 opacity-75 hover:bg-gray-700 hover:opacity-100"
            onClick={addNewSeries}
          >
            <IconPlus size={16} />
          </div>
        ) : undefined
      }
      title={getText(LANG_KEY, 'series.label')}
    >
      <div className="mb-2 flex flex-col space-y-2 p-2">
        {chartSeries.map((chartSeries: any, seriesIndex: any) => (
          <DraggableListItem
            canDelete={seriesIndex !== 0}
            draggable={false}
            id={chartSeries.id}
            index={seriesIndex}
            key={chartSeries.id}
            onRemove={() => onDeleteSeries(chartSeries.id)}
            title={
              chartSeries.label ||
              getText({ n: seriesIndex + 1 }, LANG_KEY, 'series.placeholder')
            }
            activeListItem={activeListItem}
            setActiveListItem={setActiveListItem}
            popoutOpen={popoutOpen}
            setPopoutOpen={setPopoutOpen}
            listOptions={listOptions}
            disabled={chart.chartType === STACKED_BAR && seriesIndex !== 0}
          >
            <div className="space-y-4 p-2">
              <BuildModeInput label={getText(LANG_KEY, 'yAxisValue.name')}>
                <TextInput
                  className="mt-1"
                  debounceMs={UPDATE_DEBOUNCE_MS}
                  onChange={({ target: { value: nextLabel } }) =>
                    onUpdate([index, 'series', seriesIndex, 'label'], nextLabel)
                  }
                  value={chartSeries.label}
                />
              </BuildModeInput>
              <BuildModeInput label={getText(LANG_KEY, 'yAxisValue.title')}>
                <SelectInput
                  Button={DataTypeValue}
                  className="text-black"
                  contained={true}
                  value={chartSeries.yAxisValue}
                  options={yAxisOptions}
                  onChange={(value: any) =>
                    onUpdate(
                      [index, 'series', seriesIndex, 'yAxisValue'],
                      value,
                    )
                  }
                  placement="left"
                  placeholder={getText(LANG_KEY, 'yAxisValue.placeholder')}
                  searchable={true}
                />
              </BuildModeInput>
              <BuildModeInput
                label={getText(LANG_KEY, 'conditions')}
                className="mt-2"
              >
                <ConditionsEditor
                  conditionId={chartSeries.id}
                  contained={false}
                  dataType={dataType}
                  fieldOptions={[elementScope]}
                  rules={chartSeries.conditions || []}
                  project={project}
                  updateConditions={(path: any, value: any) =>
                    onUpdate(
                      [index, 'series', seriesIndex, 'conditions', ...path],
                      value,
                    )
                  }
                  //Arbitrarily appending 'chartSeries' to elementPath to get additional options, when pased down to DynamicValueInput
                  elementPath={[...elementPath, 'chartSeries']}
                />
              </BuildModeInput>
            </div>
          </DraggableListItem>
        ))}
      </div>
    </BuildModeSection>
  );
};

export default BuildModeChartSeriesEditor;
