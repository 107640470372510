import React, { memo, useMemo } from 'react';
import { expandDataTypes } from '@noloco/core/src/utils/data';
import ConditionValueInput from './ConditionValueInput';

const ConditionValueEditor = memo(
  ({
    // @ts-expect-error TS(2339): Property 'acceptableDataTypes' does not exist on t... Remove this comment to see the full error message
    acceptableDataTypes,
    // @ts-expect-error TS(2339): Property 'additionalScopeItems' does not exist on ... Remove this comment to see the full error message
    additionalScopeItems = [],
    // @ts-expect-error TS(2339): Property 'contained' does not exist on type '{ chi... Remove this comment to see the full error message
    contained,
    // @ts-expect-error TS(2339): Property 'disabled' does not exist on type '{ chil... Remove this comment to see the full error message
    disabled,
    // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{ c... Remove this comment to see the full error message
    elementPath,
    // @ts-expect-error TS(2339): Property 'field' does not exist on type '{ childre... Remove this comment to see the full error message
    field,
    // @ts-expect-error TS(2339): Property 'includeUniqueColumnar' does not exist on... Remove this comment to see the full error message
    includeUniqueColumnar,
    // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{ chil... Remove this comment to see the full error message
    dataType,
    // @ts-expect-error TS(2339): Property 'multiple' does not exist on type '{ chil... Remove this comment to see the full error message
    multiple,
    // @ts-expect-error TS(2339): Property 'onChange' does not exist on type '{ chil... Remove this comment to see the full error message
    onChange,
    // @ts-expect-error TS(2339): Property 'placeholder' does not exist on type '{ c... Remove this comment to see the full error message
    placeholder,
    // @ts-expect-error TS(2339): Property 'fieldFilter' does not exist on type '{ c... Remove this comment to see the full error message
    fieldFilter,
    // @ts-expect-error TS(2339): Property 'includeSelf' does not exist on type '{ c... Remove this comment to see the full error message
    includeSelf,
    // @ts-expect-error TS(2339): Property 'value' does not exist on type '{ childre... Remove this comment to see the full error message
    value,
  }) => {
    const updatedAcceptableDataTypes = useMemo(() => {
      if (!field) {
        return undefined;
      }

      if (field.relationship || field.relatedField) {
        return acceptableDataTypes ? acceptableDataTypes : [field.type];
      }

      return expandDataTypes(field.type);
    }, [acceptableDataTypes, field]);

    return (
      <ConditionValueInput
        acceptableDataTypes={updatedAcceptableDataTypes}
        additionalScopeItems={additionalScopeItems}
        contained={contained}
        dataType={dataType}
        disabled={disabled}
        elementPath={elementPath}
        field={field}
        fieldFilter={fieldFilter}
        includeUniqueColumnar={includeUniqueColumnar}
        includeRelationships={true}
        includeSelf={includeSelf}
        multiple={multiple}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
    );
  },
);

(ConditionValueEditor as any).defaultProps = {
  acceptableDataTypes: undefined,
  includeUniqueColumnar: false,
};

export default ConditionValueEditor;
