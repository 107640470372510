import React, { memo, useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  IconArrowNarrowRight,
  IconInfoCircle,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import shortid from 'shortid';
import {
  ConfirmationButton,
  FormField,
  HelpTooltip,
  Label,
  Modal,
  SelectInput,
  Switch,
  Tooltip,
} from '@noloco/components';
import { LIGHT } from '@noloco/components/src/constants/surface';
import { FILE, USER } from '@noloco/core/src/constants/builtInDataTypes';
import {
  DATE,
  DataFieldType,
  OBJECT,
} from '@noloco/core/src/constants/dataTypes';
import { CONDITIONAL_USER_LIST_ROLES } from '@noloco/core/src/constants/features';
import { DATABASE } from '@noloco/core/src/constants/scopeTypes';
import { DataField } from '@noloco/core/src/models/DataTypeFields';
import { DataType } from '@noloco/core/src/models/DataTypes';
import { Project } from '@noloco/core/src/models/Project';
import StateItem from '@noloco/core/src/models/StateItem';
import { conditionsAreValid } from '@noloco/core/src/utils/conditions';
import { addRelatedFieldsToDataType } from '@noloco/core/src/utils/data';
import { filterDefaultFields } from '@noloco/core/src/utils/defaultFields';
import {
  useGraphQlErrorAlert,
  useSuccessAlert,
} from '@noloco/core/src/utils/hooks/useAlerts';
import usePromiseQuery from '@noloco/core/src/utils/hooks/usePromiseQuery';
import { getText } from '@noloco/core/src/utils/lang';
import { RECORD_SCOPE } from '@noloco/core/src/utils/scope';
import { withNullOption } from '@noloco/core/src/utils/settings';
import {
  ADD_USER_LIST,
  DELETE_USER_LIST,
  GET_DATA_TYPE,
  GET_USER_LISTS,
  UPDATE_USER_LIST,
} from '../../queries/project';
import { useFieldNameValidation } from '../../utils/hooks/useDataTypeNameValidation';
import useIsFeatureEnabled from '../../utils/hooks/useIsFeatureEnabled';
import { useUpdateDataType } from '../../utils/hooks/useUpdateDataTypes';
import DataFieldIcon from '../DataFieldIcon';
import FeatureLockedButton from '../FeatureLockedButton';
import BackendConditionValueEditor from '../canvas/BackendConditionValueEditor';
import ConditionsEditor from '../editor/ConditionsEditor';

const LANG_KEY = 'settings.signUp';

type UserListField = 'email' | 'firstName' | 'lastName' | 'profilePicture';
const USER_FIELDS: UserListField[] = [
  'email',
  'firstName',
  'lastName',
  'profilePicture',
];
const USER_NAME_FIELDS = ['firstName', 'lastName'];
const PROFILE_PICTURE = 'profilePicture';

const FieldOption = memo(({ field }: { field: DataField }) => (
  <div className="flex items-center">
    <DataFieldIcon field={field} className="mr-2 opacity-75" size={16} />
    {field.display}
  </div>
));

type Props = {
  project: Project;
  value: any;
  onChange: (path: any, value: any) => void;
  onClose: () => void;
  isUpdate: boolean;
  roleOptions: any;
  tableOptions: any;
  userType: DataType;
  setImportStatus?: any;
};

const UserListItemForm = ({
  project,
  value,
  onChange,
  onClose,
  isUpdate,
  roleOptions,
  tableOptions,
  userType,
  setImportStatus,
}: Props) => {
  const updateDataType = useUpdateDataType();
  const errorAlert = useGraphQlErrorAlert();
  const successAlert = useSuccessAlert();
  const [getDataType] = usePromiseQuery(GET_DATA_TYPE, {
    fetchPolicy: 'no-cache',
  });
  const [saving, setSaving] = useState(false);
  const [mutateUserList, { client, loading }] = useMutation(
    isUpdate ? UPDATE_USER_LIST : ADD_USER_LIST,
  );

  const [deleteUserList] = useMutation(DELETE_USER_LIST);
  const setUserTableId = useCallback(
    (val) => {
      onChange(['dataTypeId'], val);
    },
    [onChange],
  );

  const userDataTypeId = useMemo(
    () => project.dataTypes.getByName(USER)?.id,
    [project.dataTypes],
  );

  const setDisplayName = useCallback(
    ({ target }) => {
      onChange(['display'], target.value);
    },
    [onChange],
  );

  const updateConditionalInclude = useCallback(
    (path: any, conditionValue: any) =>
      onChange(
        ['conditionalInclude'],
        set(
          ['conditions', ...path],
          conditionValue,
          value.conditionalInclude || [],
        ),
      ),
    [onChange, value.conditionalInclude],
  );

  const setDefaultRole = useCallback(
    (val) => {
      onChange(['defaultRoleId'], val);
    },
    [onChange],
  );

  const conditionalRolesEnabled = useIsFeatureEnabled(
    CONDITIONAL_USER_LIST_ROLES,
  );

  const addConditionalRole = useCallback(
    () =>
      onChange(
        ['conditionalRoles'],
        [
          ...(value.conditionalRoles || []),
          {
            _id: shortid.generate(),
            roleId: null,
            conditions: [[{ field: null, operator: null, value: null }]],
          },
        ],
      ),
    [onChange, value.conditionalRoles],
  );

  const deleteConditionalRole = useCallback(
    (conditionalRoleIndex) => () =>
      onChange(
        ['conditionalRoles'],
        (value.conditionalRoles || []).filter(
          (__: any, idx: any) => idx !== conditionalRoleIndex,
        ),
      ),
    [onChange, value.conditionalRoles],
  );

  const updateConditionalRole = useCallback(
    (conditionalRoleIndex) => (nextRole: any) =>
      onChange(['conditionalRoles', conditionalRoleIndex, 'roleId'], nextRole),
    [onChange],
  );

  const updateConditions = useCallback(
    (conditionalRoleIndex) => (path: any, conditionValue: any) =>
      onChange(
        ['conditionalRoles'],
        set(
          [conditionalRoleIndex, 'conditions', ...path],
          conditionValue,
          value.conditionalRoles || [],
        ),
      ),
    [onChange, value.conditionalRoles],
  );

  const userTableId: number = value.dataTypeId;

  const userTable = useMemo(
    () => (userTableId ? project.dataTypes.getById(userTableId) : undefined),
    [project.dataTypes, userTableId],
  );

  const setFieldId = useCallback(
    (fieldName: UserListField) => (val: number) => {
      if (USER_NAME_FIELDS.includes(fieldName)) {
        const field = userTable?.fields.getById(val);
        if (
          field?.type === OBJECT &&
          field.typeOptions?.format === 'fullName'
        ) {
          onChange([`firstNameFieldId`], val);
          onChange([`lastNameFieldId`], undefined);
          return;
        }
      }
      onChange([`${fieldName}FieldId`], val);
    },
    [onChange, userTable?.fields],
  );

  const isUsingFullName = useMemo(
    () =>
      !!userTable &&
      userTable.fields.getById(value.firstNameFieldId)?.type === OBJECT &&
      userTable.fields.getById(value.firstNameFieldId)?.typeOptions?.format ===
        'fullName',
    [userTable, value.firstNameFieldId],
  );

  const {
    isValid: isDisplayNameValid,
    validationMessage: nameValidationMessage,
  } = useFieldNameValidation(
    value.display ?? '',
    (userTable?.name || '') as DataFieldType,
    userType,
    project.dataTypes,
  );

  const userFieldOptions = useMemo(() => {
    if (!userTable) {
      return [];
    }

    return withNullOption(
      userTable.fields
        .filter(
          (field) =>
            filterDefaultFields(field) &&
            !field.relationship &&
            !field.relatedField &&
            (field.type !== OBJECT || field.typeOptions?.format === 'fullName'),
        )
        .map((field) => ({
          value: field.id,
          label: <FieldOption field={field} />,
        })),
    );
  }, [userTable]);

  const userProfilePictureFieldOptions = useMemo(() => {
    if (!userTable) {
      return [];
    }

    return withNullOption(
      userTable.fields
        .filter((field) => field.type === FILE)
        .map((field) => ({
          value: field.id,
          label: <FieldOption field={field} />,
        })),
    );
  }, [userTable]);

  const userConditionsOptions = useMemo(() => {
    if (!userTable) {
      return [];
    }

    return userTable.fields
      .filter(
        (field) =>
          filterDefaultFields(field) &&
          !field.relationship &&
          !field.relatedField &&
          field.type !== DATE,
      )
      .map((field: any) => ({
        value: new StateItem({
          id: RECORD_SCOPE,
          path: field.name,
          dataType: field.type,
          display: field.display,
          source: DATABASE,
        }),

        plainLabel: field.display,
        label: <FieldOption field={field} />,
      }));
  }, [userTable]);

  const conditionalRolesAreValid = useMemo(
    () =>
      !value.conditionalRoles ||
      value.conditionalRoles.every(
        (conditionalRole: any) =>
          conditionalRole.roleId &&
          conditionsAreValid(conditionalRole.conditions),
      ),
    [value.conditionalRoles],
  );

  const isValid = useMemo(
    () =>
      (isDisplayNameValid || isUpdate) &&
      userTableId &&
      value.emailFieldId &&
      conditionalRolesAreValid,
    [
      isDisplayNameValid,
      isUpdate,
      userTableId,
      conditionalRolesAreValid,
      value.emailFieldId,
    ],
  );

  const refetchUserDataType = useCallback(
    () =>
      getDataType({
        variables: { projectId: project.name, id: userDataTypeId },
      }).then(({ data }: any) => {
        if (data && data.dataType) {
          updateDataType(
            addRelatedFieldsToDataType(data.dataType, project.dataTypes),
          );
        }
      }),
    [
      getDataType,
      project.dataTypes,
      project.name,
      updateDataType,
      userDataTypeId,
    ],
  );

  const saveUserList = useCallback(() => {
    if (isValid) {
      setSaving(true);
      mutateUserList({
        variables: {
          projectName: project.name,
          userList: {
            id: isUpdate ? value.id : undefined,
            display: value.display,
            dataTypeId: value.dataTypeId,
            emailFieldId: value.emailFieldId,
            firstNameFieldId: value.firstNameFieldId,
            lastNameFieldId: value.lastNameFieldId,
            profilePictureFieldId: value.profilePictureFieldId,
            companyFieldId: value.companyFieldId,
            paymentsIdFieldId: value.paymentsIdFieldId,
            fileSharingIdFieldId: value.fileSharingIdFieldId,
            defaultRoleId: parseInt(value.defaultRoleId, 10),
            conditionalInclude: value.conditionalInclude,
            conditionalRoles:
              value.conditionalRoles &&
              value.conditionalRoles.map((conditionalRole: any) => ({
                ...conditionalRole,
                _id: undefined,
                __typename: undefined,
              })),
          },
        },
      })
        .then(({ data }) => {
          if (!isUpdate) {
            const newUserList = data.addUserList;
            try {
              const existingUserListData = client.readQuery({
                query: GET_USER_LISTS,
                variables: { projectId: project.name },
              });
              if (existingUserListData) {
                const existingUserLists = existingUserListData.userLists;
                const newLists = [newUserList, ...existingUserLists];
                client.writeQuery({
                  data: { userLists: newLists },
                  query: GET_USER_LISTS,
                  variables: { projectId: project.name },
                });
              }
            } catch (e) {
              console.log(e);
            }
          }

          const updatedDataType = get(data, [
            isUpdate ? 'updateUserList' : 'addUserList',
            'dataType',
          ]);

          if (updatedDataType) {
            updateDataType(
              addRelatedFieldsToDataType(updatedDataType, project.dataTypes),
            );
          }
        })
        .then(refetchUserDataType)
        .then(() => {
          successAlert(
            getText(LANG_KEY, 'userTable.saved.title'),
            getText(LANG_KEY, 'userTable.saved.description'),
          );
          onClose();
          setSaving(false);
          if (setImportStatus) {
            setImportStatus({
              error: false,
              pending: false,
            });
          }
        })
        .catch((e) => {
          console.log('e', e);
          errorAlert(getText(LANG_KEY, 'userTable.error'), e);
          setSaving(false);
          if (setImportStatus) {
            setImportStatus({
              error: true,
              pending: false,
            });
          }
        });
    }
  }, [
    client,
    errorAlert,
    isUpdate,
    isValid,
    mutateUserList,
    onClose,
    project.dataTypes,
    project.name,
    refetchUserDataType,
    setImportStatus,
    successAlert,
    updateDataType,
    value.companyFieldId,
    value.conditionalInclude,
    value.conditionalRoles,
    value.dataTypeId,
    value.defaultRoleId,
    value.display,
    value.emailFieldId,
    value.fileSharingIdFieldId,
    value.firstNameFieldId,
    value.id,
    value.lastNameFieldId,
    value.paymentsIdFieldId,
    value.profilePictureFieldId,
  ]);

  const handleConfirmDelete = useCallback(() => {
    return deleteUserList({
      variables: {
        projectName: project.name,
        id: value.id,
        dataTypeId: value.dataTypeId,
      },
    })
      .then(() => {
        try {
          const existingUserListData = client.readQuery({
            query: GET_USER_LISTS,
            variables: { projectId: project.name },
          });
          if (existingUserListData) {
            const existingUserLists = existingUserListData.userLists;
            const newLists = existingUserLists.filter(
              (list: any) => list.id !== value.id,
            );
            client.writeQuery({
              data: { userLists: newLists },
              query: GET_USER_LISTS,
              variables: { projectId: project.name },
            });
          }
        } catch (e) {
          console.log(e);
        }
      })
      .then(refetchUserDataType)
      .then(() => {
        onClose();
      })
      .catch((error) => {
        errorAlert(getText(LANG_KEY, 'userTable.delete.error'), error);
      });
  }, [
    client,
    deleteUserList,
    errorAlert,
    onClose,
    project.name,
    refetchUserDataType,
    value.dataTypeId,
    value.id,
  ]);

  return (
    <Modal
      additionalButtons={
        isUpdate && (
          // @ts-expect-error TS(2322): Type '{ children: string; variant: string; confirm... Remove this comment to see the full error message
          <ConfirmationButton
            variant="danger"
            confirmText={getText(LANG_KEY, 'userTable.delete.confirm')}
            description={getText(LANG_KEY, 'userTable.delete.description')}
            title={getText(LANG_KEY, 'userTable.delete.title')}
            icon={<IconTrash size={16} />}
            onConfirm={handleConfirmDelete}
          >
            {getText(LANG_KEY, 'userTable.delete.button')}
          </ConfirmationButton>
        )
      }
      loading={loading || saving}
      title={getText(LANG_KEY, 'userTable', value.id ? 'editTitle' : 'title')}
      onCancel={onClose}
      onClose={onClose}
      onConfirm={saveUserList}
      closeOnOutsideClick={false}
      confirmText={getText(LANG_KEY, 'save')}
      confirmDisabled={!isValid || saving || loading}
      size="lg"
    >
      <div className="flex flex-grow flex-col pb-4">
        <p className="mb-4">{getText(LANG_KEY, 'userTable.description')}</p>
        <FormField
          aria-label="display-name"
          name="user-list-display-name"
          type="text"
          onChange={setDisplayName}
          readOnly={isUpdate}
          required
          errorMessage={
            !nameValidationMessage || value.id ? null : (
              <span className="text-white">{nameValidationMessage}</span>
            )
          }
          errorType="below-solid"
          label={getText(LANG_KEY, 'userTable.name.label')}
          help={getText(LANG_KEY, 'userTable.name.help')}
          suffix={getText(LANG_KEY, 'userTable.name.suffix')}
          placeholder={getText(
            {
              dataSource:
                userTable?.source.display ||
                getText(LANG_KEY, 'userTable.name.exampleTableDisplay'),
            },
            LANG_KEY,
            'userTable.name.placeholder',
          )}
          value={value.display}
          surface={LIGHT}
        />
        <Label surface={LIGHT} className="mb-2 mt-6 w-full">
          <span>{getText(LANG_KEY, 'userTable.linkField.label')}</span>
        </Label>
        <div className="flex w-full items-center rounded-lg border bg-gray-100 px-3 py-1.5 text-gray-800">
          <span>{getText(LANG_KEY, 'userTable.linkField.user')}</span>
          <IconArrowNarrowRight size={16} className="mx-2" />
          <span>
            {!value.display || (nameValidationMessage && !value.id)
              ? getText(
                  {
                    dataSource:
                      userTable?.source.display ||
                      getText(LANG_KEY, 'userTable.name.exampleTableDisplay'),
                  },
                  LANG_KEY,
                  'userTable.name.placeholder',
                )
              : value.display}
          </span>
        </div>
        <Label surface={LIGHT} className="mb-2 mt-6 w-full">
          {getText(LANG_KEY, 'userTable.table.label')}
        </Label>
        <SelectInput
          className="mb-4"
          onChange={setUserTableId}
          options={tableOptions}
          name="user-list-source"
          disabled={isUpdate}
          placeholder={getText(LANG_KEY, 'userTable.table.placeholder')}
          value={userTableId}
          surface={LIGHT}
          searchable={true}
        />
        {userTable &&
          USER_FIELDS.map((fieldName) => (
            <div className="mt-4 flex items-center" key={fieldName}>
              <Label surface={LIGHT} className="w-40 flex-shrink-0">
                {getText(LANG_KEY, 'userTable.fields', fieldName, 'label')}
              </Label>
              <Tooltip
                content={getText(LANG_KEY, 'userTable.fields', 'usingFullName')}
                disabled={!isUsingFullName || fieldName !== 'lastName'}
              >
                <SelectInput
                  className="ml-4 w-64"
                  onChange={setFieldId(fieldName)}
                  options={
                    fieldName === PROFILE_PICTURE
                      ? userProfilePictureFieldOptions
                      : userFieldOptions
                  }
                  disabled={isUsingFullName && fieldName === 'lastName'}
                  name="user-list-source"
                  placeholder={getText(
                    LANG_KEY,
                    'userTable.fields',
                    fieldName,
                    'placeholder',
                  )}
                  searchable={true}
                  surface={LIGHT}
                  value={get(value, `${fieldName}FieldId`)}
                />
              </Tooltip>
            </div>
          ))}
        {userTableId && (
          <>
            <div className="mt-6 flex">
              <Label surface={LIGHT} className="mb-2 w-full">
                {getText(LANG_KEY, 'userTable.filter.label')}
              </Label>
              <Switch
                className="my-auto ml-auto"
                onChange={(value: boolean) => {
                  if (value) {
                    onChange(['conditionalInclude'], null);
                  } else {
                    onChange(['conditionalInclude'], {
                      conditions: [
                        [{ field: null, operator: null, value: null }],
                      ],
                    });
                  }
                }}
                value={!value.conditionalInclude}
              />
            </div>
            {!!value.conditionalInclude && (
              <div className="mt-2 w-full max-w-full">
                <p>{getText(LANG_KEY, 'userTable.filter.description')}</p>
                <div className="mt-2 flex flex-col pl-8">
                  <ConditionsEditor
                    inline={true}
                    dataType={userTable}
                    fieldOptions={userConditionsOptions}
                    updateConditions={updateConditionalInclude}
                    rules={value.conditionalInclude.conditions}
                    project={project}
                    ValueInput={BackendConditionValueEditor}
                  />
                </div>
              </div>
            )}
          </>
        )}
        <hr className="my-4 border-gray-200" />
        <div className="flex items-center">
          <div className="flex w-40 items-center">
            <Label surface={LIGHT} className="mr-2 flex-shrink-0">
              {getText(LANG_KEY, 'userTable.defaultRole.label')}
            </Label>
            <HelpTooltip className="text-gray-500 hover:text-gray-800">
              {getText(LANG_KEY, 'userTable.defaultRole.help')}
            </HelpTooltip>
          </div>
          <SelectInput
            className="ml-4 w-64"
            onChange={setDefaultRole}
            options={roleOptions}
            name="user-list-source"
            placeholder={getText(LANG_KEY, 'userTable.defaultRole.placeholder')}
            searchable={true}
            value={value.defaultRoleId}
            surface={LIGHT}
          />
        </div>
        <hr className="my-4 border-gray-200" />
        {conditionalRolesEnabled &&
          value.conditionalRoles &&
          userTable &&
          value.conditionalRoles.map(
            (conditionalRole: any, conditionalRoleIndex: any) => (
              <React.Fragment key={conditionalRole.id || conditionalRole._id}>
                <div className="flex pb-5">
                  <IconInfoCircle size={16} className="m-1" />
                  <Label surface={LIGHT} className="mb-0 mt-0.5 pl-1">
                    {getText(
                      LANG_KEY,
                      'userTable.conditionalRole.noAdminChangesExplainer',
                    )}
                  </Label>
                </div>
                <div className="flex items-center">
                  <div className="flex w-40 items-center">
                    <Label surface={LIGHT} className="mr-2 flex-shrink-0">
                      {getText(LANG_KEY, 'userTable.conditionalRole.role')}
                    </Label>
                    <HelpTooltip className="text-gray-500 hover:text-gray-800">
                      {getText(LANG_KEY, 'userTable.conditionalRole.help')}
                    </HelpTooltip>
                  </div>
                  <SelectInput
                    className="ml-4 w-64"
                    onChange={updateConditionalRole(conditionalRoleIndex)}
                    options={roleOptions}
                    name="user-list-source"
                    placeholder={getText(
                      LANG_KEY,
                      'userTable.conditionalRole.placeholder',
                    )}
                    searchable={true}
                    value={conditionalRole.roleId}
                    surface={LIGHT}
                  />
                  <button
                    className="ml-auto p-1 opacity-75 hover:opacity-100"
                    onClick={deleteConditionalRole(conditionalRoleIndex)}
                  >
                    <IconTrash size={16} />
                  </button>
                </div>
                <div className="mt-6 flex items-center">
                  <Label surface={LIGHT} className="w-40 flex-shrink-0">
                    {getText(LANG_KEY, 'userTable.conditionalRole.conditions')}
                  </Label>
                </div>
                <div className="mt-2 flex w-full max-w-full flex-col pl-8">
                  <ConditionsEditor
                    inline={true}
                    dataType={userTable}
                    fieldOptions={userConditionsOptions}
                    updateConditions={updateConditions(conditionalRoleIndex)}
                    rules={conditionalRole.conditions}
                    project={project}
                    ValueInput={BackendConditionValueEditor}
                  />
                </div>
                <hr className="my-4 border-gray-200" />
              </React.Fragment>
            ),
          )}
        <div className="flex items-center">
          <FeatureLockedButton
            variant="secondary"
            className="flex items-center text-gray-800"
            feature={CONDITIONAL_USER_LIST_ROLES}
            iconClassName="text-gray-500"
            onClick={addConditionalRole}
          >
            <IconPlus size={16} className="mr-2 opacity-75" />
            <span>{getText(LANG_KEY, 'userTable.conditionalRole.add')}</span>
          </FeatureLockedButton>
        </div>
      </div>
    </Modal>
  );
};

UserListItemForm.defaultProps = {};

export default UserListItemForm;
