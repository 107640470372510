import React, { useMemo } from 'react';
import { IconCheck } from '@tabler/icons-react';
import { DateTime } from 'luxon';
import { Badge, Tooltip } from '@noloco/components';
import { ProjectUserRecord } from '@noloco/core/src/models/Project';
import { User } from '@noloco/core/src/models/User';
import { getText } from '@noloco/core/src/utils/lang';
import { isBuilder } from '@noloco/core/src/utils/user';

const LANG_KEY = 'userTable';

const UserActiveCell = ({
  user,
  builders,
}: {
  user: User;
  builders: Record<string, ProjectUserRecord>;
}) => {
  const userIsBuilder = useMemo(
    () => isBuilder(user) && builders[user.email],
    [builders, user],
  );

  const isActiveBuilder = userIsBuilder && !!builders[user.email]?.createdAt;

  const lastActiveText = useMemo(
    () =>
      user.lastActiveAt
        ? getText(
            {
              dateTime: DateTime.fromISO(
                user.lastActiveAt as string,
              ).toLocaleString(DateTime.DATETIME_MED),
            },
            LANG_KEY,
            'fields.lastActive',
          )
        : null,
    [user.lastActiveAt],
  );

  if (user.deactivatedAt) {
    return (
      <Tooltip
        content={getText(
          { deactivatedAt: user.deactivatedAt.toLocaleString() },
          LANG_KEY,
          'deactivated.tooltip',
        )}
      >
        <div className="flex w-full">
          <Badge className="mx-auto" color="gray">
            {getText(LANG_KEY, 'deactivated.badge')}
          </Badge>
        </div>
      </Tooltip>
    );
  }

  if ((!userIsBuilder || isActiveBuilder) && user.lastActiveAt) {
    return (
      <Tooltip content={lastActiveText}>
        <div className="flex items-center">
          <IconCheck className="mx-auto" size={20} />
        </div>
      </Tooltip>
    );
  }

  if (userIsBuilder) {
    return (
      <Tooltip
        content={
          <div className="flex flex-col space-y-2">
            <span>{getText(LANG_KEY, 'fields.pendingInvite.tooltip')}</span>
            {lastActiveText && <span>{lastActiveText}</span>}
          </div>
        }
      >
        <Badge color="pink">
          {getText(LANG_KEY, 'fields.pendingInvite.badge')}
        </Badge>
      </Tooltip>
    );
  }

  return null;
};

export default UserActiveCell;
