import React, { useCallback, useMemo, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import * as Icons from '@tabler/icons-react';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
import { BasePopover, Button, Surface, TextInput } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import { SM } from '@noloco/components/src/constants/tShirtSizes';
import { SECONDARY } from '@noloco/components/src/constants/variants';
import { darkModeColors } from '../../constants/darkModeColors';
import Icon from '../../elements/Icon';
import { ElementPath } from '../../models/Element';
import useDarkMode from '../../utils/hooks/useDarkMode';
import { getText } from '../../utils/lang';

type IconEditorProps = {
  clearable?: boolean;
  customInput?: JSX.Element;
  elementProps: { name: string };
  inline?: boolean;
  placement: 'bottom' | 'right';
  iconEditorIsOpen?: boolean;
  setIconEditorIsOpen?: (iconEditorIsOpen: boolean) => void;
  surface: Surface;
  updateProperty: (path: ElementPath, icon: string | null) => void;
};

const IconEditor = ({
  clearable,
  customInput,
  elementProps,
  inline,
  placement,
  iconEditorIsOpen = false,
  setIconEditorIsOpen,
  surface,
  updateProperty,
}: IconEditorProps) => {
  const { name } = elementProps || {};
  const [filter, setFilter] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isDarkModeEnabled] = useDarkMode();
  const searchIconsByNameLookup = useMemo(
    () =>
      Object.keys(Icons).reduce((acc: Record<string, string>, iconName) => {
        if (iconName === 'createReactComponent') {
          // Skips the helper function exported by the library
          return acc;
        }

        acc[iconName.toLowerCase()] = iconName.replace('Icon', '');
        return acc;
      }, {}),
    [],
  );

  const onFilterChange = useCallback(
    ({ target: { value } }) => setFilter(value),
    [],
  );

  const onChange = useCallback(
    (icon) => {
      updateProperty(['name'], icon);
      setOpen(false);
    },
    [updateProperty],
  );

  const searchString = useMemo(
    () => filter?.toLowerCase().trim().replace(/\s/g, ''),
    [filter],
  );
  const icons = useMemo(
    () => (
      <div className="flex-col">
        {clearable && customInput && name && (
          <div className="mb-2 w-full border-b border-gray-600 pb-2">
            <Button
              className="h-8 w-full"
              onClick={() => onChange(null)}
              size={SM}
              variant={SECONDARY}
            >
              {getText('leftSidebar.layers.removeIcon')}
            </Button>
          </div>
        )}
        <TextInput
          autoFocus={true}
          value={filter}
          onChange={onFilterChange}
          placeholder={getText('elements.ICON.filter')}
          surface={surface}
          clearable={true}
        />
        {/* @ts-expect-error TS2786: 'SimpleBar' cannot be used as a JSX component. */}
        <SimpleBar autoHide={true} className="mt-2 h-72 overflow-y-auto">
          <div className="flex flex-wrap">
            {Object.entries(searchIconsByNameLookup)
              .filter(
                ([searchableName]) =>
                  !filter || searchableName.includes(searchString),
              )
              .map(([_, iconName]) => (
                <div
                  className={classNames(
                    'my-1 flex h-10 w-1/5 items-center justify-center rounded-md bg-opacity-25 p-2',
                    surface === DARK
                      ? 'text-white hover:bg-gray-800'
                      : `${
                          isDarkModeEnabled
                            ? `${darkModeColors.text.primary} hover:bg-${darkModeColors.surfaces.elevation2LiteralColor}`
                            : 'text-gray-800 hover:bg-gray-200'
                        }`,
                  )}
                  key={iconName}
                  onClick={() => onChange(iconName)}
                >
                  <Icon icon={{ name: iconName }} size={10} />
                </div>
              ))}
          </div>
        </SimpleBar>
      </div>
    ),
    [
      clearable,
      customInput,
      filter,
      isDarkModeEnabled,
      name,
      onChange,
      onFilterChange,
      searchIconsByNameLookup,
      searchString,
      surface,
    ],
  );

  const input = useMemo(
    () => (
      <div
        className={classNames(
          'relative cursor-pointer rounded-lg px-3 py-1.5',
          surface === DARK
            ? 'text-brand-lightest bg-slate-700'
            : `border ${
                isDarkModeEnabled
                  ? `${darkModeColors.text.primary} ${darkModeColors.borders.one} ${darkModeColors.surfaces.elevation2LiteralColor}`
                  : 'border-gray-300 bg-white text-black'
              }`,
        )}
      >
        {name && (
          <div className="flex items-center">
            <Icon icon={{ name }} className="mr-2 h-6 w-6" />
            <span>{name}</span>
          </div>
        )}
        {!name && (
          <span className={classNames('text-gray-300')}>
            {getText('elements.ICON.placeholder')}
          </span>
        )}
        {name && clearable && (
          <button
            onClick={(event) => {
              event.stopPropagation();
              onChange(null);
            }}
            className="absolute bottom-0 right-4 top-0 flex items-center justify-center text-gray-500 opacity-50 hover:opacity-100"
          >
            <IconX size={16} />
          </button>
        )}
      </div>
    ),
    [surface, isDarkModeEnabled, name, clearable, onChange],
  );

  if (inline) {
    return (
      <div className="flex flex-col">
        <div className="mb-2" onClick={() => setOpen(!isOpen)}>
          {input}
        </div>
        {isOpen && icons}
      </div>
    );
  }

  return (
    // @ts-expect-error TS(2322): Type '{ children: Element; closeOnOutsideClick: tr... Remove this comment to see the full error message
    <BasePopover
      closeOnOutsideClick={true}
      content={
        <div
          className={classNames(
            'flex-col rounded border p-4',
            surface === DARK
              ? customInput
                ? 'border-gray-600 bg-gray-900 text-gray-200'
                : 'bg-brand border-brand-light text-brand-lightest'
              : 'border-gray-100 bg-white text-black',
          )}
        >
          {icons}
        </div>
      }
      placement={placement}
      surface={surface}
      trigger="click"
      showArrow={placement !== 'bottom'}
      isOpen={isOpen || iconEditorIsOpen}
      onOpenChange={(isOpen) => {
        if (setIconEditorIsOpen) {
          setIconEditorIsOpen(isOpen);
        }
      }}
    >
      {customInput || input}
    </BasePopover>
  );
};

IconEditor.defaultProps = {
  inline: false,
  placement: 'bottom',
  surface: DARK,
};

export default IconEditor;
