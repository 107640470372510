import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FREE } from '../../constants/accountPlans';
import { billingPlanSelector } from '../../selectors/billingPlanSelectors';

const useIsTrialExpired = () => {
  const billingPlan = useSelector(billingPlanSelector);

  const isExpired = useMemo(
    () =>
      billingPlan &&
      billingPlan.type === FREE &&
      billingPlan.trialEnd &&
      new Date(billingPlan.trialEnd) < new Date(),
    [billingPlan],
  );

  return isExpired;
};

export default useIsTrialExpired;
