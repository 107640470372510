import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import {
  BOARD,
  CARDS,
  COLUMNS,
  ROWS,
  SPLIT,
  TABLE,
  TABLE_FULL,
} from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import '../../../utils/data';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import useDraggableCollectionGroups from '../../../utils/hooks/useDraggableCollectionGroups';
import { getText } from '../../../utils/lang';
import { COLLECTION_WRAPPER_STYLES } from '../Collection';
import DraggableRow from './DraggableRow';
import DroppableGroup from './DroppableBoardGroup';
import GroupHeader from './layouts/GroupHeader';

export const GROUP_WRAPPER_STYLES = {
  [ROWS]: (): string => 'flex flex-col w-full flex-shrink-0',
  [CARDS]: (): string =>
    'flex flex-col w-full col-span-3 md:col-span-1 flex-shrink-0 pb-8',
  [COLUMNS]: (): string => 'flex flex-col w-full pb-8',
  [BOARD]: (): string => 'flex flex-col w-full max-w-xs h-full flex-shrink-0',
  [TABLE]: (isDarkModeEnabled = false): string =>
    `w-full divide-y  ${
      isDarkModeEnabled ? darkModeColors.divides.two : 'divide-gray-200'
    }`,
  [TABLE_FULL]: (isDarkModeEnabled = false): string =>
    `w-full divide-y  ${
      isDarkModeEnabled ? darkModeColors.divides.two : 'divide-gray-200'
    }`,
  [SPLIT]: (): string => 'flex flex-col w-full flex-shrink-0',
};

export const RECORDS_WRAPPER_STYLES = {
  [ROWS]: (isDarkModeEnabled = false): string =>
    `divide-y ${
      isDarkModeEnabled ? darkModeColors.divides.one : 'divide-gray-200'
    }`,
  [CARDS]: (): string => COLLECTION_WRAPPER_STYLES[CARDS](),
  [COLUMNS]: (): string => COLLECTION_WRAPPER_STYLES[COLUMNS](),
  [BOARD]: (): string =>
    'mt-4 space-y-2 flex flex-col min-h-96 h-full overflow-auto',
  [TABLE]: (): string => '',
  [TABLE_FULL]: (): string => '',
  [SPLIT]: (isDarkModeEnabled = false): string =>
    `divide-y ${
      isDarkModeEnabled ? darkModeColors.divides.one : 'divide-gray-200'
    }`,
};

const BASE_EMPTY_STATE_STYLES = (isDarkModeEnabled = false) =>
  `p-2 text-xs rounded-lg text-center font-medium uppercase tracking-wider hover:shadow-xs border ${
    isDarkModeEnabled
      ? `${darkModeColors.surfaces.elevation1} ${darkModeColors.borders.one} ${darkModeColors.text.secondary}`
      : 'bg-white text-gray-600'
  }`;

export const EMPTY_STATE_STYLES = {
  [ROWS]: (isDarkModeEnabled = false): string =>
    `text-xs font-medium uppercase tracking-wider text-center p-6 ${
      isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-600'
    }`,
  [CARDS]: (isDarkModeEnabled = false): string =>
    `${BASE_EMPTY_STATE_STYLES(
      isDarkModeEnabled,
    )} col-span-3 md:col-span-1 py-5`,
  [COLUMNS]: (isDarkModeEnabled = false): string =>
    `${BASE_EMPTY_STATE_STYLES(isDarkModeEnabled)} w-full py-5`,
  [BOARD]: (isDarkModeEnabled = false): string =>
    `${BASE_EMPTY_STATE_STYLES(isDarkModeEnabled)} mb-8`,
  [TABLE]: (isDarkModeEnabled = false): string =>
    `text-xs font-medium uppercase tracking-wider ${
      isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-600 '
    }`,
  [TABLE_FULL]: (isDarkModeEnabled = false): string =>
    `text-xs font-medium uppercase tracking-wider ${
      isDarkModeEnabled ? darkModeColors.text.secondary : ' text-gray-600'
    }`,
  [SPLIT]: (isDarkModeEnabled = false): string =>
    `text-xs font-medium uppercase tracking-wider text-center p-6 ${
      isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-600'
    }`,
};

const DraggableGroupView = ({
  customFilters,
  dataType,
  edges,
  elementId,
  fields,
  groupByFields,
  groupOptions,
  hideEmptyGroups,
  layout,
  limitPerGroup,
  nodeQueryObject,
  Row,
  enableDragAndDropEdit,
  theme,
  bulkActionsEnabled,
  selectedRows,
  setSelectedRows,
  className,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();
  const {
    canUpdateViaDrag,
    groupOpenStates,
    groupCollapsedStates,
    toggleGroupState,
    toggleGroupCollapsedState,
    handleCardDrop,
    isLimited,
    firstSummaryIndex,
    visibleGroups,
  } = useDraggableCollectionGroups({
    customFilters,
    dataType,
    edges,
    elementId,
    fields,
    groupByFields,
    groupOptions,
    hideEmptyGroups,
    layout,
    limitPerGroup,
    nodeQueryObject,
    enableDragAndDropEdit,
  });

  return (
    <>
      {visibleGroups.map((group: any) => (
        <DroppableGroup
          key={group.key}
          group={group}
          onDrop={handleCardDrop}
          className={classNames(
            className,
            GROUP_WRAPPER_STYLES[layout](isDarkModeEnabled),
          )}
          dataTestid="collection-group"
        >
          {({ isOver }: any) => (
            <>
              <GroupHeader
                bulkActionsEnabled={bulkActionsEnabled}
                group={group}
                isTable={false}
                isCollapsed={!!groupCollapsedStates[group.key]}
                fields={fields}
                firstSummaryIndex={firstSummaryIndex}
                layout={layout}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                toggleGroupCollapsedState={toggleGroupCollapsedState}
              />
              {(!groupCollapsedStates[group.id] || layout === BOARD) && (
                <div
                  className={classNames(
                    RECORDS_WRAPPER_STYLES[layout](
                      isDarkModeEnabled as boolean,
                    ),
                    {
                      [`ring ring-${theme.brandColorGroups.primary}-500`]:
                        isOver,
                      'rounded-lg':
                        isOver &&
                        (layout === CARDS ||
                          layout === BOARD ||
                          layout === COLUMNS),
                      'm-1':
                        isOver &&
                        (layout === COLUMNS ||
                          layout === ROWS ||
                          layout === SPLIT),
                    },
                  )}
                >
                  {group.rows
                    .slice(
                      0,
                      !groupOpenStates[group.key] && isLimited
                        ? limitPerGroup
                        : undefined,
                    )
                    .map((edge: any) => (
                      <DraggableRow
                        canUpdateViaDrag={canUpdateViaDrag}
                        edge={edge}
                        key={edge.node.id}
                        group={group}
                        isOver={isOver}
                        isTable={false}
                        layout={layout}
                        Row={Row}
                      />
                    ))}
                  {isLimited && group.rows.length > limitPerGroup && (
                    <div className="sticky bottom-0 flex w-full">
                      <button
                        className="hover:shadow-xs mx-2 mb-4 w-full rounded-lg border bg-white p-2 text-center text-xs font-medium uppercase tracking-wider text-gray-600 shadow-lg hover:text-gray-800"
                        onClick={toggleGroupState(group.key)}
                      >
                        {getText(
                          'core.COLLECTION.groups.limit',
                          groupOpenStates[group.key] ? 'showLess' : 'showAll',
                        )}
                      </button>
                    </div>
                  )}
                  {group.rows.length === 0 && (
                    <>
                      <div
                        className={classNames(
                          EMPTY_STATE_STYLES[layout](isDarkModeEnabled),
                        )}
                      >
                        <span>{getText('core.COLLECTION.groups.empty')}</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </DroppableGroup>
      ))}
    </>
  );
};

export default withTheme(DraggableGroupView);
