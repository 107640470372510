import React, { useMemo } from 'react';
import classNames from 'classnames';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { COUNT } from '../../../constants/aggregationTypes';
import { darkModeColors } from '../../../constants/darkModeColors';
import { aggregateData } from '../../../utils/aggregationDataTypes';
import useDarkMode from '../../../utils/hooks/useDarkMode';
import { getText } from '../../../utils/lang';
import { formatValue } from './FieldCell';

const CollectionColumnSummaryCell = ({
  className,
  config,
  parent,
  field,
  rows,
}: any) => {
  const [isDarkModeEnabled] = useDarkMode();
  const shouldRender = config.groupSummary && rows.length > 0;

  const summaryValue = useMemo(() => {
    if (!shouldRender) {
      return null;
    }
    const valuePath = [
      'node',
      ...(parent ? [parent.apiName] : []),
      field.apiName,
    ];
    const groupData = rows
      .map((edge: any) => get(edge, valuePath))
      .filter((val: any) => !isNil(val));

    const summaryValue = aggregateData(
      groupData,
      config.groupSummary,
      field.type,
    );

    if (summaryValue === null) {
      return null;
    }

    if (config.groupSummary === COUNT) {
      return summaryValue;
    }

    return formatValue(summaryValue, field, config);
  }, [config, field, parent, rows, shouldRender]);

  if (isNil(summaryValue)) {
    return <td />;
  }

  return (
    <td
      className={classNames(
        className,
        'whitespace-pre-wrap px-3 text-right text-xs font-normal',
      )}
    >
      <span
        className={`${
          isDarkModeEnabled ? darkModeColors.text.secondary : 'text-gray-500'
        } mr-1`}
      >
        <small>
          {getText(
            'elements.VIEW.fields.groupSummary.summaries',
            config.groupSummary,
          )}
        </small>
      </span>
      <span>{summaryValue}</span>
    </td>
  );
};

export default CollectionColumnSummaryCell;
