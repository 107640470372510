import React from 'react';
import ErrorText from '../form/ErrorText';

const SimpleLayout = ({
  errors,
  logoUrl,
  children,
  onSubmit,
  subTitleText,
  titleText,
}: any) => (
  <div className="flex w-full justify-center px-6 py-7 sm:px-0">
    <div className="w-full max-w-sm">
      <div>
        {logoUrl && (
          <img className="mx-auto h-10 w-auto" src={logoUrl} alt="logo" />
        )}
        <h2 className="mt-4 text-center text-xl font-extrabold leading-9 text-gray-900">
          {titleText}
        </h2>
        {subTitleText && (
          <p className="mt-1.5 text-center text-sm leading-5 text-gray-600 sm:px-2">
            {subTitleText}
          </p>
        )}
      </div>
      {errors && errors.length > 0 && (
        <ErrorText className="mt-6" type="below-solid">
          {errors.map((error: any) => (
            <div className="block" key={error}>
              {error}
            </div>
          ))}
        </ErrorText>
      )}
      <form className="mt-6" action="#" onSubmit={onSubmit}>
        {children}
      </form>
    </div>
  </div>
);

export default SimpleLayout;
