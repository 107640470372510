import React, { memo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import useScopeVariables from '../../../utils/hooks/useScopeVariables';
import Link from '../../Link';
import CollectionCardGallery from './CollectionCardGallery';

const CollectionCards = memo(
  ({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type '{}'.
    index,
    // @ts-expect-error TS(2339): Property 'additionalElementsRenderer' does not exi... Remove this comment to see the full error message
    additionalElementsRenderer,
    // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type '{}'.
    editorMode,
    // @ts-expect-error TS(2339): Property 'element' does not exist on type '{}'.
    element,
    // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
    elementPath,
    // @ts-expect-error TS(2339): Property 'theme' does not exist on type '{}'.
    theme,
    // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
    scope,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'rowLink' does not exist on type '{}'.
    rowLink,
    // @ts-expect-error TS(2339): Property 'isView' does not exist on type '{}'.
    isView,
    // @ts-expect-error TS(2339): Property 'rawVariables' does not exist on type '{}... Remove this comment to see the full error message
    rawVariables,
  }) => {
    const primaryColor = theme.brandColorGroups.primary;
    const { images } = rawVariables || {};
    const {
      link = rowLink,
      variables: { title, image, description, secondaryText } = rawVariables,
    } = useScopeVariables(scope, element, project, elementPath);
    const linkValue = rowLink || link;

    return (
      <Link
        className={classNames(
          'mb-4 flex h-full w-full flex-col items-center overflow-hidden rounded-lg border border-gray-200 bg-white shadow-lg sm:flex-wrap',
          {
            'hover:bg-gray-100': link.to || link.href !== '#',
          },
        )}
        // @ts-expect-error TS(2322): Type '{ children: Element; className: string; edit... Remove this comment to see the full error message
        editorMode={editorMode}
        link={linkValue}
        scope={scope}
      >
        <>
          {/* @ts-expect-error TS(2786): 'CollectionCardGallery' cannot be used as a JSX co... Remove this comment to see the full error message */}
          <CollectionCardGallery
            image={image}
            images={images}
            index={index}
            isView={isView}
          />
          {!isView && (
            <div className="flex w-full min-w-0 max-w-full flex-grow flex-col justify-center p-6">
              <Box
                is="span"
                className="text-lg font-medium"
                text={getColorShade(primaryColor, 800)}
              >
                {title.value}
              </Box>
              {!description.hidden && (
                <span className="whitespace-pre-wrap text-sm text-gray-400">
                  {description.value}
                </span>
              )}
            </div>
          )}
          {!isView && !secondaryText.hidden && (
            <div className="-mt-2 flex w-full min-w-0 flex-shrink flex-grow flex-col justify-center px-6 pb-6">
              <span>{secondaryText.label}</span>
              <span className="whitespace-pre-wrap text-sm text-gray-400">
                {secondaryText.value}
              </span>
            </div>
          )}
          {additionalElementsRenderer(
            scope,
            'w-full flex flex-col p-4 space-y-4 text-xs',
            'flex flex-col w-full mr-auto',
          )}
        </>
      </Link>
    );
  },
);

(CollectionCards as any).propTypes = {};

(CollectionCards as any).defaultProps = {
  additionalFields: [],
  index: 0,
};

export default withTheme(CollectionCards);
