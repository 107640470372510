import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { DataField } from '../../../models/DataTypeFields';
import { FormConfigWithField, FormFieldConfig } from '../../../models/View';
import { useAutoForm } from '../../../utils/hooks/useAutoForm';
import AutoFormSection from './AutoFormSection';

const DefaultHelpText = ({ config }: any) => (
  <span className="-mt-2 mb-2 whitespace-pre-wrap text-sm font-light tracking-wide">
    {config.helpText}
  </span>
);

type Props = {
  className?: string;
  children?: (props: {
    fieldsForValidation: { field: DataField; config: FormFieldConfig }[];
    hasChangedMap: Record<string, boolean>;
    disabled: boolean;
  }) => any;
  sectionFormat?: 'CARDS' | 'COMPACT';
};

const AutoForm = memo(({ className, children, sectionFormat }: Props) => {
  const {
    formFields,
    hasChangedMap,
    onSubmit,
    resolvedSectionsWithConditionsMet,
    fieldsWithHiddenSectionsRemoved,
    renderFormField,
    sections,
  } = useAutoForm();

  return (
    <form className={className} onSubmit={onSubmit}>
      {!sections && formFields.map((formField) => renderFormField(formField))}
      {sections &&
        resolvedSectionsWithConditionsMet.map(({ config, section }: any) => (
          <AutoFormSection
            config={config}
            fields={formFields}
            key={config.id}
            section={section}
            sectionFormat={sectionFormat}
          >
            {(formField: FormConfigWithField) => renderFormField(formField)}
          </AutoFormSection>
        ))}
      {children &&
        children({
          fieldsForValidation: fieldsWithHiddenSectionsRemoved,
          hasChangedMap,
          disabled: Object.values(hasChangedMap).filter(Boolean).length === 0,
        })}
    </form>
  );
});

(AutoForm as any).propTypes = {
  readOnly: PropTypes.bool,
};

(AutoForm as any).defaultProps = {
  submitOnBlur: false,
  inviteEnabled: true,
  Label: 'div',
  HelpText: DefaultHelpText,
  readOnly: false,
};

AutoForm.displayName = 'AutoForm';

export default AutoForm;
