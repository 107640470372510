export const SUPPORTED_PROTOCOLS = [
  'http://',
  'https://',
  'mailto:',
  'tel:',
  '#',
  '/',
];

export const COMMUNITY_URL = 'https://community.noloco.io';

export const WEBINARS_URL = 'https://noloco.io/webinars';

export const GUIDES_URL = 'https://guides.noloco.io';

export const YOUTUBE_CHANNEL_URL =
  'https://www.youtube.com/channel/UCHKb83uA6q6_otjSN2cpO8w';

export const ACADEMY_URL = 'https://academy.noloco.io/register';

export const EXPERT_URL = 'https://noloco.io/expert';
