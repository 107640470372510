import React, { memo } from 'react';
import { Box, withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import { getColorShade } from '@noloco/components';
import useScopeVariables from '../../../utils/hooks/useScopeVariables';
import { formatBg } from '../../../utils/styles';
import Link from '../../Link';

const CollectionRows = memo(
  ({
    // @ts-expect-error TS(2339): Property 'index' does not exist on type '{}'.
    index,
    // @ts-expect-error TS(2339): Property 'additionalElementsRenderer' does not exi... Remove this comment to see the full error message
    additionalElementsRenderer,
    // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type '{}'.
    editorMode,
    // @ts-expect-error TS(2339): Property 'element' does not exist on type '{}'.
    element,
    // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type '{}'... Remove this comment to see the full error message
    elementPath,
    // @ts-expect-error TS(2339): Property 'theme' does not exist on type '{}'.
    theme,
    // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
    scope,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'rowLink' does not exist on type '{}'.
    rowLink,
    // @ts-expect-error TS(2339): Property 'isView' does not exist on type '{}'.
    isView,
    // @ts-expect-error TS(2339): Property 'rawVariables' does not exist on type '{}... Remove this comment to see the full error message
    rawVariables,
  }) => {
    const secondaryColor = theme.brandColorGroups.secondary;
    const primaryColor = theme.brandColorGroups.primary;
    const {
      link = rowLink,
      variables: { title, image, description, secondaryText } = rawVariables,
    } = useScopeVariables(scope, element, project, elementPath);
    const linkValue = rowLink || link;
    const additionalElements = additionalElementsRenderer(
      scope,
      `w-full grid grid-cols-12 gap-x-6 gap-y-4 sm:pl-0 ${
        isView || image.hidden ? 'my-4' : 'mt-8 pl-20'
      }`,
      'flex flex-col max-w-full min-w-0',
    );

    return (
      // @ts-expect-error TS(2746): This JSX tag's 'children' prop expects a single ch... Remove this comment to see the full error message
      <Link
        className="w-full flex-col items-center px-6 py-3 hover:bg-gray-100 hover:bg-opacity-50"
        editorMode={editorMode}
        link={linkValue}
        scope={scope}
      >
        <div className="flex w-full items-center sm:flex-wrap">
          {!isView && (
            <div className="flex w-3/5 min-w-0 sm:w-full sm:flex-wrap">
              {!isView && !image.hidden && (
                <div className="sm:mx-auto sm:w-full">
                  <Box
                    is="div"
                    className={classNames(
                      'sm:rounded-0 h-16 w-16 rounded-full bg-cover bg-center text-white sm:mx-auto sm:mb-4 sm:h-32 sm:w-32',
                    )}
                    bg={getColorShade(secondaryColor, 400)}
                    style={formatBg(
                      image.value.src ||
                        `https://picsum.photos/id/${
                          (index % 10) + 1020
                        }/600/600`,
                    )}
                  >
                    <span className="block h-16 w-16 sm:h-32 sm:w-32" />
                  </Box>
                </div>
              )}
              <div className="flex min-w-0 max-w-full flex-shrink flex-grow flex-col justify-center pl-3 sm:w-full sm:pl-0">
                <Box
                  is="span"
                  className="text-lg font-medium"
                  text={getColorShade(primaryColor, 800)}
                >
                  {title.value}
                </Box>
                {!description.hidden && (
                  <span className="whitespace-pre-wrap text-sm text-gray-400">
                    {description.value}
                  </span>
                )}
              </div>
            </div>
          )}
          {!isView && !secondaryText.hidden && (
            <div className="flex w-2/3 min-w-0 flex-shrink flex-grow flex-col justify-center pl-6 sm:ml-0 sm:mt-3 sm:w-full sm:pl-0">
              <span>{secondaryText.label}</span>
              <span className="whitespace-pre-wrap text-sm text-gray-400">
                {secondaryText.value}
              </span>
            </div>
          )}
        </div>
        {additionalElements}
      </Link>
    );
  },
);

(CollectionRows as any).propTypes = {};

(CollectionRows as any).defaultProps = {
  index: 0,
};

export default withTheme(CollectionRows);
