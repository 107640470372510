import React, { Suspense, lazy, memo, useCallback, useMemo } from 'react';
import { withTheme } from '@darraghmckay/tailwind-react-ui';
import classNames from 'classnames';
import first from 'lodash/first';
import set from 'lodash/fp/set';
import get from 'lodash/get';
import identity from 'lodash/identity';
import isNil from 'lodash/isNil';
import { DateTime } from 'luxon';
import colors from 'tailwindcss/colors';
import { Loader, RatingInput } from '@noloco/components';
import { Aggregation, COUNT, SUM } from '../constants/aggregationTypes';
import {
  AREA,
  BAR,
  FUNNEL,
  GAUGE,
  LINE,
  PIE,
  RADAR,
  STACKED_BAR,
  STATISTIC,
} from '../constants/chartTypes';
import { ColorPalette } from '../constants/colorPalettes';
import { DATE, DECIMAL, INTEGER, SINGLE_OPTION } from '../constants/dataTypes';
import {
  DAY as DAY_FORMAT,
  MONTH_SHORT as MONTH_FORMAT,
  YEAR as YEAR_FORMAT,
} from '../constants/dateFormatOptions';
import { RATING } from '../constants/fieldFormats';
import { DESC } from '../constants/orderByDirections';
import PRIMITIVE_DATA_TYPES from '../constants/primitiveDataTypes';
import {
  AUTO,
  DAY,
  MONTH,
  QUARTER,
  TimePeriod,
  WEEK,
  YEAR,
} from '../constants/timePeriods';
import { DataType } from '../models/DataTypes';
import { Project } from '../models/Project';
import { RecordEdge, RecordValue } from '../models/Record';
import StateItem from '../models/StateItem';
import { aggregateNumericalData } from '../utils/aggregationDataTypes';
import { getChartColorPalette } from '../utils/chartColorPalette';
import {
  getFieldFromAxisValuePath,
  getFieldPathFromPath,
  isMetricChart,
} from '../utils/charts';
import { getColorByIndex } from '../utils/colors';
import { safelyAppendPath } from '../utils/data';
import { conditionsAreMet } from '../utils/data';
import { findPreviewFields } from '../utils/dataTypes';
import { getDateFromValue } from '../utils/dates';
import { getFieldFromDependency } from '../utils/fields';
import DataListWrapper from './DataListWrapper';
import GaugeChart from './sections/charts/GaugeChart';
import StatisticChart from './sections/charts/StatisticChart';
import { ChartSeries } from './sections/charts/chartTypes';
import { formatValue } from './sections/collections/FieldCell';

export const sampleData = [...Array(12)].map((__, index) => ({
  y: index * Math.ceil(Math.random() * 10),
  x: new Date(new Date().setMonth(index)).toISOString(),
  g: `Group ${index + 1}`,
}));

export const metricData = [...Array(12)].map((__, index) => ({
  x: index,
  g: `Group ${index + 1}`,
}));

export const sampleXValue = { path: 'edges.x', dataType: DATE };
export const sampleXMetricValue = { path: 'edges.x', dataType: INTEGER };
export const sampleYValue = { path: 'edges.y', dataType: DECIMAL };

export const formatDatum = (type: any, value: any, timePeriod?: any) => {
  if (!value || value === 'Unset') {
    return value;
  }

  if (type === DATE) {
    const dateValue = getDateFromValue(value);

    if (dateValue && dateValue.isValid) {
      if (timePeriod) {
        const dateTimePeriod = getDateTimePeriod(timePeriod);

        return getLabelFromDate(
          dateValue.toUTC().startOf(dateTimePeriod),
          timePeriod,
        );
      }

      return dateValue.toJSDate();
    }

    return null;
  }

  // Check if the value is entirely numeric and parse it only if it's numeric
  if (/^-?\d+(\.\d+)?$/.test(value)) {
    const maybeNumber = parseFloat(value);
    if (!isNaN(maybeNumber)) {
      return maybeNumber;
    }
  }

  return value;
};

// @ts-expect-error TS(7031): Binding element 'xA' implicitly has an 'any' type.
const numericSort = ({ x: xA }, { x: xB }) => xA - xB;

export const getDateTimePeriod = (timePeriod?: TimePeriod) => {
  if (timePeriod === AUTO) {
    return DAY;
  }
  return timePeriod || DAY;
};

const aggregateSeries = (series: any, values: any, aggregation: any, x: any) =>
  series.reduce(
    // @ts-expect-error TS(7006): Parameter 'seriesAcc' implicitly has an 'any' type... Remove this comment to see the full error message
    (seriesAcc, chartSeries) => ({
      ...seriesAcc,

      [chartSeries.id]:
        aggregateNumericalData(
          values.map((value: any) => value[chartSeries.id]),
          aggregation,
        ) ?? null,
    }),
    {
      x,
    },
  );

type MetricData = {
  x: number | null;
};

type SeriesData = {
  x: string | number;
  [y: string]: string | number;
}[];

const transformRawMetricData = (
  data: { x: RecordValue }[],
  xAxisValue: StateItem,
  dataType: DataType,
  aggregation: Aggregation = SUM,
): MetricData => {
  let filteredData = data.map((item) => item.x).filter((x) => !isNil(x));
  const xAxisField = getFieldFromAxisValuePath(xAxisValue.path, dataType);

  if (xAxisField && xAxisField.type === DATE) {
    filteredData.map((x) => DateTime.fromISO(x as string).toMillis());
  }

  const metric = aggregateNumericalData(filteredData as number[], aggregation);

  return { x: metric };
};
type Datum = any;

const groupByDate = (
  data: any[],
  timePeriod: TimePeriod,
  dateAccessor: (datum: any) => Date,
) => {
  const dateData = data.reduce(
    (commonDates: Record<string, Datum[]>, datum: Datum) => {
      const dateValue = DateTime.fromJSDate(dateAccessor(datum));

      if (!dateValue.isValid) {
        return commonDates;
      }
      const dateTimePeriod = getDateTimePeriod(timePeriod);

      const dateStr = dateValue.toUTC().startOf(dateTimePeriod).toISO();

      if (commonDates[dateStr]) {
        return set([dateStr, commonDates[dateStr].length], datum, commonDates);
      }

      return set([dateStr], [datum], commonDates);
    },
    {},
  );

  return Object.entries(dateData).map(([dateStr, values]: [string, any]) => ({
    dateStr,
    values,
  }));
};

const transformRawData = (
  data: any,
  xAxisValue: any,
  dataType: any,
  dataTypes: any,
  timePeriod: any,
  aggregation: any,
  sortOptions: any,
  series: any,
): SeriesData => {
  const xAxisType = xAxisValue.dataType;
  if (xAxisType === DATE) {
    const dateData = groupByDate(data, timePeriod, (datum: any) => datum.x);

    return dateData
      .map(({ dateStr, values }: { dateStr: string; values: any[] }) =>
        aggregateSeries(
          series,
          values,
          aggregation,
          DateTime.fromISO(dateStr).toUTC().toMillis(),
        ),
      )
      .sort(numericSort)
      .map((datum) => ({
        ...datum,
        x: datum.x !== null ? DateTime.fromMillis(datum.x).toJSDate() : datum.x,
      }));
  }

  const groupedData = data.reduce((commonValues: any, datum: any) => {
    const keyStr =
      PRIMITIVE_DATA_TYPES.includes(xAxisType) || !xAxisValue.path
        ? String(datum.x)
        : get(datum, 'x.id');
    if (commonValues[keyStr]) {
      return set([keyStr, commonValues[keyStr].length], datum, commonValues);
    }

    return set([keyStr], [datum], commonValues);
  }, {});

  const reducedData = Object.entries(groupedData).map(([keyStr, values]) =>
    aggregateSeries(series, values, aggregation, keyStr),
  );

  if (sortOptions) {
    const valueIdMap = data.reduce((idMap: any, datum: any) => {
      const keyStr = get(datum, 'x.id');
      return set([keyStr], datum.x, idMap);
    }, {});

    return reducedData.sort(({ primary: primaryA }, { primary: primaryB }) => {
      const { direction, field } = sortOptions;
      const valueA = get(valueIdMap, [primaryA, field], null);
      const valueB = get(valueIdMap, [primaryB, field], null);

      if (!valueA && !valueB) {
        return 0;
      }

      if (!valueA && valueB) {
        return 1;
      }

      if (valueA && !valueB) {
        return -1;
      }

      if (!direction || direction === DESC) {
        return valueB > valueA ? 1 : -1;
      }

      return valueB > valueA ? -1 : 1;
    });
  }

  if (xAxisType === DECIMAL || xAxisType === INTEGER) {
    return reducedData
      .filter((datum) => datum.x !== 'null')
      .map((datum) => ({
        ...datum,
        x: parseFloat(datum.x),
      }))
      .sort(numericSort);
  }

  if (dataType && xAxisType === SINGLE_OPTION) {
    const xAxisFieldFromDependency = getFieldFromDependency(
      xAxisValue.path.split('.'),
      dataType,
      dataTypes,
    );
    const xAxisField = xAxisFieldFromDependency?.field;
    if (xAxisField && xAxisField.type === SINGLE_OPTION) {
      const optionOrderMap = xAxisField.options?.reduce((map, option) => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        map[option.name] = option.order;
        return map;
      }, {});

      return reducedData.sort(
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (valueA, valueB) => optionOrderMap[valueA.x] - optionOrderMap[valueB.x],
      );
    }
  }

  const firstSeries = first(series);
  const sortedData = reducedData.sort(
    ({ [(firstSeries as any).id]: yA }, { [(firstSeries as any).id]: yB }) =>
      yB - yA,
  );

  return sortedData;
};

const recordLabelFormatter = (
  axisValuePath: any,
  edges: any,
  relatedDataType: any,
  id: any,
) => {
  const { textFields } = findPreviewFields(
    relatedDataType.fields,
    relatedDataType,
  );

  const idEdge = edges.find(
    (edge: any) =>
      (axisValuePath
        ? get(edge, `${getFieldPathFromPath(axisValuePath)}.id`)
        : String(edge.id)) === id,
  );

  if (textFields.length === 0 || !idEdge) {
    return id;
  }

  const fieldPath = axisValuePath
    ? `${getFieldPathFromPath(axisValuePath)}.`
    : '';

  return textFields
    .map((textField) => get(idEdge, `${fieldPath}${textField.name}`))
    .join(' ');
};

const periodsWithDays = [AUTO, WEEK, DAY];

export const getLabelFromDate = (dateTime: any, timePeriod: any) => {
  switch (timePeriod) {
    case WEEK:
      return dateTime.startOf('week').toLocaleString();

    case MONTH:
      return `${dateTime.monthLong}, ${dateTime.year}`;

    case QUARTER: {
      const quarterStart = dateTime.startOf('quarter').monthShort;
      const quarterEnd = dateTime.endOf('quarter').monthShort;

      return `${quarterStart} - ${quarterEnd}, ${dateTime.year}`;
    }
    case YEAR:
      return dateTime.year;

    default:
      return dateTime.toLocaleString();
  }
};

export const axisFormatter =
  (
    axisValue: any,
    dataType: any,
    dataTypes: any,
    edges: any,
    timePeriod?: any,
    useRawValue: boolean = false,
  ) =>
  (value: any) => {
    if (!axisValue || !axisValue.dataType) {
      return value;
    }

    const axisType = axisValue.dataType;

    if (dataType && axisType === SINGLE_OPTION) {
      const axisFieldFromDependency = getFieldFromDependency(
        axisValue.path.split('.'),
        dataType,
        dataTypes,
      );
      const axisField = axisFieldFromDependency?.field;
      if (axisField && axisField.type === SINGLE_OPTION) {
        const option = axisField.options?.find((op) => op.name === value);
        return option ? option.display : value;
      }
    }

    if (PRIMITIVE_DATA_TYPES.includes(axisType)) {
      if (dataType) {
        const axisField = getFieldFromAxisValuePath(axisValue.path, dataType);

        if (axisField) {
          if (
            value !== null &&
            axisField.typeOptions?.format === RATING &&
            useRawValue
          ) {
            return (
              <RatingInput
                className="my-2"
                disabled={true}
                maxRating={get(axisField.typeOptions, 'max')}
                value={value}
              />
            );
          }
          if (axisField.type === DATE) {
            const jsDate = new Date(value);
            const dateTime = DateTime.fromJSDate(jsDate).toUTC();
            return getLabelFromDate(dateTime, timePeriod);
          }

          return value !== null
            ? formatValue(value, axisField, {
                format: undefined,
                dateFormat: `${
                  periodsWithDays.includes(timePeriod) ? DAY_FORMAT : ''
                } ${MONTH_FORMAT} ${YEAR_FORMAT}`,
              })
            : null;
        }
      } else if (axisType === DATE) {
        const jsDate = new Date(value);
        const dateTime = DateTime.fromJSDate(jsDate).toUTC();
        return getLabelFromDate(dateTime, timePeriod);
      }
      return value;
    }

    const relatedDataType = dataTypes.getByName(axisType);
    if (!relatedDataType) {
      return value;
    }

    return recordLabelFormatter(axisValue.path, edges, relatedDataType, value);
  };

const LazyXYChart = lazy(() => import('./sections/charts/XYChart'));
const LazyPieChart = lazy(() => import('./sections/charts/PieChartWithLegend'));
const LazyFunnelChart = lazy(() => import('./sections/charts/FunnelChart'));
const LazyRadarChart = lazy(() => import('./sections/charts/RadarChart'));

const chartComponents = {
  [AREA]: (props: any) => (
    <Suspense fallback={<Loader />}>
      <LazyXYChart {...props} />
    </Suspense>
  ),
  [LINE]: (props: any) => (
    <Suspense fallback={<Loader />}>
      <LazyXYChart {...props} />
    </Suspense>
  ),
  [BAR]: (props: any) => (
    <Suspense fallback={<Loader />}>
      <LazyXYChart {...props} />
    </Suspense>
  ),
  [STACKED_BAR]: (props: any) => (
    <Suspense fallback={<Loader />}>
      <LazyXYChart {...props} />
    </Suspense>
  ),
  [PIE]: (props: any) => (
    <Suspense fallback={<Loader />}>
      <LazyPieChart {...props} />
    </Suspense>
  ),
  [FUNNEL]: (props: any) => (
    <Suspense fallback={<Loader />}>
      <LazyFunnelChart {...props} />
    </Suspense>
  ),
  [RADAR]: (props: any) => (
    <Suspense fallback={<Loader />}>
      <LazyRadarChart {...props} />
    </Suspense>
  ),
  [STATISTIC]: StatisticChart,
  [GAUGE]: GaugeChart,
};

export const ChartData = memo(
  ({
    // @ts-expect-error TS(2339): Property 'aggregation' does not exist on type '{}'... Remove this comment to see the full error message
    aggregation,
    // @ts-expect-error TS(2339): Property 'chartId' does not exist on type '{}'.
    chartId,
    // @ts-expect-error TS(2339): Property 'chartType' does not exist on type '{}'.
    chartType,
    // @ts-expect-error TS(2339): Property 'timePeriod' does not exist on type '{}'.
    timePeriod,
    // @ts-expect-error TS(2339): Property 'dataType' does not exist on type '{}'.
    dataType,
    // @ts-expect-error TS(2339): Property 'dataTypes' does not exist on type '{}'.
    dataTypes,
    // @ts-expect-error TS(2339): Property 'EmptyState' does not exist on type '{}'.
    EmptyState,
    // @ts-expect-error TS(2339): Property 'element' does not exist on type '{}'.
    element,
    // @ts-expect-error TS(2339): Property 'max' does not exist on type '{}'.
    max,
    // @ts-expect-error TS(2339): Property 'nodes' does not exist on type '{}'.
    nodes,
    // @ts-expect-error TS(2339): Property 'series' does not exist on type '{}'.
    series = [],
    // @ts-expect-error TS(2339): Property 'palette' does not exist on type '{}'... Remove this comment to see the full error message
    palette,
    // @ts-expect-error TS(2339): Property 'project' does not exist on type '{}'.
    project,
    // @ts-expect-error TS(2339): Property 'sortOptions' does not exist on type '{}'... Remove this comment to see the full error message
    sortOptions,
    // @ts-expect-error TS(2339): Property 'scope' does not exist on type '{}'.
    scope,
    // @ts-expect-error TS(2339): Property 'theme' does not exist on type '{}'.
    theme,
    // @ts-expect-error TS(2339): Property 'useOptionColors' does not exist on type ... Remove this comment to see the full error message
    useOptionColors,
    // @ts-expect-error TS(2339): Property 'xAxisLabel' does not exist on type '{}'.
    xAxisLabel,
    // @ts-expect-error TS(2339): Property 'xAxisValue' does not exist on type '{}'.
    xAxisValue,
    // @ts-expect-error TS(2339): Property 'yAxisLabel' does not exist on type '{}'.
    yAxisLabel,
    // @ts-expect-error TS(2339): Property 'stackedGroups' does not exist on type '{}'.
    stackedGroups,
  }) => {
    const xAxisType = xAxisValue.dataType;
    const primaryAxisFormatter = useMemo(
      () => axisFormatter(xAxisValue, dataType, dataTypes, nodes, timePeriod),
      [xAxisValue, dataType, dataTypes, nodes, timePeriod],
    );

    const formatSeriesValue = useCallback(
      (chartSeries: ChartSeries, value: number) =>
        axisFormatter(
          get(chartSeries, ['yAxisValue']),
          dataType,
          dataTypes,
          nodes,
          undefined,
          true,
        )(value),
      [dataType, dataTypes, nodes],
    );

    const secondaryAxisFormatter = useMemo(() => {
      if (aggregation === COUNT) {
        return identity;
      }

      return axisFormatter(
        get(series, [0, 'yAxisValue']),
        dataType,
        dataTypes,
        nodes,
      );
    }, [aggregation, series, dataType, dataTypes, nodes]);

    const xAxisField = useMemo(
      () => xAxisValue && getFieldFromAxisValuePath(xAxisValue.path, dataType),
      [dataType, xAxisValue],
    );

    const cellFillsMap = useMemo(() => {
      if (useOptionColors && xAxisField && xAxisField.type === SINGLE_OPTION) {
        const nullColor = getColorByIndex(xAxisField.options.length).split(
          '-',
        )[0];
        return xAxisField.options.reduce(
          // @ts-expect-error TS(7006): Parameter 'mapAcc' implicitly has an 'any' type.
          (mapAcc, option) => ({
            ...mapAcc,

            [option.name]:
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              colors[
                option.color || getColorByIndex(option.order).split('-')[0]
              ][400],
          }),
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          { null: colors[nullColor][400] },
        );
      }

      return null;
    }, [useOptionColors, xAxisField]);

    const fills = useMemo(
      () => getChartColorPalette(palette, theme),
      [palette, theme],
    );

    const groupsSeries: ChartSeries[] = useMemo(() => {
      if (chartType === STACKED_BAR && Array.isArray(stackedGroups)) {
        const yAxisValue = series[0].yAxisValue;
        return stackedGroups.map((group) => ({
          id: group.id,
          label: group.label,
          yAxisValue: { ...yAxisValue, path: String(group.id) },
        }));
      }
      return [];
    }, [chartType, stackedGroups, series]);

    const data = useMemo(() => {
      if (chartType === STACKED_BAR && Array.isArray(stackedGroups)) {
        const yAxisValue = series[0].yAxisValue;

        const rawData = stackedGroups.reduce((acc, group) => {
          group.rows.forEach((row: RecordEdge) => {
            const conditions = series[0].conditions;
            if (conditions && conditions.length !== 0) {
              const conditionsMet = conditionsAreMet(
                conditions,
                { ...scope, [`${element.id}:VIEW`]: row.node },
                project,
              );
              if (!conditionsMet) {
                return acc;
              }
            }
            const x = formatDatum(
              xAxisValue.dataType,
              get(row.node, getFieldPathFromPath(xAxisValue.path || 'id'), 0),
            );

            const y = formatDatum(
              yAxisValue.dataType,
              get(row.node, getFieldPathFromPath(yAxisValue.path || 'id'), 0),
            );

            acc.push({ x, [group.id]: y, group: group.id });
          });
          return acc;
        }, []);

        return transformRawData(
          rawData,
          xAxisValue,
          dataType,
          dataTypes,
          timePeriod,
          aggregation,
          sortOptions,
          groupsSeries,
        );
      }

      const rawData = nodes.map((edge: any) =>
        series.reduce(
          // @ts-expect-error TS(7006): Parameter 'seriesAcc' implicitly has an 'any' type... Remove this comment to see the full error message
          (seriesAcc, chartSeries) => {
            if (chartSeries.conditions && chartSeries.conditions.length !== 0) {
              const conditionsMet = conditionsAreMet(
                chartSeries.conditions,
                { ...scope, [`${element.id}:VIEW`]: edge },
                project,
              );
              if (!conditionsMet) {
                return seriesAcc;
              }
            }
            return {
              ...seriesAcc,

              [chartSeries.id]: chartSeries.yAxisValue
                ? formatDatum(
                    chartSeries.yAxisValue.dataType,
                    get(
                      edge,
                      getFieldPathFromPath(chartSeries.yAxisValue.path),
                      0,
                    ),
                  )
                : null,
            };
          },
          {
            x: formatDatum(
              xAxisValue.dataType,
              get(edge, getFieldPathFromPath(xAxisValue.path || 'id'), 0),
            ),
          },
        ),
      );

      if (isMetricChart(chartType)) {
        return transformRawMetricData(
          rawData,
          xAxisValue,
          dataType,
          aggregation,
        );
      }

      return transformRawData(
        rawData,
        xAxisValue,
        dataType,
        dataTypes,
        timePeriod,
        aggregation,
        sortOptions,
        series,
      );
    }, [
      aggregation,
      chartType,
      dataType,
      dataTypes,
      element?.id,
      nodes,
      project,
      scope,
      series,
      sortOptions,
      timePeriod,
      xAxisValue,
      stackedGroups,
      groupsSeries,
    ]);

    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const ChartComponent = chartComponents[chartType || LINE];

    const showEmptyState = useMemo(() => {
      if (!EmptyState) {
        return false;
      }

      return (
        !isMetricChart(chartType) && data && (data as SeriesData).length === 0
      );
    }, [EmptyState, chartType, data]);

    return (
      <div
        className={classNames(
          'relative w-full min-w-0',
          {
            'h-96': !isMetricChart(chartType),
            'flex h-full': isMetricChart(chartType),
            'items-end': chartType === STATISTIC,
            'items-center': chartType === GAUGE,
          },
          `chart-${chartId}`,
        )}
      >
        {showEmptyState && <EmptyState />}
        {!showEmptyState && (
          <ChartComponent
            chartId={chartId}
            cellFillsMap={cellFillsMap}
            chartType={chartType}
            data={data}
            fills={fills}
            max={max}
            primaryAxisFormatter={primaryAxisFormatter}
            formatSeriesValue={formatSeriesValue}
            secondaryAxisFormatter={secondaryAxisFormatter}
            series={chartType === STACKED_BAR ? groupsSeries : series}
            xAxisLabel={xAxisLabel}
            xAxisField={xAxisField}
            xAxisType={xAxisType}
            yAxisLabel={yAxisLabel}
            theme={theme}
          />
        )}
      </div>
    );
  },
);

type ChartProps = {
  palette: ColorPalette | undefined;
  project: Project;
};

const Chart = ({
  // @ts-expect-error TS(2339): Property 'aggregation' does not exist on type 'Cha... Remove this comment to see the full error message
  aggregation,
  // @ts-expect-error TS(2339): Property 'className' does not exist on type 'Chart... Remove this comment to see the full error message
  className,
  // @ts-expect-error TS(2339): Property 'chartId' does not exist on type 'Chart... Remove this comment to see the full error message
  chartId,
  // @ts-expect-error TS(2339): Property 'chartType' does not exist on type 'Chart... Remove this comment to see the full error message
  chartType,
  // @ts-expect-error TS(2339): Property 'timePeriod' does not exist on type 'Char... Remove this comment to see the full error message
  timePeriod,
  // @ts-expect-error TS(2339): Property 'dataList' does not exist on type 'ChartP... Remove this comment to see the full error message
  dataList,
  // @ts-expect-error TS(2339): Property 'editorMode' does not exist on type 'Char... Remove this comment to see the full error message
  editorMode,
  // @ts-expect-error TS(2339): Property 'elementPath' does not exist on type 'Cha... Remove this comment to see the full error message
  elementPath,
  // @ts-expect-error TS(2339): Property 'emptyState' does not exist on type 'Char... Remove this comment to see the full error message
  emptyState,
  // @ts-expect-error TS(2339): Property 'onClick' does not exist on type 'ChartPr... Remove this comment to see the full error message
  onClick,
  project,
  // @ts-expect-error TS(2339): Property 'scope' does not exist on type 'ChartProp... Remove this comment to see the full error message
  scope,
  // @ts-expect-error TS(2339): Property 'sortBy' does not exist on type 'ChartPro... Remove this comment to see the full error message
  sortBy,
  // @ts-expect-error TS(2339): Property 'sortDirection' does not exist on type 'C... Remove this comment to see the full error message
  sortDirection,
  // @ts-expect-error TS(2339): Property 'subtitle' does not exist on type 'ChartP... Remove this comment to see the full error message
  subtitle,
  // @ts-expect-error TS(2339): Property 'title' does not exist on type 'ChartProp... Remove this comment to see the full error message
  title,
  // @ts-expect-error TS(2339): Property 'theme' does not exist on type 'ChartProp... Remove this comment to see the full error message
  theme,
  palette,
  // @ts-expect-error TS(2339): Property 'xAxisValue' does not exist on type 'Char... Remove this comment to see the full error message
  xAxisValue,
  // @ts-expect-error TS(2339): Property 'yAxisValue' does not exist on type 'Char... Remove this comment to see the full error message
  yAxisValue,
}: ChartProps) => {
  const EmptyState = useMemo(
    () => () => (
      <div className="flex w-full flex-col items-center justify-center p-16 text-center">
        <h2 className="text-base font-medium">{emptyState.title.value}</h2>
        {!emptyState.image.hidden && emptyState.image.value.src && (
          <img
            className="mt-4 w-full max-w-xs rounded-lg"
            src={emptyState.image.value.src}
            alt={emptyState.title.value}
          />
        )}
      </div>
    ),
    [emptyState],
  );

  const titleRow = useMemo(
    () =>
      (title || subtitle) && (
        <div className="mb-2 flex items-center">
          <div className="flex flex-col">
            {title && <h1 className="text-base font-medium">{title}</h1>}
            {subtitle && <p className="font-base mt-1 text-base">{subtitle}</p>}
          </div>
        </div>
      ),
    [subtitle, title],
  );

  const sortOptions = useMemo(
    () =>
      xAxisValue &&
      chartType === BAR &&
      !PRIMITIVE_DATA_TYPES.includes(xAxisValue.dataType) &&
      sortBy &&
      sortDirection
        ? {
            field: sortBy,
            direction: sortDirection,
          }
        : null,
    [chartType, sortBy, sortDirection, xAxisValue],
  );

  const additionalDeps = useMemo(
    () =>
      sortOptions
        ? [
            {
              ...xAxisValue,
              path: safelyAppendPath(xAxisValue.path, sortOptions.field),
            },
          ]
        : [],
    [sortOptions, xAxisValue],
  );

  if (!xAxisValue || !yAxisValue) {
    return (
      <div className={classNames(className, 'w-full p-8')} onClick={onClick}>
        {titleRow}
        <div className="w-full rounded-lg bg-white p-8 shadow-md">
          <ChartData
            // @ts-expect-error TS(2322): Type '{ aggregation: any; chartType: any; dataType... Remove this comment to see the full error message
            chartId={chartId}
            aggregation={aggregation}
            chartType={chartType || LINE}
            dataTypes={project.dataTypes}
            timePeriod={timePeriod || WEEK}
            nodes={sampleData}
            id="SAMPLE"
            theme={theme}
            palette={palette}
            xAxisValue={sampleXValue}
            series={[{ id: 'sample', yAxisValue: sampleYValue }]}
            project={project}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={classNames(className, 'w-full p-8')} onClick={onClick}>
      {titleRow}
      <div className="w-full rounded-lg bg-white p-8 shadow-md">
        <DataListWrapper
          additionalDeps={additionalDeps}
          project={project}
          elementPath={elementPath}
          editorMode={editorMode}
          EmptyState={EmptyState}
          scope={scope}
          skipLambdas={true}
          {...dataList}
        >
          {({ loading, nodes, rawData, error }) => {
            if (loading) {
              return (
                <div className="flex w-full items-center justify-center p-8">
                  <Loader type="Bars" size="sm" />
                </div>
              );
            }
            if (error && !rawData) {
              console.log(error);
              return (
                <div className="flex w-full items-center justify-center p-8">
                  <em>Something went wrong</em>
                </div>
              );
            }

            if (nodes.length === 0) {
              return <EmptyState />;
            }

            return (
              <ChartData
                // @ts-expect-error TS(2322): Type '{ aggregation: any; chartType: any; dataType... Remove this comment to see the full error message
                chartId={chartId}
                aggregation={aggregation}
                chartType={chartType}
                dataTypes={project.dataTypes}
                timePeriod={timePeriod}
                nodes={nodes}
                sortOptions={sortOptions}
                theme={theme}
                palette={palette}
                xAxisValue={xAxisValue}
                series={[{ id: 'default', yAxisValue }]}
                project={project}
              />
            );
          }}
        </DataListWrapper>
      </div>
    </div>
  );
};

export default withTheme(Chart);
