import React, {
  LegacyRef,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  IconChevronDown,
  IconChevronUp,
  IconCopy,
  IconEdit,
  IconGripVertical,
  IconTrash,
  IconX,
} from '@tabler/icons-react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import shortId from 'shortid';
import { SelectInput, Tooltip } from '@noloco/components';
import { DARK } from '@noloco/components/src/constants/surface';
import BuildModeIcon from '@noloco/core/src/components/buildMode/BuildModeIcon';
import {
  CMD,
  COMMA,
  PERIOD,
  SHIFT,
} from '@noloco/core/src/constants/shortcuts';
import Icon from '@noloco/core/src/elements/Icon';
import { ID } from '@noloco/core/src/elements/sections/collections/CollectionEvents';
import KeyboardShortcutTooltip from '@noloco/core/src/elements/sections/view/KeyboardShortcutTooltip';
import { IconValue } from '@noloco/core/src/models/IconValue';
import { setIsFieldListEditorOpen } from '@noloco/core/src/reducers/elements';
import { isFieldListEditorOpenSelector } from '@noloco/core/src/selectors/elementsSelectors';
import useOnKeyPress from '@noloco/core/src/utils/hooks/useOnKeyPress';
import { getText } from '@noloco/core/src/utils/lang';
import RightPopoutMenu from '../../canvas/RightPopoutMenu';

type DraggableListItemProps = {
  activeListItem: number | null;
  canDelete?: boolean;
  children: JSX.Element;
  draggable?: boolean;
  dragRef?: LegacyRef<HTMLButtonElement> | undefined;
  extraListItemChildren?: JSX.Element | false;
  icon?: IconValue;
  id: string;
  index: number;
  isOver?: boolean;
  listOptions: {
    icon?: JSX.Element;
    label?: string;
    value: string | number;
  }[];
  onClone?: (id: ID, newItemId: ID, tab?: boolean) => void;
  onRemove: (id: ID) => void;
  popoutOpen: boolean;
  setActiveListItem: (activeListItem: number | null) => void;
  setPopoutOpen: (popoutOpen: boolean) => void;
  tab?: boolean;
  title: string;
  disabled?: boolean;
};

const DraggableListItem = forwardRef(
  (
    {
      activeListItem,
      canDelete = true,
      children,
      draggable,
      dragRef,
      extraListItemChildren,
      icon,
      id,
      index,
      isOver,
      listOptions = [],
      onClone,
      onRemove,
      popoutOpen = false,
      setActiveListItem,
      setPopoutOpen,
      tab,
      title,
      disabled = false,
    }: DraggableListItemProps,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const dispatch = useDispatch();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const onDelete = useCallback(() => {
      if (canDelete) {
        onRemove(id);
      }
    }, [canDelete, onRemove, id]);

    const onCloneItem = useCallback(() => {
      if (onClone) {
        onClone(id, shortId.generate(), tab);
      }
    }, [onClone, id, tab]);

    const isFieldListEditorOpen = useSelector(isFieldListEditorOpenSelector);
    const totalListItems = useMemo(() => listOptions.length, [listOptions]);

    const showListItemNav = useMemo(
      () =>
        !!(
          (activeListItem || activeListItem === 0) &&
          (totalListItems || totalListItems === 0)
        ),
      [activeListItem, totalListItems],
    );

    const { nextListItem = null, prevListItem = null } = useMemo(
      () => ({
        nextListItem:
          showListItemNav &&
          (activeListItem === totalListItems - 1 ? 0 : activeListItem! + 1),
        prevListItem:
          showListItemNav &&
          (activeListItem === 0 ? totalListItems - 1 : activeListItem! - 1),
      }),
      [activeListItem, totalListItems, showListItemNav],
    );

    const setActiveListItemState = useCallback(
      (listItem) => {
        setActiveListItem!(listItem);

        if (!isFieldListEditorOpen) {
          return dispatch(setIsFieldListEditorOpen(true));
        }

        if (listItem === null) {
          setPopoutOpen!(false);
          dispatch(setIsFieldListEditorOpen(false));
        }
      },
      [setActiveListItem, dispatch, isFieldListEditorOpen, setPopoutOpen],
    );

    const handleNextListItem = useCallback(() => {
      setPopoutOpen!(true);

      if (nextListItem || nextListItem === 0) {
        setActiveListItemState(nextListItem);
      }
    }, [setPopoutOpen, nextListItem, setActiveListItemState]);

    const handlePrevListItem = useCallback(() => {
      setPopoutOpen!(true);

      if (prevListItem || prevListItem === 0) {
        return setActiveListItemState(prevListItem);
      }
    }, [setPopoutOpen, prevListItem, setActiveListItemState]);

    useOnKeyPress(PERIOD, handleNextListItem, {
      ctrlKey: true,
      shiftKey: true,
      enabled:
        !!(popoutOpen || activeListItem || activeListItem === 0) &&
        nextListItem !== null,
    });

    useOnKeyPress(COMMA, handlePrevListItem, {
      ctrlKey: true,
      shiftKey: true,
      enabled:
        !!(popoutOpen || activeListItem || activeListItem === 0) &&
        prevListItem !== null,
    });

    const header = useMemo(
      () => (
        <div className="flex w-full select-none items-center space-x-2">
          <SelectInput
            className="w-full max-w-60"
            onChange={(index: number) => {
              setPopoutOpen!(true);
              setActiveListItemState(index);
            }}
            options={listOptions}
            placeholder={title}
            searchable={true}
            shiftRight={true}
            surface={DARK}
            value={index}
          />
          <div className="flex items-center">
            <KeyboardShortcutTooltip
              keys={[CMD, SHIFT, COMMA]}
              label={getText('elements.VIEW.fields.prevItem')}
              offset={[0, 8]}
              placement="bottom"
            >
              <BuildModeIcon
                disabled={prevListItem === null}
                Icon={IconChevronUp}
                onClick={handlePrevListItem}
              />
            </KeyboardShortcutTooltip>
            <KeyboardShortcutTooltip
              keys={[CMD, SHIFT, PERIOD]}
              label={getText('elements.VIEW.fields.nextItem')}
              offset={[0, 8]}
              placement="bottom"
            >
              <BuildModeIcon
                disabled={nextListItem === null}
                Icon={IconChevronDown}
                onClick={handleNextListItem}
              />
            </KeyboardShortcutTooltip>
          </div>
        </div>
      ),
      [
        handleNextListItem,
        handlePrevListItem,
        index,
        listOptions,
        nextListItem,
        prevListItem,
        setActiveListItemState,
        setPopoutOpen,
        title,
      ],
    );

    return (
      <div
        className={classNames('flex w-full flex-col', {
          'pointer-events-none opacity-50': disabled,
        })}
        ref={ref}
      >
        {draggable && isOver && (
          <div className="mb-2 h-2 w-full rounded-full bg-slate-900" />
        )}
        <div className="group mr-auto flex w-full items-center rounded-lg bg-slate-700 px-2 py-2">
          <div className="mr-2">
            {draggable && (
              <button
                className="hidden cursor-move group-hover:flex"
                draggable={true}
                ref={dragRef}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <IconGripVertical size={16} />
              </button>
            )}
            {icon && icon.name && !icon.hidden && (
              <Icon
                className={classNames('flex h-4 w-4', {
                  'group-hover:hidden': draggable,
                })}
                icon={icon}
              />
            )}
          </div>
          <label className="max-w-full truncate text-xs font-medium uppercase tracking-wider text-slate-300">
            {title}
          </label>
          <div className="ml-auto flex text-slate-300">
            <button
              className="mr-2 hidden group-hover:block"
              onClick={onCloneItem}
            >
              <IconCopy size={16} className="opacity-75" />
            </button>
            {canDelete && (
              <Tooltip
                content={
                  <div className="flex items-center">
                    <span className="mr-4">
                      {getText('elements.VIEW.confirmDelete')}
                    </span>
                    <button
                      onClick={() => onDelete()}
                      className="p-1 opacity-50 hover:opacity-100"
                    >
                      <IconTrash size={16} />
                    </button>
                    <button
                      onClick={() => setShowDeleteConfirmation(false)}
                      className="p-1 opacity-50 hover:opacity-100"
                    >
                      <IconX size={16} />
                    </button>
                  </div>
                }
                trigger="none"
                isOpen={showDeleteConfirmation}
              >
                <button
                  className={classNames(
                    'mr-2 group-hover:block',
                    showDeleteConfirmation ? 'block' : 'hidden',
                  )}
                  onClick={() => setShowDeleteConfirmation(true)}
                >
                  <IconTrash size={16} className="opacity-75" />
                </button>
              </Tooltip>
            )}
            <button
              className="hidden group-hover:visible group-hover:flex"
              onClick={() => setActiveListItemState(index)}
            >
              <IconEdit size={16} className="opacity-75" />
            </button>
            {extraListItemChildren}
          </div>
        </div>
        {activeListItem === index && (
          <RightPopoutMenu
            disableTransition={popoutOpen}
            header={header}
            onClose={() => setActiveListItemState(null)}
            rootSelector=".noloco-project"
            source="buildMode"
            title={title}
            usePortal={true}
          >
            <div className="flex flex-col text-sm text-slate-200">
              {children}
            </div>
          </RightPopoutMenu>
        )}
      </div>
    );
  },
);

export default DraggableListItem;
