import React, { useCallback, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Button, Loader, TextArea } from '@noloco/components';
import {
  LINK,
  OUTLINE,
} from '@noloco/components/src/components/button/buttonTypes';
import { DARK, LIGHT } from '@noloco/components/src/constants/surface';
import { MD, SM } from '@noloco/components/src/constants/tShirtSizes';
import { ID } from '@noloco/core/src/elements/sections/collections/CollectionEvents';
import {
  DataField,
  DataFieldOption,
} from '@noloco/core/src/models/DataTypeFields';
import { useGraphQlErrorAlert } from '@noloco/core/src/utils/hooks/useAlerts';
import { getText } from '@noloco/core/src/utils/lang';
import { ADD_DATA_FIELD_OPTION } from '../../../queries/project';
import { useUpdateDataFieldOptions } from '../../../utils/hooks/useUpdateDataTypes';

type BulkAddOptionsProps = {
  dataField?: DataField;
  dataTypeId?: ID;
  newDataField?: boolean;
  options: ({ display: string } | DataFieldOption)[];
  projectName?: string;
  setOptions: (options: ({ display: string } | DataFieldOption)[]) => void;
  setShowBulkAddScreen: (showBulkAddScreen: boolean) => void;
};

const BulkAddOptions = ({
  dataField,
  dataTypeId,
  newDataField = false,
  options,
  projectName,
  setOptions,
  setShowBulkAddScreen,
}: BulkAddOptionsProps) => {
  const updateDataFieldOptions = useUpdateDataFieldOptions();
  const errorAlert = useGraphQlErrorAlert();
  const [newOptions, setNewOptions] = useState(newDataField ? options : []);
  const [loading, setLoading] = useState(false);
  const [createDataFieldOption] = useMutation(ADD_DATA_FIELD_OPTION);

  const filteredOptions = useMemo(
    () => newOptions.filter((option) => option.display !== ''),
    [newOptions],
  );

  const optionsText = useMemo(
    () => filteredOptions.map((option) => option.display).join('\n'),
    [filteredOptions],
  );

  const handleOnChange = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) =>
      setNewOptions(
        value
          .split('\n')
          .map((optionValue) => optionValue.trim())
          .filter((optionValue) => optionValue !== '')
          .map((display) => ({ display })),
      ),
    [setNewOptions],
  );

  const handleBack = useCallback(
    () => setShowBulkAddScreen(false),
    [setShowBulkAddScreen],
  );

  const createNewOption = useCallback(
    (display, color = null) =>
      createDataFieldOption({
        variables: {
          projectName,
          dataTypeId,
          dataFieldId: dataField?.id,
          option: { display, color },
        },
      })
        .then(({ data }) => {
          if (data.createDataFieldOption) {
            return data.createDataFieldOption.options.find(
              (option: any) => option.display === display,
            );
          }
        })
        .catch((e) => {
          errorAlert(getText('data.options.edit.error'), e);
        }),
    [createDataFieldOption, projectName, dataTypeId, dataField, errorAlert],
  );

  const handleSubmit = useCallback(async () => {
    if (newDataField) {
      setOptions([...filteredOptions, { display: '' }]);
    } else {
      setLoading(true);
      const newOptionsCreated = await Promise.all(
        newOptions.map((option) => createNewOption(option.display)),
      );

      const allOptions = [
        ...options,
        ...newOptionsCreated.filter((option) => option.id),
      ];

      updateDataFieldOptions({
        dataTypeId: dataTypeId as number,
        dataFieldId: dataField?.id as number,
        options: allOptions,
      });
      setOptions(allOptions);
      setLoading(false);
    }

    setShowBulkAddScreen(false);
  }, [
    newDataField,
    setShowBulkAddScreen,
    setOptions,
    filteredOptions,
    newOptions,
    options,
    updateDataFieldOptions,
    dataTypeId,
    dataField?.id,
    createNewOption,
  ]);

  return (
    <>
      {newDataField && (
        <div className="text-white">{getText('data.fields.bulkAdd.title')}</div>
      )}
      <div className="flex flex-col md:hidden">
        <span className="mt-1 text-xs font-medium text-gray-400">
          {getText('data.fields.bulkAdd.subtitle')}
        </span>
        <TextArea
          className="mt-2 w-full p-1"
          size={SM}
          surface={newDataField ? DARK : LIGHT}
          type="textarea"
          minRows={5}
          autoFocus={true}
          onChange={handleOnChange}
          placeholder=""
          value={optionsText}
        />
      </div>
      <div className="mt-4 flex items-center justify-between">
        <Button onClick={handleBack} small={MD} type={LINK}>
          {getText('data.fields.bulkAdd.back')}
        </Button>
        <Button
          onClick={handleSubmit}
          size={MD}
          type={OUTLINE}
          disabled={options.length === 0}
        >
          {loading ? (
            <Loader size={SM} />
          ) : (
            getText(
              { context: filteredOptions.length },
              'data.fields.bulkAdd.add',
            )
          )}
        </Button>
      </div>
    </>
  );
};

export default BulkAddOptions;
